import React from "react";
import { Route } from 'react-router-dom';

// Pages
import Home from '../../pages/Home/Home';
import Services from '../../pages/Services/Services';
import About from '../../pages/About/About';
import Contact from '../../pages/Contact/Contact';
import Careers from "../../pages/Careers/Careers";

const routes = () => {

  return (
    <React.Fragment>
      <Route component={Home} path="/" exact />
      <Route component={Services} path="/services" />
      <Route component={About} path="/about" />
      <Route component={Careers} path="/careers" />
      <Route component={Contact} path="/contact" />
    </React.Fragment>
  );
}

export default routes;