import React, { useEffect } from "react";
import { projectName } from '../../utils';

// Images
import { svgPeople, svgCurve, svgRep } from '../../assets/svg';
import Bitcoin from "../../assets/svg/bitcoin.svg";
import Coding from "../../assets/svg/coding.svg";
import Cloud from "../../assets/svg/cloud.svg";
import Fingerprint from "../../assets/svg/fingerprint.svg"
import Mobile from "../../assets/svg/mobile.svg";
import Projects from "../../assets/svg/projects.svg";
//import ConfluenceBit from "../../assets/images/confluence-bit.png";
import { Link } from "react-router-dom";


const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <title>{projectName}</title>

      <div className="home">

        <div className="big-hero">
          <div className="big-hero__flex container">
            <div className="big-hero__left">
              <div className="big-hero__text">
                <h1>We provide IT Services</h1>
                <div className="has-shadow-text u-margin-bottom-medium">
                  <h4>Manage IT Solutions</h4>
                  <h1 className="shadow-text">Solutions</h1>
                </div>
                <p>Microbridge technologies limited helps companies through a full range of IT services and technology support</p>
              </div>
            </div>
            <div className="big-hero__right">
              <div className="big-hero__photo">
                {svgPeople}
              </div>
            </div>
          </div>
          {svgCurve}
        </div>

        <section className="home__summary">
          <div className="container">
            <div className="has-shadow-text u-margin-bottom-large">
              <h4 className="u-text-center">What We Offer</h4>
              <h1 className="shadow-text align-center">What We Offer</h1>
            </div>
            <div className="home__summary__contents">
              <div className="summary-content">
                <div className="summary-content__icon u-margin-bottom-small">
                  <img src={Coding} alt="Web Development Icon" />
                </div>
                <h3>Web Development</h3>
                <p>Our company offers end-to-end web development services to equip your company, customers, and clients with the digital tools that make them efficient, innovative, and successful and keep your business at the top of the competition.</p>
                <div className="border-right"></div>
              </div>
              <div className="summary-content">
                <div className="summary-content__icon u-margin-bottom-medium">
                  <img src={Cloud} alt="Web Development Icon" />
                </div>
                <h3>Cloud Services</h3>
                <p>Our services are designed to offer easy, scalable, and affordable access to applications and resources, without the need for internal infrastructure or hardware.</p>
                <div className="border-right"></div>
              </div>
              <div className="summary-content">
                <div className="summary-content__icon u-margin-bottom-medium">
                  <img src={Mobile} alt="Project managements" />
                </div>
                <h3>Mobile Development</h3>
                <p>We build mobile applications on different platforms using top-notch technologies and proven approaches. Our smartphone app development services include Leveraging next-gen technologies, intelligent algorithms, and modern UI Design and development of native Android and iOS mobile apps.</p>
              </div>
              <div className="summary-content">
                <div className="summary-content__icon u-margin-bottom-medium">
                  <img src={Fingerprint} alt="Cloud services" />
                </div>
                <h3>Identity Management Solutions</h3>
                <p>We design and deploy innovative, scalable, and practical biometric identity management solutions to increase security and convenience.</p>
                <div className="border-right"></div>
              </div>
              <div className="summary-content">
                <div className="summary-content__icon u-margin-bottom-medium">
                  <img src={Projects} alt="Customer support service" />
                </div>
                <h3>Project Management</h3>
                <p>Many IT projects fail. Yours should not! We can help streamline your IT project management activities from pre-project planning to post-project review to get work done efficiently.</p>
                <div className="border-right"></div>
              </div>
              <div className="summary-content">
                <div className="summary-content__icon u-margin-bottom-medium">
                  <img src={Bitcoin} alt="Bitcoin" />
                </div>
                <h3>Blockchain</h3>
                <p>We develop and provide blockchain technology services that help you materialize your ideas into sustainable business models.</p>
              </div>
            </div>
            <div className="view-more">
              <Link to='/services'>View more</Link>
            </div>
          </div>
        </section>

        <section className="home__we-got-you">
          <div className="container">
            <div className="home__we-got-you__text">
              <div className="has-shadow-text u-full-width">
                <h4 className="header-tertiary">We Got You Every <br /> Step Of The Way</h4>
                <h1 className="shadow-text">We Got You Every <br /> Step Of The Way</h1>
              </div>
            </div>

            <div className="home__we-got-you__image">
              {svgRep}
            </div>
          </div>
        </section>
      </div>

    </>
  );
}

export default Home;