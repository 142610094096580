import React, { useEffect } from "react";
import { projectName } from '../../utils';
import Team from "../../assets/svg/team.svg"; //123COMPANY34


const Careers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <title>{projectName}</title>
      <div className="careers">
        <div className="container">

          <section className="careers__main">

            <div className="has-shadow-text u-margin-bottom-large">
              <h4 className="header-tertiary u-text-center">Careers</h4>
              <h1 className="shadow-text align-center">Careers</h1>
            </div>

            <div className="careers__banner">

              <div>
                <h1>Build a future you can feature in</h1>
                <p>In Microbridge, we help businesses to be more successful by gaining competitive advantage through the latest and adaptable software solutions: Initiative and Diligence is welcome!</p>

                <p>Join our team of friendly and forward-thinking experts to build your career!</p>
              </div>

              <div className="careers__banner-image">
                <img src={Team} alt="Team" />
              </div>

            </div>


            <div className="careers__container">
              <h3 className="open-role-header">Open roles</h3>
              <p>We are always happy to receive open applications, even if we are not actively hiring.</p>

              <div className="careers__content">

                <table className="careers__table">
                  <thead>
                    <tr>
                      <th width="30%">Job Title</th>
                      <th width="60%">Description</th>
                      <th width="10%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="role"><span className="contract">Full Time</span>Java Developer</td>
                      <td>Responsible for the inner workings of a web application. Outstanding skills in the use of Java, Database and Spring/Springboot framework is essential.</td>
                      <td align="right">
                        <a
                          href="mailto:info@microbridge.com.ng?subject=Java Developer Application"
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn__primary btn__small">Apply</a>
                      </td>
                    </tr>

                    <tr>
                      <td className="role"><span className="contract">Full Time</span>Research & Development Officer</td>
                      <td>Innovates and introduces new products and services, as well as improving existing products in the company.</td>
                      <td align="right">
                        <a
                          href="mailto:info@microbridge.com.ng?subject=R&D Application"
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn__primary btn__small">Apply</a>
                      </td>
                    </tr>
                    <tr>
                      <td className="role"><span className="contract">Full Time</span>UI/UX Engineer</td>
                      <td>Creates user-friendly interfaces that enhance a customer’s experience. We are looking to hire a UI/UX Engineer with experience in the use of Photoshop and Adobe XD tools.</td>
                      <td align="right">
                        <a
                          href="mailto:info@microbridge.com.ng?subject=UI/UX Application"
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn__primary btn__small">Apply</a>
                      </td>
                    </tr>

                    <tr>
                      <td className="role"><span className="contract">Full Time</span>Frontend Developer</td>
                      <td>Implements visual elements that users see and interact with in a web application. The ideal candidate is expected to have a good knowledge of HTML, CSS, React and JavaScript tools.</td>
                      <td align="right">
                        <a
                          href="mailto:info@microbridge.com.ng?subject=Frontend Developer Application"
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn__primary btn__small">Apply</a>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>

            </div>

          </section>


        </div>
      </div>
    </>
  );
}

export default Careers;