import React, { useEffect, useCallback, useState, useReducer, useRef } from "react";
import { useDispatch } from "react-redux";
import { sendMessage } from "../../store/actions/MessageActions";
import { projectName, emailRegex, phoneRegex } from '../../utils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faUserAlt,
  faMobile,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import Button from '../../components/Button/Button';
import Spinner from "../../components/Spinner/Spinner";
import Modal from '../../components/Modal/Modal';

import Party from '../../assets/images/mb-party.svg';

const FORM_UPDATE = "FORM_UPDATE";
const CLEAR_FORM_VALUES = "CLEAR_FORM_VALUES";

const initialState = {
  inputValues: {
    name: '',
    email: '',
    phone: "",
    message: ''
  },
  inputValidities: {
    name: false,
    email: false,
    phone: false,
    message: false
  },
  formIsValid: false,
  submitted: false
}

function reducer(state, action) {
  switch (action.type) {
    case FORM_UPDATE:

      const updatedValues = {
        ...state.inputValues,
        [action.id]: action.value
      }

      const updatedValidities = {
        ...state.inputValidities,
        [action.id]: action.isValid
      }

      let updatedFormIsValid = true;
      for (let key in updatedValidities) {
        updatedFormIsValid = updatedFormIsValid && updatedValidities[key];
      }

      return {
        formIsValid: updatedFormIsValid,
        inputValues: updatedValues,
        inputValidities: updatedValidities
      }

    case CLEAR_FORM_VALUES:
      return initialState;
    default:
      return state;
  }
}

const Contact = () => {

  const [state, formDispatch] = useReducer(reducer, initialState);

  const nameRef = useRef(false);
  const emailRef = useRef(false);
  const phoneRef = useRef(false);
  const messageRef = useRef(false);

  const dispatch = useDispatch();

  const [isRequesting, setIsRequesting] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChangeHandler = useCallback((e, isEmail, isPhone) => {

    let id = e.target.id;
    let isRequired = e.target.required;
    let isValid = true;
    let value = e.target.value;

    let parentElem = document.getElementById(id).parentElement;

    console.log('Parent Element: ', parentElem);

    if (isRequired && value.trim().length === 0) {
      isValid = false;
    } else if (isEmail && !emailRegex.test(value.toLowerCase())) {
      isValid = false;
    } else if (isPhone && !phoneRegex.test(value.toLowerCase())) {
      isValid = false;
    }

    // if (isRequired && value.trim().length === 0 && parentElem) {
    //   parentElem.children[2].classList.add("show");
    //   parentElem.children[2].innerHTML = "This is a required field";
    //   parentElem.classList.add("error");
    //   isValid = false;
    // } else if (isEmail && !emailRegex.test(value.toLowerCase())) {
    //   parentElem.children[2].classList.add("show");
    //   parentElem.children[2].innerHTML = "Invalid email format";
    //   parentElem.classList.add("error");
    //   isValid = false;
    // } else if (isPhone && !phoneRegex.test(value.toLowerCase())) {
    //   parentElem.children[2].classList.add("show");
    //   parentElem.children[2].innerHTML = "Invalid phone number format";
    //   parentElem.classList.add("error");
    //   isValid = false;
    // } else {
    //   parentElem.classList.remove("error");
    //   parentElem.children[2].classList.remove("show");
    //   parentElem.children[2].innerHTML = "";
    // }

    formDispatch({ type: FORM_UPDATE, value: value, id: id, isValid: isValid });

  }, [formDispatch]);

  const sendMessageHandler = (e) => {
    e.preventDefault();

    setIsRequesting(true);

    if (!state.formIsValid) {
      return;
    }

    dispatch(sendMessage(
      state.inputValues.name,
      state.inputValues.email,
      state.inputValues.phone,
      state.inputValues.message
    ))
      .then(() => {
        setIsRequesting(false);
        setShowModal(true);
        formDispatch({ type: CLEAR_FORM_VALUES });
        e.target.reset();
      })
      .catch(error => {
        setIsRequesting(false);
        console.log('Send message error', error);
      });
  };

  const closeModal = () => {
    setShowModal(false);
  }

  return (
    <>
      <title>{projectName}</title>
      <Modal show={showModal} closeModal={closeModal}>
        <div className="modal__close-icon" onClick={closeModal}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <h1 className="modal__title u-text-center">Your message was sent successfully</h1>
        <div className="modal__success-image">
          <img src={Party} alt="Party" />
        </div>
      </Modal>
      <div className="contact">
        <div className="container">
          <section className="contact__main">
            <div className="has-shadow-text u-margin-bottom-large">
              <h4 className="header-tertiary u-text-center">Contact Us</h4>
              <h1 className="shadow-text align-center">Contact Us</h1>
            </div>
            <div className="text-content">
              <h4 className="text-content__subheader">Getting in touch is easy</h4>
              <p className="text-content__paragraphy">If you have any questions or queries, our support service will always be happy to help. Feel free to contact us by telephone or email and we will be sure to get back to you as soon as possible.</p>
            </div>
          </section>
          <section className="contact__info">
            <div className="contact__info__content">
              <div className="icon">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </div>
              <p className="text">16 Umaru Dikko Street, Jabi, Abuja, Nigeria.</p>
            </div>
            <div className="contact__info__content">
              <div className="icon">
                <FontAwesomeIcon icon={faPhone} />
              </div>
              <a className="text" href="tel:+2349123076330">+234 912 3076 330</a>
            </div>
            <div className="contact__info__content email">
              <div className="icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <a className="text" href="mailto:contact@microbridge.com.ng">contact@microbridge.com.ng</a>
            </div>
          </section>
          <section className="contact__form">
            <h1 className="contact__form__title">Send us a message</h1>
            <form action="#" onSubmit={sendMessageHandler}>
              <div className="contact__form__form-area">
                <div className="form-row">
                  <div className="form-elem" ref={nameRef}>
                    <label>Name</label>
                    <div className="form-input">
                      <div className="form-input__icon">
                        <FontAwesomeIcon icon={faUserAlt} />
                      </div>
                      <input required id="name" onChange={onChangeHandler} type="text" />
                      <span className="error-message"></span>
                    </div>

                  </div>
                </div>
                <div className="form-row">
                  <div className="form-elem">
                    <label>Email</label>
                    <div className="form-input" ref={emailRef}>
                      <div className="form-input__icon">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </div>
                      <input required id="email" onChange={(e) => onChangeHandler(e, true)} type="text" />
                      <span className="error-message"></span>
                    </div>

                  </div>
                </div>
                <div className="form-row">
                  <div className="form-elem">
                    <label>Phone Number</label>
                    <div className="form-input" ref={phoneRef}>
                      <div className="form-input__icon">
                        <FontAwesomeIcon icon={faMobile} />
                      </div>
                      <input id="phone" onChange={(e) => onChangeHandler(e, false, true)} type="tel" />
                      <span className="error-message"></span>
                    </div>

                  </div>
                </div>
                <div className="form-row message">
                  <div className="form-elem">
                    <label>Message</label>
                    <div className="form-input" ref={messageRef}>
                      <textarea required id="message" onChange={(e) => onChangeHandler(e, false, false)} placeholder="Enter your message here"></textarea>
                      <span className="error-message"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact__form__action">

                <Button
                  disabled={!state.formIsValid || isRequesting}
                  //onClick={sendMessageHandler}
                  classes="btn btn__primary btn__wide"
                  type="submit">
                  {isRequesting ? <Spinner /> : "Send Message"}
                </Button>
              </div>

            </form>
          </section>
        </div>
      </div>
    </>
  );
}

export default Contact;