export const svgPeople = <svg xmlns="http://www.w3.org/2000/svg" width="767.324" height="650.065" viewBox="0 0 767.324 650.065">
  <g id="MB_illustration" data-name="MB illustration" transform="translate(-960 -152.014)">
    <g id="bubbles" transform="translate(994.15 173)">
      <ellipse id="Ellipse_107" data-name="Ellipse 107" cx="20" cy="20" rx="20" ry="20" transform="translate(602.851 169.023)" fill="#adcc67" />
      <ellipse className="animate animate-137" id="Ellipse_137" data-name="Ellipse 137" cx="20" cy="20" rx="20" ry="20" transform="translate(528.851 69.023)" fill="#adcc67" />
      <ellipse id="Ellipse_108" data-name="Ellipse 108" cx="13.5" cy="13.5" rx="13.5" ry="13.5" transform="translate(642.851 204.795)" fill="#adcc67" />
      <ellipse id="Ellipse_109" data-name="Ellipse 109" cx="7.5" cy="7.5" rx="7.5" ry="7.5" transform="translate(646.851 174.015)" fill="#adcc67" />
      <ellipse className="animate animate-110" id="Ellipse_110" data-name="Ellipse 110" cx="20" cy="20" rx="20" ry="20" transform="translate(20.001 11.014)" fill="#adcc67" />
      <ellipse id="Ellipse_111" data-name="Ellipse 111" cx="7.5" cy="7.5" rx="7.5" ry="7.5" transform="translate(683.851 52)" fill="#adcc67" />
      <ellipse className="animate animate-112" id="Ellipse_112" data-name="Ellipse 112" cx="35.5" cy="35.5" rx="35.5" ry="35.5" transform="translate(493.491 205.014)" fill="#e0ebc8" />
      <ellipse className="animate animate-113" id="Ellipse_113" data-name="Ellipse 113" cx="24" cy="24" rx="24" ry="24" transform="translate(243.832 276.014)" fill="#e0ebc8" />
      <ellipse className="animate animate-114" id="Ellipse_114" data-name="Ellipse 114" cx="13" cy="13" rx="13" ry="13" transform="translate(27.001 221.354)" fill="#e0ebc8" />
      <ellipse id="Ellipse_115" data-name="Ellipse 115" cx="26" cy="26" rx="26" ry="26" transform="translate(93.593 67.582)" fill="#e0ebc8" />
      <ellipse className="animate animate-116" id="Ellipse_116" data-name="Ellipse 116" cx="12.5" cy="12.5" rx="12.5" ry="12.5" transform="translate(516.923 0)" fill="#e0ebc8" />
      <ellipse id="Ellipse_117" data-name="Ellipse 117" cx="12.5" cy="12.5" rx="12.5" ry="12.5" transform="translate(602.851 296.182)" fill="#e0ebc8" />
      <ellipse className="animate animate-118" id="Ellipse_118" data-name="Ellipse 118" cx="12.5" cy="12.5" rx="12.5" ry="12.5" transform="translate(0.001 123.706)" fill="#adcc67" />
      <ellipse id="Ellipse_119" data-name="Ellipse 119" cx="13.5" cy="13.5" rx="13.5" ry="13.5" transform="translate(339 -20.986)" fill="#e0ebc8" />
      <ellipse id="Ellipse_120" data-name="Ellipse 120" cx="7.5" cy="7.5" rx="7.5" ry="7.5" transform="translate(298 -10.986)" fill="#e0ebc8" />
      <g id="Group_1005" data-name="Group 1005">
        <circle id="Ellipse_138" data-name="Ellipse 138" cx="1.5" cy="1.5" r="1.5" transform="translate(214.852 401.015)" fill="#7bb200" />
      </g>
    </g>
    <g id="people">
      <g id="Group_978" data-name="Group 978" transform="translate(-10339 -860.792)">
        <path id="Path_1815" data-name="Path 1815" d="M861.275,1027.267H198.434a.349.349,0,0,1,0-.7h662.9a.325.325,0,0,1,.334.349A.378.378,0,0,1,861.275,1027.267Z" transform="translate(11100.9 603.489)" fill="#b0b4b9" />
        <g id="Group_971" data-name="Group 971" transform="translate(11645.149 1378.532)">
          <path id="Path_1816" data-name="Path 1816" d="M908.763,985.23a26.357,26.357,0,0,1-.609,3.955c-1.466,4.108-6.124,5.415-10.256,6.762l-22.658,8.016c-2.039.65-1.791,3.635.349,3.635h2.837a1.941,1.941,0,0,0,1.6.8,2.862,2.862,0,0,0,1.744-.7c.047-.047.1-.047.148-.1h.4c.047.047.148.047.2.148a2.043,2.043,0,0,0,1.5.7,2.882,2.882,0,0,0,1.643-.6.483.483,0,0,1,.7.047,1.521,1.521,0,0,0,1.194.55,2.536,2.536,0,0,0,1.395-.5.531.531,0,0,1,.65.047,1.7,1.7,0,0,0,1.094.449,1.544,1.544,0,0,0,1.342-.8h.2l30.112-.449h.083a6.563,6.563,0,0,0,6.213-5.3l2.483-14.157Z" transform="translate(-820.545 -758.14)" fill="#fc9d9d" />
          <path id="Path_1817" data-name="Path 1817" d="M922.772,987.061l-14.258.662,15.091,4.114L921.826,999a6.708,6.708,0,0,1-6.278,5.119h-.083l-29.881.449h-.2a1.539,1.539,0,0,1-2.435.349.527.527,0,0,0-.65-.047,2.475,2.475,0,0,1-1.4.5,1.511,1.511,0,0,1-1.194-.55.527.527,0,0,0-.7-.047,2.582,2.582,0,0,1-1.643.6,1.868,1.868,0,0,1-1.5-.7.652.652,0,0,0-.2-.148h-.047l-.449.148a2.572,2.572,0,0,0-.349.148c-.047.047-.148.1-.2.148-1.4.993-.993,3.387.9,3.387h2.837a1.942,1.942,0,0,0,1.6.8,2.862,2.862,0,0,0,1.744-.7c.047-.047.1-.047.148-.1h.4c.047.047.148.047.2.148a2.043,2.043,0,0,0,1.5.7,2.881,2.881,0,0,0,1.643-.6.483.483,0,0,1,.7.047,1.52,1.52,0,0,0,1.194.55,2.536,2.536,0,0,0,1.4-.5.531.531,0,0,1,.65.047,1.7,1.7,0,0,0,1.094.449,1.544,1.544,0,0,0,1.342-.8h.2l28.037-.449c5.367.768,6.922-2.045,8.069-4.138l2.831-15.315Z" transform="translate(-820.526 -758.888)" opacity="0.15" />
          <path id="Path_1818" data-name="Path 1818" d="M912.18,1004.2s4.38,7.72,12.6,10.5H916.46Z" transform="translate(-836.221 -765.896)" opacity="0.15" />
          <path id="Path_1819" data-name="Path 1819" d="M873.262,1024.76h51.392a1.395,1.395,0,1,0,0-2.79H871.92v1.4A1.383,1.383,0,0,0,873.262,1024.76Z" transform="translate(-819.759 -773.162)" fill="#4d61fc" />
          <path id="Path_1820" data-name="Path 1820" d="M871.92,1013.345a7.127,7.127,0,0,1,4.782-6.473l17.03-5.923a1.285,1.285,0,0,1,1.691.9c.745,2.489,2.589,7.821,6.224,11.5h23.006a1.394,1.394,0,0,1,1.4,1.4,1.361,1.361,0,0,1-1.4,1.4H873.262a1.337,1.337,0,0,1-1.342-1.342v-1.454Z" transform="translate(-819.759 -764.537)" fill="#4d61fc" />
          <path id="Path_1821" data-name="Path 1821" d="M871.92,1013.345a7.127,7.127,0,0,1,4.782-6.473l17.03-5.923a1.285,1.285,0,0,1,1.691.9c.745,2.489,2.589,7.821,6.224,11.5h23.006a1.394,1.394,0,0,1,1.4,1.4,1.361,1.361,0,0,1-1.4,1.4H873.262a1.337,1.337,0,0,1-1.342-1.342v-1.454Z" transform="translate(-819.759 -764.537)" fill="#21252b" />
          <path id="Path_1822" data-name="Path 1822" d="M871.92,1018.045a7.116,7.116,0,0,1,3.186-5.675c-2.441,1.744-1.992,3.488,1.5,3.334,17.976-.845,20.317-.845,22.409-1.046a.186.186,0,0,1-.047-.148,20.925,20.925,0,0,0,2.737,3.535H924.66a1.394,1.394,0,0,1,1.4,1.4,1.361,1.361,0,0,1-1.4,1.4H873.268a1.337,1.337,0,0,1-1.342-1.342Z" transform="translate(-819.759 -769.237)" opacity="0.4" />
          <path id="Path_1823" data-name="Path 1823" d="M871.92,1021.21h29.326l.4.449H871.92Z" transform="translate(-819.759 -772.851)" opacity="0.4" />
          <path id="Path_1824" data-name="Path 1824" d="M1133.847,754.084h-63.031a6.53,6.53,0,0,1-6.171-8.406l6.845-139.924a6.454,6.454,0,0,1,6.171-4.693h63.031a6.53,6.53,0,0,1,6.171,8.406l-6.839,139.924A6.466,6.466,0,0,1,1133.847,754.084Z" transform="translate(-898.451 -601.061)" fill="#84888f" />
          <path id="Path_1825" data-name="Path 1825" d="M1124.8,754.084h-54.9a6.53,6.53,0,0,1-6.171-8.406l6.845-139.924a6.454,6.454,0,0,1,6.171-4.693h54.9a6.53,6.53,0,0,1,6.171,8.406l-6.839,139.924A6.466,6.466,0,0,1,1124.8,754.084Z" transform="translate(-898.079 -601.061)" fill="#b0b4b9" />
          <path id="Path_1826" data-name="Path 1826" d="M1099.307,852.136h-66.714a5.633,5.633,0,0,1,0-11.267h66.714a5.633,5.633,0,0,1,0,11.267Z" transform="translate(-883.152 -699.114)" fill="#b0b4b9" />
          <path id="Path_1827" data-name="Path 1827" d="M1090.895,1008.36H1033.55v5.675h57.344Z" transform="translate(-885.847 -767.597)" fill="#b0b4b9" />
          <path id="Path_1828" data-name="Path 1828" d="M1035.138,1012.91a5.407,5.407,0,1,1-6.218-4.451A5.406,5.406,0,0,1,1035.138,1012.91Z" transform="translate(-882.103 -767.607)" fill="#b0b4b9" />
          <path id="Path_1829" data-name="Path 1829" d="M1028.92,1008.449a5.584,5.584,0,0,1,.887-.065v-.024h57.345v5.675H1035.2a5.407,5.407,0,1,1-6.283-5.586Z" transform="translate(-882.103 -767.597)" fill="#b0b4b9" />
          <path id="Path_1830" data-name="Path 1830" d="M1127.645,1008.36H1070.3v5.675h57.345Z" transform="translate(-900.872 -767.597)" fill="#84888f" />
          <path id="Path_1831" data-name="Path 1831" d="M1071.888,1012.91a5.408,5.408,0,1,1-6.219-4.451A5.406,5.406,0,0,1,1071.888,1012.91Z" transform="translate(-897.129 -767.607)" fill="#84888f" />
          <path id="Path_1832" data-name="Path 1832" d="M1118.475,854.211H1112.8v93.444h5.675Z" transform="translate(-918.25 -704.569)" fill="#84888f" />
          <path id="Path_1833" data-name="Path 1833" d="M1168.9,1012.91a5.408,5.408,0,1,1-6.219-4.451A5.406,5.406,0,0,1,1168.9,1012.91Z" transform="translate(-936.795 -767.607)" fill="#84888f" />
          <path id="Path_1834" data-name="Path 1834" d="M1128.861,852.136h-66.708a5.633,5.633,0,0,1,0-11.267h66.708a5.633,5.633,0,1,1,0,11.267Z" transform="translate(-895.238 -699.114)" fill="#84888f" />
          <path id="Path_1835" data-name="Path 1835" d="M1284.965,778.01h-4.315V924.986h4.315Z" transform="translate(-986.881 -673.412)" fill="#61656d" />
          <path id="Path_1836" data-name="Path 1836" d="M1249.076,778.01H1203.43V924.98h45.646Z" transform="translate(-955.307 -673.412)" fill="#61656d" />
          <path id="Path_1837" data-name="Path 1837" d="M1249.076,778.01H1203.43V924.98h45.646Z" transform="translate(-955.307 -673.412)" opacity="0.5" />
          <path id="Path_1838" data-name="Path 1838" d="M1087.309,763.779H783.68v8.406h303.629a4.2,4.2,0,0,0,0-8.406Z" transform="translate(-783.68 -667.593)" fill="#61656d" />
          <path id="Path_1839" data-name="Path 1839" d="M932.52,902.348l22.581.768,9.162-87.61h87.894V778.26H949.326q-.408,0-.816.018A15.264,15.264,0,0,0,934.258,793.4Z" transform="translate(-844.537 -673.514)" fill="#7bb200" />
          <path id="Path_1840" data-name="Path 1840" d="M977.283,985.23a26.347,26.347,0,0,1-.609,3.955c-1.466,4.108-6.124,5.415-10.256,6.762l-22.658,8.016c-2.039.65-1.791,3.635.349,3.635h2.837a1.942,1.942,0,0,0,1.6.8,2.862,2.862,0,0,0,1.744-.7c.047-.047.1-.047.148-.1h.4c.047.047.148.047.2.148a2.043,2.043,0,0,0,1.5.7,2.882,2.882,0,0,0,1.643-.6.483.483,0,0,1,.7.047,1.52,1.52,0,0,0,1.194.55,2.536,2.536,0,0,0,1.4-.5.53.53,0,0,1,.65.047,1.7,1.7,0,0,0,1.094.449,1.544,1.544,0,0,0,1.342-.8h.2l30.112-.449h.083a6.563,6.563,0,0,0,6.213-5.3l2.873-14.157Z" transform="translate(-848.561 -758.14)" fill="#fc9d9d" />
          <path id="Path_1841" data-name="Path 1841" d="M991.288,987.061l-14.317,1.371,15.144,3.4L990.336,999a6.708,6.708,0,0,1-6.278,5.119h-.083l-29.881.449h-.2a1.539,1.539,0,0,1-2.435.349.527.527,0,0,0-.65-.047,2.476,2.476,0,0,1-1.4.5,1.512,1.512,0,0,1-1.194-.55.527.527,0,0,0-.7-.047,2.581,2.581,0,0,1-1.643.6,1.868,1.868,0,0,1-1.5-.7.651.651,0,0,0-.2-.148h-.047l-.449.148a2.56,2.56,0,0,0-.349.148c-.047.047-.148.1-.2.148-1.4.993-.993,3.387.9,3.387h2.837a1.941,1.941,0,0,0,1.6.8,2.862,2.862,0,0,0,1.744-.7c.047-.047.1-.047.148-.1h.4c.047.047.148.047.2.148a2.042,2.042,0,0,0,1.5.7,2.881,2.881,0,0,0,1.643-.6.483.483,0,0,1,.7.047,1.519,1.519,0,0,0,1.194.55,2.536,2.536,0,0,0,1.4-.5.531.531,0,0,1,.65.047,1.7,1.7,0,0,0,1.094.449,1.544,1.544,0,0,0,1.342-.8h.2l28.037-.449c5.367.768,6.922-2.045,8.069-4.138l3.222-15.315Z" transform="translate(-848.538 -758.888)" opacity="0.15" />
          <path id="Path_1842" data-name="Path 1842" d="M980.7,1004.2s4.38,7.72,12.6,10.5H984.98Z" transform="translate(-864.237 -765.896)" opacity="0.15" />
          <path id="Path_1843" data-name="Path 1843" d="M951.03,902.72l11.645.4,9.162-87.61h87.894V778.26H971.784c-3.287,0-6.449,1.72-9.1,4.2a20.423,20.423,0,0,0-6.3,13.927Z" transform="translate(-852.106 -673.514)" opacity="0.2" />
          <path id="Path_1844" data-name="Path 1844" d="M941.782,1024.76h51.392a1.395,1.395,0,0,0,0-2.79H940.44v1.4A1.383,1.383,0,0,0,941.782,1024.76Z" transform="translate(-847.776 -773.162)" fill="#4d61fc" />
          <path id="Path_1845" data-name="Path 1845" d="M940.44,1013.345a7.127,7.127,0,0,1,4.782-6.473l17.03-5.923a1.285,1.285,0,0,1,1.691.9c.745,2.489,2.589,7.821,6.224,11.5h23.006a1.394,1.394,0,0,1,1.395,1.4,1.361,1.361,0,0,1-1.395,1.4H941.782a1.337,1.337,0,0,1-1.342-1.342Z" transform="translate(-847.776 -764.537)" fill="#4d61fc" />
          <path id="Path_1846" data-name="Path 1846" d="M940.44,1013.345a7.127,7.127,0,0,1,4.782-6.473l17.03-5.923a1.285,1.285,0,0,1,1.691.9c.745,2.489,2.589,7.821,6.224,11.5h23.006a1.394,1.394,0,0,1,1.395,1.4,1.361,1.361,0,0,1-1.395,1.4H941.782a1.337,1.337,0,0,1-1.342-1.342Z" transform="translate(-847.776 -764.537)" fill="#21252b" />
          <path id="Path_1847" data-name="Path 1847" d="M940.44,1018.045a7.116,7.116,0,0,1,3.186-5.675c-2.441,1.744-1.992,3.488,1.5,3.334,17.976-.845,20.317-.845,22.41-1.046a.186.186,0,0,1-.047-.148,20.922,20.922,0,0,0,2.737,3.535H993.18a1.394,1.394,0,0,1,1.395,1.4,1.361,1.361,0,0,1-1.395,1.4H941.788a1.337,1.337,0,0,1-1.342-1.342Z" transform="translate(-847.776 -769.237)" opacity="0.4" />
          <path id="Path_1848" data-name="Path 1848" d="M940.44,1021.21h29.331l.4.449H940.44Z" transform="translate(-847.776 -772.851)" opacity="0.4" />
          <path id="Path_1849" data-name="Path 1849" d="M1000.95,903.053l23.024.059,19.365-87.61h41.178a14.206,14.206,0,0,0,14.2-14.2V778.25h-69.173a16.218,16.218,0,0,0-14.991,10.061Z" transform="translate(-872.517 -673.51)" fill="#7bb200" />
          <path id="Path_1850" data-name="Path 1850" d="M1037.636,807.591c3.736-7.271,7.868-9.411,16.037-9.411h42.508l-2.14-19.921h13v23.509a13.738,13.738,0,0,1-13.738,13.738h-41.313l-19.412,87.61-10.617-.03Z" transform="translate(-881.107 -673.514)" fill="#4b6d00" opacity="0.34" />
          <path id="Path_1851" data-name="Path 1851" d="M999.36,701.873l9.96-68.617a17.446,17.446,0,0,1,13.194-14.441l26.642-6.325,26.843.248,24.8,5.923a17.438,17.438,0,0,1,13.194,19.572l-2.891,19.022-4.883,10.558a12.96,12.96,0,0,0-.55,1.791l-7.608,32.317Z" transform="translate(-871.867 -605.734)" fill="#c6de39" />
          <path id="Path_1852" data-name="Path 1852" d="M1039.637,675.789l61.335,2.465-1.306,12.668-61.276-2.79Z" transform="translate(-887.826 -631.616)" fill="#fff" />
          <path id="Path_1853" data-name="Path 1853" d="M1133.319,697.359l3.937-38.695a.407.407,0,0,0-.8-.148L1124.21,698.7Z" transform="translate(-922.916 -624.427)" opacity="0.2" />
          <path id="Path_1854" data-name="Path 1854" d="M1015.623,750.385a1.482,1.482,0,0,0,2.039.248l8.814-6.473-11.2,5.131A1.474,1.474,0,0,0,1015.623,750.385Z" transform="translate(-878.37 -659.571)" fill="#868a91" />
          <path id="Path_1855" data-name="Path 1855" d="M1033.494,658.5l-4.729,46.657-4.132,4.085-3.783-2.489,8.264-44.819C1029.959,658.255,1033.494,657.953,1033.494,658.5Z" transform="translate(-880.654 -624.461)" opacity="0.2" />
          <path id="Path_1856" data-name="Path 1856" d="M1108.634,612.775a2.918,2.918,0,0,1-1.094,3.038,21.927,21.927,0,0,1-18.473,2.737,20.815,20.815,0,0,1-6.023-2.985,3.048,3.048,0,0,1-1.247-2.039v-.946l-2.737.65a5.466,5.466,0,0,0,1.643,3.836c.8.8,12.6,9.907,27.635,1.5a4.986,4.986,0,0,0,2.938-5.131Z" transform="translate(-904.455 -605.771)" fill="#fff" />
          <path id="Path_1857" data-name="Path 1857" d="M1040.72,735.037l-7.07.946-15.091-1.342a5.062,5.062,0,0,0-3.488,1.5l-9.31,8.317a1.486,1.486,0,0,0,1.892,2.288l8.814-6.473-5.728,6.774a1.447,1.447,0,0,0,.6,2.293,1.472,1.472,0,0,0,1.5-.3l7.667-7.519-5.828,5.728a1.349,1.349,0,0,0,1.744,2.045l8.169-5.976-5.527,4.085a1.161,1.161,0,0,0,0,1.892,1.191,1.191,0,0,0,1.147.148l7.419-3.086a2.7,2.7,0,0,1,2.24.047l2.837,1.4a16.74,16.74,0,0,0,7.419,1.744h4.629C1051.378,749.531,1040.72,735.037,1040.72,735.037Z" transform="translate(-874.284 -655.679)" fill="#fc9d9d" />
          <path id="Path_1858" data-name="Path 1858" d="M1028.412,753.69a.186.186,0,0,0,.148-.047l5.078-3.736a.249.249,0,0,0-.3-.4l-5.078,3.736a.244.244,0,0,0-.047.349A.413.413,0,0,0,1028.412,753.69Z" transform="translate(-883.641 -661.736)" opacity="0.15" />
          <path id="Path_1859" data-name="Path 1859" d="M1021.079,752.323c.047,0,.148,0,.148-.047l5.48-5.379a.247.247,0,0,0-.349-.349l-5.48,5.379a.24.24,0,0,0,0,.349A.309.309,0,0,0,1021.079,752.323Z" transform="translate(-880.634 -660.517)" opacity="0.15" />
          <path id="Path_1860" data-name="Path 1860" d="M1035.217,752.746c-1.691.845-4.534,2.294-5.527,2.837-1.4.8-1.791-.248-1.791-.248a1.218,1.218,0,0,0,.449,1.247,1.191,1.191,0,0,0,1.147.148l7.418-3.086a2.7,2.7,0,0,1,2.24.047l-1.442-.845A2.755,2.755,0,0,0,1035.217,752.746Z" transform="translate(-883.523 -662.979)" opacity="0.15" />
          <path id="Path_1861" data-name="Path 1861" d="M1067.18,713.244l42.773-6.272,2.79-27.091,26.689,1.342-5.426,34.61a13.368,13.368,0,0,1-13.194,11.3h-48.649Z" transform="translate(-899.597 -633.289)" fill="#4d61fc" />
          <path id="Path_1862" data-name="Path 1862" d="M1057.32,713.276l48.6-6.224,2.79-27.091L1135.4,681.3l-5.426,34.61a13.368,13.368,0,0,1-13.194,11.3h-54.625Z" transform="translate(-895.565 -633.322)" fill="#c6de39" />
          <path id="Path_1863" data-name="Path 1863" d="M1062.149,735.311l-4.83.6,4.782,13.9h5.976Z" transform="translate(-895.565 -655.953)" fill="#fff" />
        </g>
        <g id="Group_955" data-name="Group 955" transform="translate(11811.228 1450.672)" opacity="0.2">
          <path id="Path_1864" data-name="Path 1864" d="M1064.644,729.969a.364.364,0,0,0,.349.3h.047l43.92-6.372.1-.8-44.121,6.425A.383.383,0,0,0,1064.644,729.969Z" transform="translate(-1064.635 -723.1)" opacity="0.2" />
        </g>
        <g id="Group_970" data-name="Group 970" transform="translate(11698.894 1390.319)">
          <path id="Path_1865" data-name="Path 1865" d="M1064.06,700.571l.8,2.441h54.625a13.332,13.332,0,0,0,13.194-11.3l7.82-50.044a17.446,17.446,0,0,0-13.194-19.572L1122.675,621a17.417,17.417,0,0,1,10.161,18.526l-7.224,46.113a13.368,13.368,0,0,1-13.194,11.3Z" transform="translate(-952.067 -621)" opacity="0.15" />
          <path id="Path_1866" data-name="Path 1866" d="M1051.862,747.776a2.012,2.012,0,0,0,1.945-2.193l-1.094-9.263,4.829,13.891h-.7a16.554,16.554,0,0,1-7.419-1.744l-3.387-1.6A24.817,24.817,0,0,0,1051.862,747.776Z" transform="translate(-944.698 -668.152)" opacity="0.15" />
          <path id="Path_1867" data-name="Path 1867" d="M1042.223,759.15h-49.1a.765.765,0,0,0-.745.745v1.247a.765.765,0,0,0,.745.745h49.1a.765.765,0,0,0,.745-.745v-1.247A.734.734,0,0,0,1042.223,759.15Z" transform="translate(-922.758 -677.487)" fill="#21252b" />
          <path id="Path_1868" data-name="Path 1868" d="M1042.223,759.15h-49.1a.765.765,0,0,0-.745.745v1.247a.765.765,0,0,0,.745.745h49.1a.765.765,0,0,0,.745-.745v-1.247A.734.734,0,0,0,1042.223,759.15Z" transform="translate(-922.758 -677.487)" opacity="0.6" />
          <path id="Path_1869" data-name="Path 1869" d="M960.951,759.15H875.345a.765.765,0,0,0-.745.745v1.247a.765.765,0,0,0,.745.745h85.6a.765.765,0,0,0,.745-.745v-1.247A.8.8,0,0,0,960.951,759.15Z" transform="translate(-874.6 -677.487)" fill="#21252b" />
        </g>
        <g id="Group_956" data-name="Group 956" transform="translate(11700.489 1471.982)" opacity="0.6">
          <path id="Path_1870" data-name="Path 1870" d="M877.548,759.646h87.143a.248.248,0,1,0,0-.5H877.548a.236.236,0,0,0-.248.248A.266.266,0,0,0,877.548,759.646Z" transform="translate(-877.3 -759.15)" opacity="0.6" />
        </g>
        <g id="Group_969" data-name="Group 969" transform="translate(11687.902 1325.539)">
          <path id="Path_1871" data-name="Path 1871" d="M1003.177,716.989h-12.9a2.075,2.075,0,0,1-2.039-1.691L977.434,658.48a2.052,2.052,0,0,1,2.039-2.441h12.9a2.075,2.075,0,0,1,2.039,1.691l10.806,56.818A2.076,2.076,0,0,1,1003.177,716.989Z" transform="translate(-905.639 -570.547)" fill="#21252b" />
          <path id="Path_1872" data-name="Path 1872" d="M1003.177,716.989h-12.9a2.075,2.075,0,0,1-2.039-1.691L977.434,658.48a2.052,2.052,0,0,1,2.039-2.441h12.9a2.075,2.075,0,0,1,2.039,1.691l10.806,56.818A2.076,2.076,0,0,1,1003.177,716.989Z" transform="translate(-905.639 -570.547)" opacity="0.6" />
          <path id="Path_1873" data-name="Path 1873" d="M951.7,716.989H868.889a2.075,2.075,0,0,1-2.039-1.691L856.044,658.48a2.053,2.053,0,0,1,2.039-2.441h82.763a2.075,2.075,0,0,1,2.039,1.691l10.806,56.818A2.014,2.014,0,0,1,951.7,716.989Z" transform="translate(-856.005 -570.547)" fill="#21252b" />
          <path id="Path_1874" data-name="Path 1874" d="M935.838,702.041c.5,1.147-.047,2.14-1.194,2.14h-.2a7.208,7.208,0,0,0,5.178,2.489c2.441-.047,3.588-2.039,2.489-4.481a7.433,7.433,0,0,0-6.372-4.28A2.705,2.705,0,0,0,932.9,700,3.6,3.6,0,0,1,935.838,702.041Z" transform="translate(-887.446 -587.667)" opacity="0.6" />
          <path id="Path_1875" data-name="Path 1875" d="M1079.9,557.93s22.806-16.735,25.046,1.5l.65,4.782,3.387,23.355v.047l.047.5a2.391,2.391,0,0,1-.5,1.844V590l-.047.047a2.152,2.152,0,0,1-.6.55,22.827,22.827,0,0,1-4.534,2.294,21.314,21.314,0,0,1-20.565-2.985,2.849,2.849,0,0,1-.65-1.992l.4-12.148a20.161,20.161,0,0,1-.8-4.185C1080.994,564.408,1079.9,557.93,1079.9,557.93Z" transform="translate(-947.551 -527.565)" fill="#fc9d9d" />
          <path id="Path_1876" data-name="Path 1876" d="M1069.906,553.742a8.116,8.116,0,1,0-8.116-8.116A8.118,8.118,0,0,0,1069.906,553.742Z" transform="translate(-940.147 -522.083)" fill="#21252b" />
          <path id="Path_1877" data-name="Path 1877" d="M1073.136,539.575s-5.876,36.2,12.6,37.246c15.984.946,15.884-23.8,15.884-23.8l-1.945-15.937Z" transform="translate(-944.422 -521.907)" fill="#fc9d9d" />
          <path id="Path_1878" data-name="Path 1878" d="M1071.6,531.226h16.232a3.676,3.676,0,0,1,3.683,3.683,3.676,3.676,0,0,0,3.683,3.683,1,1,0,0,1,.993.993v3.588a.837.837,0,0,0,.845.845.813.813,0,0,0,.8-.7l.5-3.434.65-1.194,3.984,2.093s5.48-9.96,1.892-18.378c-2.039-4.829-7.07-6.177-9.363-6.325a2.613,2.613,0,0,1-1.892-1.046c-1.046-1.543-3.535-4.132-8.613-3.535a8.489,8.489,0,0,0-6.875,4.534,2.567,2.567,0,0,1-3.038,1.147,7.038,7.038,0,0,0-6.774.993C1063.139,521.762,1065.93,530.777,1071.6,531.226Z" transform="translate(-941.697 -511.413)" fill="#21252b" />
          <path id="Path_1879" data-name="Path 1879" d="M1122.081,575.179a3.767,3.767,0,0,1-2.341-.349l2.837,3.783Z" transform="translate(-963.841 -537.343)" opacity="0.15" />
          <path id="Path_1880" data-name="Path 1880" d="M1120.285,557.818a3.264,3.264,0,0,1,4.534-2.14c4.084,1.442,3.984,10.557-2.737,11.55-3.535.55-2.938-1.543-2.542-4.333Z" transform="translate(-963.634 -529.393)" fill="#fc9d9d" />
          <path id="Path_1881" data-name="Path 1881" d="M1126.535,559.449a2.484,2.484,0,0,0-1.992-.349,2.539,2.539,0,0,0-1.442,1.342,5.149,5.149,0,0,0-.5,2.489v.8a.423.423,0,1,0,.845-.053v-.8a4.951,4.951,0,0,1,.4-2.093,1.742,1.742,0,0,1,.946-.946,1.83,1.83,0,0,1,1.342.3.405.405,0,0,0,.55-.148A.423.423,0,0,0,1126.535,559.449Z" transform="translate(-965.008 -530.875)" opacity="0.3" />
          <path id="Path_1882" data-name="Path 1882" d="M1073.313,544.949l1.892.047a.373.373,0,1,0,.047-.745l-1.891-.047a2.8,2.8,0,0,1-.7-.1,9.186,9.186,0,0,1-2.24-.9.366.366,0,1,0-.349.644,8.7,8.7,0,0,0,2.441.946A1.866,1.866,0,0,0,1073.313,544.949Z" transform="translate(-943.453 -524.392)" fill="#fc9d9d" />
          <path id="Path_1883" data-name="Path 1883" d="M1083.69,604.182l.2-5.775a12.2,12.2,0,0,0,11.054,1.892h0a12.156,12.156,0,0,0,6.077-4.433.358.358,0,0,1,.6.4,13.257,13.257,0,0,1-8.069,5.131A13.034,13.034,0,0,1,1083.69,604.182Z" transform="translate(-949.101 -545.881)" opacity="0.15" />
        </g>
        <g id="Group_957" data-name="Group 957" transform="translate(11826.824 1372.343)" opacity="0.45">
          <path id="Path_1884" data-name="Path 1884" d="M1091.469,591.583h2.24a.5.5,0,1,0,0-.993h-2.193a.5.5,0,0,0-.5.5A.655.655,0,0,0,1091.469,591.583Z" transform="translate(-1091.02 -590.59)" opacity="0.3" />
        </g>
        <g id="Group_958" data-name="Group 958" transform="translate(11823.97 1367.516)" opacity="0.45">
          <path id="Path_1885" data-name="Path 1885" d="M1088.6,586.2c4.031.3,5.675-1.992,6.225-3.139a.445.445,0,0,0-.8-.4c-.55,1.094-2.341,3.535-7.271,2.294a.46.46,0,1,0-.2.9A10.409,10.409,0,0,0,1088.6,586.2Z" transform="translate(-1086.192 -582.424)" opacity="0.3" />
        </g>
        <g id="Group_959" data-name="Group 959" transform="translate(11812.978 1355.843)" opacity="0.45">
          <path id="Path_1890" data-name="Path 1890" d="M1086.774,580.434h1.992a.449.449,0,1,0,0-.9h-1.992a.9.9,0,0,1,0-1.8.449.449,0,0,0,0-.9,1.8,1.8,0,0,0,0,3.594Z" transform="translate(-1074.725 -568.468)" opacity="0.3" />
          <g id="Group_968" data-name="Group 968" transform="translate(0 0)">
            <path id="Path_1886" data-name="Path 1886" d="M1097.092,569.965a1.442,1.442,0,1,0-1.442-1.442A1.441,1.441,0,0,0,1097.092,569.965Z" transform="translate(-1079.067 -564.477)" fill="#21252b" />
            <path id="Path_1887" data-name="Path 1887" d="M1096.7,570.2a.3.3,0,1,0-.3-.3A.3.3,0,0,0,1096.7,570.2Z" transform="translate(-1079.373 -565.508)" fill="#fff" />
            <path id="Path_1888" data-name="Path 1888" d="M1077.552,571.485a1.442,1.442,0,1,0-1.442-1.442A1.44,1.44,0,0,0,1077.552,571.485Z" transform="translate(-1071.077 -565.099)" fill="#21252b" />
            <path id="Path_1889" data-name="Path 1889" d="M1077.161,571.642a.3.3,0,1,0-.3-.3A.3.3,0,0,0,1077.161,571.642Z" transform="translate(-1071.384 -566.096)" fill="#fff" />
            <path id="Path_1891" data-name="Path 1891" d="M1067.6,566.326l.1,1.643a5.739,5.739,0,1,0,11.45-.8l-.1-1.643a2.172,2.172,0,0,0-.55-1.247l2.24-.148a1.909,1.909,0,0,0-.349,1.295l.1,1.643a5.739,5.739,0,0,0,11.45-.8l-.1-1.643a2.08,2.08,0,0,0-2.24-1.945l-7.17.5h-.047l-5.58.4-.845.047-6.372.449A2.235,2.235,0,0,0,1067.6,566.326Zm14.89-2.294h.047l7.17-.5a1.273,1.273,0,0,1,1.342,1.194l.1,1.643a4.9,4.9,0,0,1-9.765.7l-.1-1.643A1.34,1.34,0,0,1,1082.489,564.032Zm-14,2.193a1.273,1.273,0,0,1,1.194-1.342l6.372-.449.845-.047a1.273,1.273,0,0,1,1.342,1.194l.1,1.643a4.9,4.9,0,1,1-9.765.7Z" transform="translate(-1067.596 -562.677)" fill="#4b6d00" />
          </g>
        </g>
        <path id="Path_1892" data-name="Path 1892" d="M788.413,177.471H779.67V507.9h8.743Z" transform="translate(10865.268 1120.696)" fill="#fff" />
        <path id="Path_1999" data-name="Path 1999" d="M321.279,662.192c-78.672,26.931-67.683,135.946-57.847,186.836a32.54,32.54,0,0,0,31.932,26.405H512.838a32.444,32.444,0,0,0,31.873-26.1c2.489-12.656,3.955-29.9,1.572-51.7-5.645-51.729-46.6-59.721-46.6-59.721s-51.906-8.465-72.6-35.272S373.481,644.322,321.279,662.192Z" transform="translate(11076.994 754.615)" fill="#b1b5ba" />
        <g id="Group_972" data-name="Group 972" transform="translate(11401.241 1344.899)">
          <path id="Path_2000" data-name="Path 2000" d="M507.889,748.02l-6.709-7.5a4.234,4.234,0,0,0-2.654-1.507l-12.2-.343-19.808-3.695-8.057,8.554,17.261,3.121,1.6.822a10.755,10.755,0,0,0,8.689.716l.071.006,2.435-.851a2.344,2.344,0,0,1,1.821.142l5.651,3.126a.882.882,0,0,0,.922-.035,1,1,0,0,0,.177-1.536l-4.02-3.79,6.018,5.5a1.114,1.114,0,0,0,1.029.26,1.069,1.069,0,0,0,.55-1.708l-4.15-5.149,5.456,6.715a1.09,1.09,0,0,0,1.164.349,1.168,1.168,0,0,0,.7-1.762l-3.984-5.976,6.455,5.976a1.135,1.135,0,0,0,1.631-.024A1.039,1.039,0,0,0,507.889,748.02Z" transform="translate(-406.796 -622.185)" fill="#f69797" />
          <path id="Path_2001" data-name="Path 2001" d="M467.78,747.609l11.509,2.672a5.709,5.709,0,0,1,2.477,1.206,10.844,10.844,0,0,0,5.752,2.288s-4.179,1.289-9.05-1.892l-13.247-2.305Z" transform="translate(-409.56 -627.349)" opacity="0.15" />
          <path id="Path_2002" data-name="Path 2002" d="M596.533,993.184l27.2.833c.473-.8,1.809-3.216.266-3.789l-18.337-7.968c-3.239-1.218-6.963-2.66-7.708-4.906-.355-.532.272-1.614.065-2.217l-1.8-27.387-13.631,3.038-1.708,24.171c.3,2.4.035,6.461.041,8.766l-.041,3.718a5.345,5.345,0,0,0,4.971,5.285h.077Z" transform="translate(-456.851 -709.182)" fill="#f69797" />
          <path id="Path_2003" data-name="Path 2003" d="M606.036,991.353l-9.659-.443H596.3a5.345,5.345,0,0,1-4.971-5.285l.041-3.718c-.006-2.305-.686-6.366-.981-8.766l1.685-23.81-9.476,2.11-1.708,24.171c.3,2.4.035,6.461.041,8.766l-.041,3.718a5.345,5.345,0,0,0,4.971,5.285h.077l10.6.443,27.2.834a6.179,6.179,0,0,0,.981-2.737Z" transform="translate(-456.855 -709.828)" opacity="0.15" />
          <path id="Path_2004" data-name="Path 2004" d="M527.678,820.529H468.755a21.993,21.993,0,0,1-21.966-21.6l-.219-13.708h96.27a15.235,15.235,0,0,1,15.121,17.119L542.852,909.965,526,907.837l-.627-19.016C522.576,852.012,523.558,843.269,527.678,820.529Z" transform="translate(-401.934 -642.727)" fill="#4b6d00" />
          <path id="Path_2005" data-name="Path 2005" d="M541.861,805.851a19.637,19.637,0,0,0-19.6-20.565c-29.509-.024-63.285-.065-63.285-.065H446.56l.219,13.708a21.993,21.993,0,0,0,21.966,21.6h58.923c-4.12,22.74-5.1,31.483-2.305,68.3l.627,19.016,9.682,1.224Z" transform="translate(-401.93 -642.727)" opacity="0.2" />
          <path id="Path_2006" data-name="Path 2006" d="M637.761,992.07H664.74c.443-.81,1.7-3.245.148-3.76l-18.354-7.885c-3.245-1.105-5.456-1.844-7.371-4.274a7.625,7.625,0,0,1-.958-1.7l-14.3-32.435L606.29,950.5l14.961,25.945c1.324,1.915,1.62,4.422,1.7,6.709l.071,3.683a5.293,5.293,0,0,0,5.084,5.084h.071Z" transform="translate(-467.24 -706.839)" fill="#f69797" />
          <path id="Path_2007" data-name="Path 2007" d="M671.78,1006.544c-.219.514-.443.958-.591,1.253H644.21l-9.659-.148h-.071a5.239,5.239,0,0,1-5.084-5.084l-.071-3.683a12.954,12.954,0,0,0-1.7-6.709l-5.379-9.286.3-2.577,8.181,5.9,5.161,12.531s4.936,6.709,19.678,7.294l14.743.514h1.472Z" transform="translate(-473.766 -722.496)" opacity="0.15" />
          <path id="Path_2008" data-name="Path 2008" d="M545.527,902.086l-15.7,6.485-9.949-16.143c-24.106-35.751-28.876-41.97-38.458-71.821H410.426a21.993,21.993,0,0,1-21.966-21.6l5.149-13.785,91.346.071a17.579,17.579,0,0,1,16.214,10.835Z" transform="translate(-378.174 -642.727)" fill="#4b6d00" />
          <path id="Path_2009" data-name="Path 2009" d="M519.984,877.912c-19.016-32.724-18.709-36.148-27.257-63.345-2.359-7.519-7.738-9.138-11.574-9.138l-33.617.219c-11.35-.3-31.034.886-40.391-5.457L390.783,785.23h2.938l-5,13.708a21.942,21.942,0,0,0,21.966,21.6h70.988c10.321,30.809,14.429,36.295,38.458,71.821l9.948,16.143,5.308-2.211C535.312,906.363,523.371,883.806,519.984,877.912Z" transform="translate(-378.281 -642.731)" opacity="0.2" />
          <path id="Path_2010" data-name="Path 2010" d="M458.938,606.269a4.652,4.652,0,0,0,2.938,1.566c5.16,1.052,9.091,1.791,11.355-2.985,3.464-7.554,8.323-25.335,5.858-37.465-5.03.573-6.059,1.312-6.059,1.312s-5.521-4.274-5.627-4.386C467.433,564.24,452.513,597.443,458.938,606.269Z" transform="translate(-406.334 -552.402)" fill="#21252b" />
          <path id="Path_2011" data-name="Path 2011" d="M418.034,587.231l12.189-42.661c-1.98-1.123-6.378.367-7.785,1.105-18.343,10.386-28.734,44.5-32.328,57.345,4.392,13.241,24.005-5.852,26.943-7.442C418.507,594.7,418.034,587.231,418.034,587.231Z" transform="translate(-378.849 -544.164)" fill="#21252b" />
          <path id="Path_2012" data-name="Path 2012" d="M458.573,615.653l-22.09,26.937L420.02,617.669l1.442-2.56c.148-.053.29-.035.437-.089a4.385,4.385,0,0,0,2.973-4.327l4.741-13.915,15.688,12.23c.254,2.081.81,5.858,4.339,5.947Z" transform="translate(-391.079 -565.677)" fill="#f69797" />
          <path id="Path_2013" data-name="Path 2013" d="M400.456,628.663c.692-.018,4.906-.148,7.868,2.619,12.36,11.55,24.52,9.5,27.966-3.511l2.766.159a121.017,121.017,0,0,0,6.52,18.018c.768,1.75,3.9,9.109,3.9,9.109,4.3,11.314-1.821,18.916-1.821,18.916a5.566,5.566,0,0,0-.881,2.73s-.508,21.169,2.967,31.673c1.3,3.937,6.065,15.588,6.065,15.588s-29.361.946-41.313,6.437l-18.52,8.483a7.444,7.444,0,0,1-10.108-9.3l1.046-2.92c3.949-10.936,11.675-22.522,11.018-34.055l-1.212-26.34-12.762-8.749-1.058-7.8C384.443,632.623,398.068,628.722,400.456,628.663Z" transform="translate(-375.901 -578.349)" fill="#7bb200" />
          <path id="Path_2014" data-name="Path 2014" d="M440.061,776.79l.313-.248a1.868,1.868,0,0,1,.3-2.583l-.248-.313a1.867,1.867,0,0,1-2.583-.3l-.313.248a1.867,1.867,0,0,1-.3,2.583l.248.313A1.742,1.742,0,0,1,440.061,776.79Z" transform="translate(-398.115 -637.874)" fill="#4d61fc" />
          <path id="Path_2015" data-name="Path 2015" d="M400.212,696.853a1.056,1.056,0,0,1,1.159.981s1.377,11.042,9.919,12.632c-.479.633-5.752,1.4-6.065,2.282,0,0-2.234,43.784,4.3,49.069l-11.58,5.32a7.444,7.444,0,0,1-10.108-9.3l1.046-2.92c3.949-10.936,11.414-21.375,10.764-32.908l-.431-23.917A1.042,1.042,0,0,1,400.212,696.853Z" transform="translate(-377.737 -606.593)" opacity="0.3" />
          <path id="Path_2016" data-name="Path 2016" d="M489.441,782.469l.313-.248a1.867,1.867,0,0,1,.3-2.583l-.248-.313a1.867,1.867,0,0,1-2.583-.3l-.313.248a1.867,1.867,0,0,1-.3,2.583l.248.313A1.748,1.748,0,0,1,489.441,782.469Z" transform="translate(-418.305 -640.196)" fill="#4d61fc" />
          <path id="Path_2017" data-name="Path 2017" d="M488.957,705.92a9.988,9.988,0,0,1-4.877,3.494l3.547,2.92.455-3.683A6.343,6.343,0,0,1,488.957,705.92Z" transform="translate(-417.271 -610.303)" opacity="0.3" />
          <path id="Path_2018" data-name="Path 2018" d="M494.655,775.389l-6.709-7.489a4.235,4.235,0,0,0-2.654-1.507l-13.608.106L453.4,761.539,445.22,770.9l17.261,3.121,1.6.822a10.755,10.755,0,0,0,8.689.715l.071.006,2.435-.851a2.344,2.344,0,0,1,1.821.142l5.651,3.127a.882.882,0,0,0,.922-.035,1,1,0,0,0,.177-1.537l-4.02-3.789,6.018,5.5a1.113,1.113,0,0,0,1.029.26,1.069,1.069,0,0,0,.55-1.708l-4.15-5.149,5.456,6.715a1.089,1.089,0,0,0,1.164.349,1.167,1.167,0,0,0,.7-1.761l-3.984-5.976,6.455,5.976a1.135,1.135,0,0,0,1.632-.024C495,776.636,495.093,775.868,494.655,775.389Z" transform="translate(-401.382 -633.044)" fill="#f69797" />
          <path id="Path_2019" data-name="Path 2019" d="M507.684,784.565c-.071-.006-.071-.006-.13-.089l-3.706-3.47c-.059-.077-.118-.225-.035-.29s.225-.118.29-.035l3.706,3.47c.059.077.118.225.035.29S507.684,784.565,507.684,784.565Z" transform="translate(-425.322 -640.855)" opacity="0.15" />
          <path id="Path_2020" data-name="Path 2020" d="M513.386,784.087c-.071-.006-.142-.018-.13-.089l-3.9-4.835a.2.2,0,0,1,.307-.248l3.9,4.835a.216.216,0,0,1-.03.278C513.527,784.1,513.456,784.093,513.386,784.087Z" transform="translate(-427.59 -640.121)" opacity="0.15" />
          <path id="Path_2021" data-name="Path 2021" d="M378.059,664.869l-7,55.163a9.5,9.5,0,0,0,5.888,7.259l33.836,13.377,17.757-17.2L389.207,713l11.7-42.768Z" transform="translate(-371.06 -593.518)" fill="#7bb200" />
          <path id="Path_2022" data-name="Path 2022" d="M419.88,750.7l.213-.615L460.33,761.2l-.1.272Z" transform="translate(-391.021 -628.363)" opacity="0.3" />
          <path id="Path_2023" data-name="Path 2023" d="M454.55,775.551l11.509,2.672a5.712,5.712,0,0,1,2.477,1.206,10.845,10.845,0,0,0,5.752,2.288s-4.179,1.289-9.05-1.892l-13.247-2.305Z" transform="translate(-404.15 -638.774)" opacity="0.15" />
          <path id="Path_2024" data-name="Path 2024" d="M423.447,648.037a.982.982,0,1,0-.863-1.088A.954.954,0,0,0,423.447,648.037Z" transform="translate(-392.123 -585.835)" fill="#f4f4f4" />
          <path id="Path_2025" data-name="Path 2025" d="M470.572,786.449H513.73a.633.633,0,0,1,.633.633V788.2a.633.633,0,0,1-.633.633H470.572a.633.633,0,0,1-.632-.633v-1.123A.589.589,0,0,1,470.572,786.449Z" transform="translate(-411.49 -643.229)" fill="#21252b" />
          <path id="Path_2026" data-name="Path 2026" d="M470.572,786.449H513.73a.633.633,0,0,1,.633.633V788.2a.633.633,0,0,1-.633.633H470.572a.633.633,0,0,1-.632-.633v-1.123A.589.589,0,0,1,470.572,786.449Z" transform="translate(-411.49 -643.229)" opacity="0.6" />
          <path id="Path_2027" data-name="Path 2027" d="M519.8,786.449H597.73a.633.633,0,0,1,.633.633V788.2a.633.633,0,0,1-.633.633H519.8a.633.633,0,0,1-.632-.633v-1.123A.626.626,0,0,1,519.8,786.449Z" transform="translate(-431.619 -643.229)" fill="#21252b" />
        </g>
        <g id="Group_962" data-name="Group 962" transform="translate(11486.888 1488.113)" opacity="0.6">
          <path id="Path_2028" data-name="Path 2028" d="M592.778,786.865H516.163a.213.213,0,0,1,0-.426h76.615a.188.188,0,0,1,.213.213A.227.227,0,0,1,592.778,786.865Z" transform="translate(-515.95 -786.439)" opacity="0.6" />
        </g>
        <g id="Group_966" data-name="Group 966" transform="translate(11440.12 1352.75)">
          <path id="Path_2029" data-name="Path 2029" d="M522.451,753.062l11.314-.13a1.941,1.941,0,0,0,1.809-1.5l8.914-50.08a1.835,1.835,0,0,0-1.85-2.158l-11.314.13a1.941,1.941,0,0,0-1.809,1.5L520.6,750.9A1.84,1.84,0,0,0,522.451,753.062Z" transform="translate(-471.078 -615.405)" fill="#21252b" />
          <path id="Path_2030" data-name="Path 2030" d="M522.451,753.062l11.314-.13a1.941,1.941,0,0,0,1.809-1.5l8.914-50.08a1.835,1.835,0,0,0-1.85-2.158l-11.314.13a1.941,1.941,0,0,0-1.809,1.5L520.6,750.9A1.84,1.84,0,0,0,522.451,753.062Z" transform="translate(-471.078 -615.405)" opacity="0.6" />
          <path id="Path_2031" data-name="Path 2031" d="M525.228,752.537l72.814-.834a1.941,1.941,0,0,0,1.809-1.5l8.914-50.08a1.835,1.835,0,0,0-1.85-2.158L534.1,698.8a1.941,1.941,0,0,0-1.809,1.5l-8.843,50.08A1.755,1.755,0,0,0,525.228,752.537Z" transform="translate(-472.236 -614.903)" fill="#21252b" />
          <path id="Path_2032" data-name="Path 2032" d="M592.107,739.268c-.408.987.095,1.9,1.076,1.886h.142a6.156,6.156,0,0,1-4.546,2.229c-2.181-.047-3.186-1.791-2.223-3.913a6.57,6.57,0,0,1,5.58-3.86,2.553,2.553,0,0,1,2.554,1.8A3.318,3.318,0,0,0,592.107,739.268Z" transform="translate(-497.902 -630.293)" opacity="0.6" />
          <path id="Path_2033" data-name="Path 2033" d="M449.434,596.119l-.993-.154c-7.383-1.147-12.6-8.607-11.45-15.99l2.808-18.7a4.51,4.51,0,0,1,5.113-3.771l17.491,2.648a5.855,5.855,0,0,1,4.912,6.674l-2.619,17.42C463.567,591.556,457.095,597.52,449.434,596.119Z" transform="translate(-436.832 -557.445)" fill="#f69797" />
          <path id="Path_2034" data-name="Path 2034" d="M460.947,606.143l.366.816a3.313,3.313,0,0,0,5.444.709l.68-.8c.142-.171.083-.42-.136-.426l-6.071-.644C461.035,605.723,460.87,605.965,460.947,606.143Z" transform="translate(-446.684 -577.21)" fill="#fff" />
          <path id="Path_2035" data-name="Path 2035" d="M459.445,590.228a1.476,1.476,0,1,0-.857-1.9A1.443,1.443,0,0,0,459.445,590.228Z" transform="translate(-445.686 -569.678)" fill="#21252b" />
          <path id="Path_2036" data-name="Path 2036" d="M461.516,591.145a.281.281,0,1,0-.166-.361A.308.308,0,0,0,461.516,591.145Z" transform="translate(-446.853 -571.004)" fill="#fff" />
          <path id="Path_2037" data-name="Path 2037" d="M478.175,594.148a1.476,1.476,0,1,0-.857-1.9A1.443,1.443,0,0,0,478.175,594.148Z" transform="translate(-453.344 -571.281)" fill="#21252b" />
          <path id="Path_2038" data-name="Path 2038" d="M480.406,595a.281.281,0,1,0-.165-.361A.3.3,0,0,0,480.406,595Z" transform="translate(-454.576 -572.579)" fill="#fff" />
        </g>
        <g id="Group_963" data-name="Group 963" transform="translate(11457.162 1376.375)" opacity="0.3">
          <path id="Path_2039" data-name="Path 2039" d="M467.99,600.75l-1.951-.213c-.225-.012-.437-.242-.361-.437.012-.225.242-.437.437-.361l1.951.213a.869.869,0,0,0,.2-1.726c-.225-.012-.437-.242-.361-.437.012-.225.242-.437.437-.361a1.771,1.771,0,0,1,1.525,1.933A1.658,1.658,0,0,1,467.99,600.75Z" transform="translate(-465.663 -597.412)" opacity="0.3" />
        </g>
        <g id="Group_965" data-name="Group 965" transform="translate(11304.615 1344.256)">
          <path id="Path_2040" data-name="Path 2040" d="M465.189,566.481l.721.272c1.431-20.429-18.4-27.8-29.893-21.481-6.94,3.831-12.467,12.26-12.467,12.26.284,4.014,3.6,8.352,4.079,11.243.2,1.277,3.31-3.18,4.416-3.511a11.372,11.372,0,0,1,1.259,2.43.806.806,0,0,0,1.466.106,21.621,21.621,0,0,0,2.353-7l.313-2.211c4.954.225,20.2.065,23.527.2.822.012,1.058,1.377.981,2.175L460.3,571.842l3.659-4.1Z" transform="translate(-295.897 -543.076)" fill="#21252b" />
          <path id="Path_2041" data-name="Path 2041" d="M433.484,588.993s-6.467-.2-7.093-6.674c-.692-6.5,7.306-6.78,7.773-.284l.112,7.034Z" transform="translate(-297.041 -557.07)" fill="#f69797" />
          <path id="Path_2042" data-name="Path 2042" d="M430.207,580.149a2.4,2.4,0,0,1,1.992-.3,2.355,2.355,0,0,1,1.413,1.436,5.45,5.45,0,0,1,.449,2.577l-.03.668c-.041.508-.893.562-.887-.035l.053-.733a5.064,5.064,0,0,0-.337-2.081,1.7,1.7,0,0,0-.916-.952,1.958,1.958,0,0,0-1.365.236.431.431,0,0,1-.55-.136A.531.531,0,0,1,430.207,580.149Z" transform="translate(-298.523 -558.069)" opacity="0.3" />
          <path id="Path_2043" data-name="Path 2043" d="M470,582.2a5.771,5.771,0,0,0-4.759,1.655,5.319,5.319,0,0,0-3.576-.68,6.009,6.009,0,0,0-4.782-3.842A5.865,5.865,0,1,0,462,585.855a4.936,4.936,0,0,0-.1-1.915,4.951,4.951,0,0,1,2.9.5,6.06,6.06,0,0,0-1.188,2.932,5.865,5.865,0,0,0,11.645,1.407A6.067,6.067,0,0,0,470,582.2Zm-14.477,8.039a5.1,5.1,0,1,1,5.675-4.463A5.119,5.119,0,0,1,455.526,590.235Zm13.212,2.832a5.1,5.1,0,1,1,5.675-4.463A5.061,5.061,0,0,1,468.738,593.067Z" transform="translate(-306.842 -557.881)" fill="#fff" />
          <path id="Path_2044" data-name="Path 2044" d="M434.705,593.307a1.265,1.265,0,1,0-.733-1.631A1.261,1.261,0,0,0,434.705,593.307Z" transform="translate(-300.125 -562.614)" fill="#4d61fc" />
          <path id="Path_2045" data-name="Path 2045" d="M435.348,593.269a1.386,1.386,0,0,1-1.23-1.519,1.477,1.477,0,0,1,.272-.721,1.27,1.27,0,0,0-.662.952,1.2,1.2,0,0,0,1.123,1.4,1.008,1.008,0,0,0,.739-.171C435.549,593.346,435.484,593.323,435.348,593.269Z" transform="translate(-300.052 -562.683)" opacity="0.15" />
          <path id="Path_2046" data-name="Path 2046" d="M435.363,596.77l2.211.313-3.493,4.463Z" transform="translate(-300.203 -565.03)" opacity="0.15" />
          <path id="Path_2047" data-name="Path 2047" d="M468.571,565.147c-10.108-6.839-14.571,10.487-25.229,3.89-9.984-6.189,4.682-15.517,4.682-15.517l10.179,1.9,12.218,6.437Z" transform="translate(-302.566 -547.346)" fill="#21252b" />
          <path id="Path_2048" data-name="Path 2048" d="M445.588,580.384a7.794,7.794,0,0,1-2.938-2.163Z" transform="translate(-303.707 -557.446)" />
          <path id="Path_2049" data-name="Path 2049" d="M445.588,580.384a7.794,7.794,0,0,1-2.938-2.163" transform="translate(-303.707 -557.446)" fill="none" stroke="#f69797" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.299" />
          <path id="Path_2050" data-name="Path 2050" d="M459.376,623.342a3.277,3.277,0,0,1-.473-.887,14.479,14.479,0,0,1-6.881-.9.429.429,0,0,0-.331.792,18.775,18.775,0,0,0,1.886.579A14.518,14.518,0,0,0,459.376,623.342Z" transform="translate(-307.304 -575.158)" opacity="0.15" />
          <path id="Path_2051" data-name="Path 2051" d="M424.842,596.22s-.757,24.786,2.211,34.4a49.592,49.592,0,0,1-5.066.361c-.372.006-1-8.187-1.4-8.187-.42,0-.6,8.193-1.04,8.181-3.523-.1-7.5-.65-10.043-2.347-2.962-1.957.083-20.843,5.373-29.45C418.617,593.111,424.842,596.22,424.842,596.22Z" transform="translate(-289.682 -564.48)" fill="#21252b" />
          <path id="Path_2052" data-name="Path 2052" d="M477.9,628.039l3.476.248a5.448,5.448,0,0,1,5.06,5.432v18.325C486.43,652.044,479.307,634.547,477.9,628.039Z" transform="translate(-318.12 -577.816)" fill="#7bb200" />
          <path id="Path_2053" data-name="Path 2053" d="M487.006,655.67c-2.577-6.041-7.7-15.57-7.7-15.57l7.7,11.592Z" transform="translate(-318.697 -582.747)" opacity="0.3" />
          <path id="Path_2054" data-name="Path 2054" d="M496.6,739.5l-2.282,14.471s-3.18-8.471-2.613-15.464Z" transform="translate(-323.736 -622.985)" fill="#7bb200" />
          <path id="Path_2055" data-name="Path 2055" d="M494.326,753.974a35.276,35.276,0,0,1-2.583-15.464l1.478.3s-.355,3.021-.39,6.544C492.807,747.832,494.326,753.974,494.326,753.974Z" transform="translate(-323.747 -622.985)" opacity="0.3" />
          <path id="Path_2056" data-name="Path 2056" d="M381.933,647.388l-6.266,44.234a14.151,14.151,0,0,0,9.783,15.889c.059.018.112.035.171.047l35.911,8.24-10.747,10.368L377.08,712.878a9.689,9.689,0,0,1-6-7.484l6.981-55.027c.786-9.145,5.905-18.561,16.894-20.967C388.518,631.28,382.625,638.438,381.933,647.388Z" transform="translate(-274.444 -578.372)" opacity="0.3" />
          <path id="Path_2057" data-name="Path 2057" d="M437.189,691.41a1.823,1.823,0,0,1,.55.78,1.7,1.7,0,0,1,.089.952,1.855,1.855,0,0,1-.638-1.732Z" transform="translate(-301.462 -603.727)" fill="#fff" />
          <path id="Path_2058" data-name="Path 2058" d="M436.65,694.659a1.554,1.554,0,1,1-.869.018A1.438,1.438,0,0,1,436.65,694.659Z" transform="translate(-300.603 -605.031)" fill="#fff" />
          <path id="Path_2059" data-name="Path 2059" d="M437.97,697.134a1.772,1.772,0,0,1-.337-.893.493.493,0,1,1,.485-.047A1.789,1.789,0,0,1,437.97,697.134Z" transform="translate(-301.652 -605.318)" fill="#fff" />
          <path id="Path_2060" data-name="Path 2060" d="M438.29,694.778a1.508,1.508,0,0,1,.822-.29.453.453,0,1,1-.822.29Z" transform="translate(-301.924 -604.985)" fill="#fff" />
          <path id="Path_2061" data-name="Path 2061" d="M474.239,659.711a1.822,1.822,0,0,1,.55.78,1.7,1.7,0,0,1,.089.952,1.855,1.855,0,0,1-.638-1.732Z" transform="translate(-316.611 -590.766)" fill="#fff" />
          <path id="Path_2062" data-name="Path 2062" d="M473.7,662.962a1.554,1.554,0,1,1-.869.018A1.488,1.488,0,0,1,473.7,662.962Z" transform="translate(-315.753 -592.071)" fill="#fff" />
          <path id="Path_2063" data-name="Path 2063" d="M475.01,665.432a1.773,1.773,0,0,1-.337-.893.493.493,0,1,1,.337.893Z" transform="translate(-316.797 -592.356)" fill="#fff" />
          <path id="Path_2064" data-name="Path 2064" d="M475.34,663.079a1.509,1.509,0,0,1,.822-.29.454.454,0,1,1-.822.29Z" transform="translate(-317.073 -592.024)" fill="#fff" />
          <path id="Path_2065" data-name="Path 2065" d="M483.27,638.341a1.868,1.868,0,1,1,.739.6A1.752,1.752,0,0,1,483.27,638.341Z" transform="translate(-320.316 -582.023)" fill="#fff" />
          <path id="Path_2066" data-name="Path 2066" d="M485.455,640.279a1.543,1.543,0,1,1-.6.627A1.483,1.483,0,0,1,485.455,640.279Z" transform="translate(-320.893 -582.82)" fill="#fff" />
          <path id="Path_2067" data-name="Path 2067" d="M487.946,641.759a1.731,1.731,0,0,1-.869-.39,1.773,1.773,0,0,1-.568-.768,1.683,1.683,0,0,1,.869.39A1.772,1.772,0,0,1,487.946,641.759Z" transform="translate(-321.641 -582.952)" fill="#fff" />
          <path id="Path_2068" data-name="Path 2068" d="M486.57,639.237a1.505,1.505,0,0,1,.372-.786.454.454,0,1,1,.366.325A1.524,1.524,0,0,1,486.57,639.237Z" transform="translate(-321.665 -581.884)" fill="#fff" />
          <path id="Path_2069" data-name="Path 2069" d="M424.549,725.061a1.823,1.823,0,0,1,.55.78,1.7,1.7,0,0,1,.089.952,1.855,1.855,0,0,1-.638-1.732Z" transform="translate(-296.294 -617.486)" fill="#fff" />
          <path id="Path_2070" data-name="Path 2070" d="M424.01,728.311a1.554,1.554,0,1,1-.869.018A1.491,1.491,0,0,1,424.01,728.311Z" transform="translate(-295.435 -618.791)" fill="#fff" />
          <path id="Path_2071" data-name="Path 2071" d="M425.331,730.781a1.772,1.772,0,0,1-.337-.893.493.493,0,1,1,.337.893Z" transform="translate(-296.484 -619.076)" fill="#fff" />
          <path id="Path_2072" data-name="Path 2072" d="M425.65,728.429a1.509,1.509,0,0,1,.822-.29.453.453,0,1,1-.822.29Z" transform="translate(-296.756 -618.744)" fill="#fff" />
          <path id="Path_2073" data-name="Path 2073" d="M486.4,696.57a1.822,1.822,0,0,1,.55.78,1.7,1.7,0,0,1,.089.952,1.855,1.855,0,0,1-.638-1.732Z" transform="translate(-321.583 -605.837)" fill="#fff" />
          <path id="Path_2074" data-name="Path 2074" d="M485.86,699.819a1.44,1.44,0,0,1-.721.479,1.489,1.489,0,0,1-.869.018,1.554,1.554,0,0,1,1.59-.5Z" transform="translate(-320.724 -607.141)" fill="#fff" />
          <path id="Path_2075" data-name="Path 2075" d="M487.17,702.293a1.773,1.773,0,0,1-.337-.893.493.493,0,1,1,.337.893Z" transform="translate(-321.769 -607.427)" fill="#fff" />
          <path id="Path_2076" data-name="Path 2076" d="M487.5,699.939a1.507,1.507,0,0,1,.822-.29.453.453,0,1,1-.822.29Z" transform="translate(-322.045 -607.096)" fill="#fff" />
          <path id="Path_2077" data-name="Path 2077" d="M438.117,757.141a1.822,1.822,0,0,1,.55.78,1.7,1.7,0,0,1,.089.952,1.823,1.823,0,0,1-.55-.78A1.71,1.71,0,0,1,438.117,757.141Z" transform="translate(-301.839 -630.603)" fill="#fff" />
          <path id="Path_2078" data-name="Path 2078" d="M437.58,760.39a1.554,1.554,0,1,1-.869.018A1.441,1.441,0,0,1,437.58,760.39Z" transform="translate(-300.984 -631.907)" fill="#fff" />
          <path id="Path_2079" data-name="Path 2079" d="M438.891,762.852a1.773,1.773,0,0,1-.337-.893.493.493,0,1,1,.337.893Z" transform="translate(-302.028 -632.189)" fill="#fff" />
          <path id="Path_2080" data-name="Path 2080" d="M439.22,760.509a1.508,1.508,0,0,1,.822-.29.453.453,0,1,1,.024.491A1.551,1.551,0,0,1,439.22,760.509Z" transform="translate(-302.305 -631.861)" fill="#fff" />
          <path id="Path_2081" data-name="Path 2081" d="M472.979,737.439a1.823,1.823,0,0,1,.55.78,1.7,1.7,0,0,1,.089.952,1.854,1.854,0,0,1-.638-1.732Z" transform="translate(-316.096 -622.547)" fill="#fff" />
          <path id="Path_2082" data-name="Path 2082" d="M472.44,740.688a1.554,1.554,0,1,1-.869.018A1.441,1.441,0,0,1,472.44,740.688Z" transform="translate(-315.238 -623.851)" fill="#fff" />
          <path id="Path_2083" data-name="Path 2083" d="M473.76,743.163a1.773,1.773,0,0,1-.337-.893.493.493,0,1,1,.485-.047A1.85,1.85,0,0,1,473.76,743.163Z" transform="translate(-316.286 -624.138)" fill="#fff" />
          <path id="Path_2084" data-name="Path 2084" d="M474.08,740.81a1.508,1.508,0,0,1,.822-.29.453.453,0,1,1-.822.29Z" transform="translate(-316.558 -623.807)" fill="#fff" />
          <path id="Path_2085" data-name="Path 2085" d="M413.159,770.17a1.822,1.822,0,0,1,.55.78,1.7,1.7,0,0,1,.089.952,1.855,1.855,0,0,1-.638-1.732Z" transform="translate(-291.636 -635.93)" fill="#fff" />
          <path id="Path_2086" data-name="Path 2086" d="M412.62,773.419a1.553,1.553,0,1,1-.869.018A1.44,1.44,0,0,1,412.62,773.419Z" transform="translate(-290.778 -637.234)" fill="#fff" />
          <path id="Path_2087" data-name="Path 2087" d="M413.94,775.884a1.772,1.772,0,0,1-.337-.893.493.493,0,1,1,.485-.047A1.84,1.84,0,0,1,413.94,775.884Z" transform="translate(-291.826 -637.517)" fill="#fff" />
          <path id="Path_2088" data-name="Path 2088" d="M414.26,773.538a1.508,1.508,0,0,1,.822-.29.453.453,0,1,1,.024.491A1.561,1.561,0,0,1,414.26,773.538Z" transform="translate(-292.099 -637.189)" fill="#fff" />
          <path id="Path_2089" data-name="Path 2089" d="M398.329,800.25a1.821,1.821,0,0,1,.55.78,1.7,1.7,0,0,1,.089.952,1.855,1.855,0,0,1-.638-1.732Z" transform="translate(-285.573 -648.229)" fill="#fff" />
          <path id="Path_2090" data-name="Path 2090" d="M397.79,803.5a1.554,1.554,0,1,1-.869.018A1.44,1.44,0,0,1,397.79,803.5Z" transform="translate(-284.714 -649.533)" fill="#fff" />
          <path id="Path_2091" data-name="Path 2091" d="M399.1,805.974a1.773,1.773,0,0,1-.337-.893.493.493,0,1,1,.337.893Z" transform="translate(-285.759 -649.82)" fill="#fff" />
          <path id="Path_2092" data-name="Path 2092" d="M399.43,803.618a1.508,1.508,0,0,1,.822-.29.453.453,0,1,1-.822.29Z" transform="translate(-286.035 -649.488)" fill="#fff" />
          <path id="Path_2093" data-name="Path 2093" d="M391.7,735.84a1.861,1.861,0,1,1,.668.68A1.771,1.771,0,0,1,391.7,735.84Z" transform="translate(-282.875 -621.893)" fill="#fff" />
          <path id="Path_2094" data-name="Path 2094" d="M393.552,738.061a1.45,1.45,0,0,1-.26.828,1.477,1.477,0,0,1-.662.556,1.545,1.545,0,0,1,.922-1.383Z" transform="translate(-283.255 -622.801)" fill="#fff" />
          <path id="Path_2095" data-name="Path 2095" d="M395.97,739.745a1.805,1.805,0,0,1-.822-.479.493.493,0,1,1,.822.479Z" transform="translate(-284.089 -622.956)" fill="#fff" />
          <path id="Path_2096" data-name="Path 2096" d="M394.79,737.172a1.532,1.532,0,1,1,.78-.372A1.556,1.556,0,0,1,394.79,737.172Z" transform="translate(-284.138 -621.984)" fill="#fff" />
          <path id="Path_2097" data-name="Path 2097" d="M413.2,685.107a1.8,1.8,0,0,1-.922.242.494.494,0,1,1,0-.491A1.865,1.865,0,0,1,413.2,685.107Z" transform="translate(-290.913 -601.048)" fill="#fff" />
          <path id="Path_2098" data-name="Path 2098" d="M411.07,683.487a1.541,1.541,0,1,1,.29-.822A1.493,1.493,0,0,1,411.07,683.487Z" transform="translate(-290.713 -599.806)" fill="#fff" />
          <path id="Path_2099" data-name="Path 2099" d="M407.58,683.734a1.794,1.794,0,0,1,.952-.006,1.768,1.768,0,0,1,.833.467,1.8,1.8,0,0,1-.952.006A1.77,1.77,0,0,1,407.58,683.734Z" transform="translate(-289.367 -600.561)" fill="#fff" />
          <path id="Path_2100" data-name="Path 2100" d="M410.371,685.051a1.542,1.542,0,1,1-.485.721A1.492,1.492,0,0,1,410.371,685.051Z" transform="translate(-290.279 -601.127)" fill="#fff" />
          <path id="Path_2101" data-name="Path 2101" d="M394,661.65a1.822,1.822,0,0,1,.55.78,1.7,1.7,0,0,1,.089.952,1.822,1.822,0,0,1-.55-.78A1.7,1.7,0,0,1,394,661.65Z" transform="translate(-283.8 -591.558)" fill="#fff" />
          <path id="Path_2102" data-name="Path 2102" d="M393.46,664.909a1.44,1.44,0,0,1-.721.479,1.488,1.488,0,0,1-.869.018,1.554,1.554,0,0,1,1.59-.5Z" transform="translate(-282.944 -592.867)" fill="#fff" />
          <path id="Path_2103" data-name="Path 2103" d="M394.771,667.372a1.773,1.773,0,0,1-.337-.893.493.493,0,1,1,.337.893Z" transform="translate(-283.988 -593.149)" fill="#fff" />
          <path id="Path_2104" data-name="Path 2104" d="M395.1,665.028a1.507,1.507,0,0,1,.822-.29.453.453,0,1,1,.024.491A1.539,1.539,0,0,1,395.1,665.028Z" transform="translate(-284.264 -592.821)" fill="#fff" />
          <path id="Path_2105" data-name="Path 2105" d="M320.621,665.811s-34.7,80.333,23.32,96.323c24.815,6.839,52.7-3.629,68.582-1.2,28.5,4.357,40.3,64.946,28.6,118.117H295.386a36.925,36.925,0,0,1-36.59-31.76c-4.528-32.34-7.85-83.555,6.957-125.584C281.512,676.989,320.621,665.811,320.621,665.811Z" transform="translate(-226.963 -593.26)" fill="#858990" />
          <path id="Path_2107" data-name="Path 2107" d="M284.315,922.663h-.4c1.052-18.857-5.586-46.45-5.663-46.769l.4-.083C278.652,876.053,285.367,903.73,284.315,922.663Z" transform="translate(-236.487 -679.124)" fill="#3f4249" />
          <path id="Path_2117" data-name="Path 2117" d="M237.667,901.6s-4.776-18.124-23.627-13.921C214.04,887.69,217.279,903.141,237.667,901.6Z" transform="translate(-210.233 -683.72)" fill="#7bb200" />
          <path id="Path_2122" data-name="Path 2122" d="M274.937,906.737s-18.047.969-18.047-19.016C256.89,887.721,274.369,887.963,274.937,906.737Z" transform="translate(-227.754 -683.994)" fill="#7bb200" />
          <path id="Path_2124" data-name="Path 2124" d="M316.16,925.389s4.936-13.755,20.394-10.28C336.554,915.192,333.965,926.684,316.16,925.389Z" transform="translate(-251.987 -694.963)" fill="#7bb200" />
          <path id="Path_2125" data-name="Path 2125" d="M316.16,925.389s4.936-13.755,20.394-10.28C336.554,915.192,333.965,926.684,316.16,925.389Z" transform="translate(-251.987 -694.963)" fill="#fff" opacity="0.4" />
          <path id="Path_2127" data-name="Path 2127" d="M279.452,1008.173H255.659l-1.779-26.134h27.351Z" transform="translate(-226.523 -722.559)" fill="#84888f" />
          <path id="Path_2130" data-name="Path 2130" d="M279.433,832.736s-8.82-13.353,2.672-26.057A23.8,23.8,0,0,1,279.433,832.736Z" transform="translate(-235.649 -650.858)" fill="#7bb200" />
          <path id="Path_2132" data-name="Path 2132" d="M293.343,872.951s-9.712-20.636,7.608-33.422C300.95,839.612,312.927,858.3,293.343,872.951Z" transform="translate(-241.548 -664.29)" fill="#7bb200" />
          <path id="Path_2135" data-name="Path 2135" d="M270.6,949.382s-2.672-17.479,13.271-19.5C283.874,929.881,287.675,945.014,270.6,949.382Z" transform="translate(-233.292 -701.233)" fill="#7bb200" />
          <path id="Path_2139" data-name="Path 2139" d="M251.238,834.74S239.262,847.2,251,860.714C251,860.714,262.729,847.443,251.238,834.74Z" transform="translate(-223.236 -662.332)" fill="#7bb200" />
          <g id="Group_979" data-name="Group 979">
            <path id="Path_2106" data-name="Path 2106" d="M290.832,939.049l-.4-.083c.083-.325,4.368-34.634,14.163-53.165l.325.16C295.2,904.575,290.832,938.724,290.832,939.049Z" transform="translate(-241.467 -683.209)" fill="#3f4249" />
            <path id="Path_2108" data-name="Path 2108" d="M313.15,875.206s.083-18.532,16.268-19.667C329.495,855.545,330.795,871.889,313.15,875.206Z" transform="translate(-250.758 -670.836)" fill="#4b6d00" />
            <path id="Path_2109" data-name="Path 2109" d="M231.15,872.746s-4.368-17.639-23.55-12.626C207.606,860.043,211.247,872.989,231.15,872.746Z" transform="translate(-207.6 -672.342)" fill="#4b6d00" />
            <path id="Path_2110" data-name="Path 2110" d="M276.532,878.451s5.746-15.05-7.608-22.9C269.007,855.551,263.184,870.359,276.532,878.451Z" transform="translate(-232.186 -670.841)" fill="#4b6d00" />
            <path id="Path_2111" data-name="Path 2111" d="M265.221,937.425h-.4c0-34.793-17.4-55.1-17.639-55.353l.325-.242C247.659,882.072,265.221,902.466,265.221,937.425Z" transform="translate(-223.783 -681.586)" fill="#3f4249" />
            <path id="Path_2112" data-name="Path 2112" d="M286.862,935.581h-.4c2.022-35.686,3.641-41.916,6.8-54.218.325-1.295.65-2.589,1.052-4.043l.4.083c-.325,1.454-.727,2.749-1.052,4.043C290.5,893.741,288.884,899.895,286.862,935.581Z" transform="translate(-239.844 -679.742)" fill="#3f4249" />
            <path id="Path_2113" data-name="Path 2113" d="M251.02,919.924h-.4c2.187-26.784-4.043-45.723-9.629-56.889-6.555-13.028-14.648-20.311-18.449-22.09l.16-.325c3.884,1.779,12.059,9.145,18.614,22.256C246.971,874.041,253.284,893.057,251.02,919.924Z" transform="translate(-213.708 -664.735)" fill="#3f4249" />
            <path id="Path_2114" data-name="Path 2114" d="M254.177,942.817h-.4c.083-.325,4.853-34.551-16.185-45.883l.16-.325c6.148,3.316,13.921,10.764,16.268,27.511A72.039,72.039,0,0,1,254.177,942.817Z" transform="translate(-219.862 -687.628)" fill="#3f4249" />
            <path id="Path_2115" data-name="Path 2115" d="M291.932,959.956l-.4-.16a24.247,24.247,0,0,1,7.85-10.2l.242.325A24.147,24.147,0,0,0,291.932,959.956Z" transform="translate(-241.917 -709.295)" fill="#3f4249" />
            <path id="Path_2116" data-name="Path 2116" d="M295.322,940.337l-.242-.325c.485-.4,12.218-10.682,18.206-12.461l.083.4C307.464,929.655,295.405,940.177,295.322,940.337Z" transform="translate(-243.368 -700.28)" fill="#3f4249" />
            <path id="Path_2118" data-name="Path 2118" d="M237.667,901.6s-4.776-18.124-23.627-13.921C214.04,887.69,217.279,903.141,237.667,901.6Z" transform="translate(-210.233 -683.72)" fill="#fff" opacity="0.4" />
            <path id="Path_2119" data-name="Path 2119" d="M256.223,936.586s-3.239-12.869-20.553-8.66C235.67,927.926,241.333,940.795,256.223,936.586Z" transform="translate(-219.077 -700.087)" fill="#4b6d00" />
            <path id="Path_2120" data-name="Path 2120" d="M290.29,913.189s1.62-20.228,22.74-22.74C312.948,890.532,314,910.275,290.29,913.189Z" transform="translate(-241.41 -685.11)" fill="#4b6d00" />
            <path id="Path_2121" data-name="Path 2121" d="M280.572,914.028h-.4c.16-14.406.969-88.692.969-89.419h.4C281.459,825.336,280.649,899.622,280.572,914.028Z" transform="translate(-237.272 -658.189)" fill="#3f4249" />
            <path id="Path_2123" data-name="Path 2123" d="M274.937,906.737s-18.047.969-18.047-19.016C256.89,887.721,274.369,887.963,274.937,906.737Z" transform="translate(-227.754 -683.994)" fill="#fff" opacity="0.4" />
            <path id="Path_2126" data-name="Path 2126" d="M281.925,975.189H250.04v4.043h31.885Z" transform="translate(-224.952 -719.758)" fill="#3f4249" />
            <path id="Path_2128" data-name="Path 2128" d="M279.452,1008.173H255.659l-1.779-26.134h27.351Z" transform="translate(-226.522 -722.559)" fill="#3f4249" />
            <path id="Path_2129" data-name="Path 2129" d="M283.5,975.189h-5.1v4.049H253.88l.077,1.129h19.667l.083,25.005h5.746l1.779-26.134H283.5Z" transform="translate(-226.522 -719.758)" opacity="0.2" />
            <path id="Path_2131" data-name="Path 2131" d="M279.433,832.736s-8.82-13.353,2.672-26.057A23.8,23.8,0,0,1,279.433,832.736Z" transform="translate(-235.649 -650.858)" fill="#fff" opacity="0.4" />
            <path id="Path_2133" data-name="Path 2133" d="M293.343,872.951s-9.712-20.636,7.608-33.422C300.95,839.612,312.844,858.3,293.343,872.951Z" transform="translate(-241.549 -664.29)" fill="#fff" opacity="0.4" />
            <path id="Path_2134" data-name="Path 2134" d="M299.19,950.787s2.347-8.985,14.648-7.123C313.838,943.664,311.81,952.809,299.19,950.787Z" transform="translate(-245.049 -706.764)" fill="#4b6d00" />
            <path id="Path_2136" data-name="Path 2136" d="M270.6,949.382s-2.672-17.479,13.271-19.5C283.874,929.881,287.675,945.014,270.6,949.382Z" transform="translate(-233.292 -701.233)" fill="#fff" opacity="0.4" />
            <path id="Path_2137" data-name="Path 2137" d="M215.41,836.36s9.629-.969,11.491,8.252A10.821,10.821,0,0,1,215.41,836.36Z" transform="translate(-210.793 -662.982)" fill="#7bb200" />
            <path id="Path_2140" data-name="Path 2140" d="M251.238,834.74S239.262,847.2,251,860.714C251,860.714,262.729,847.443,251.238,834.74Z" transform="translate(-223.236 -662.332)" fill="#fff" opacity="0.4" />
          </g>
          <g id="Group_980" data-name="Group 980" transform="translate(651.923 -18.246)">
            <path id="Path_2106-2" data-name="Path 2106" d="M290.991,960.073l-.561-.115c.115-.454,6.093-48.308,19.755-74.157l.453.223C297.084,911.987,290.991,959.62,290.991,960.073Z" transform="translate(-222.135 -664.743)" fill="#3f4249" />
            <path id="Path_2108-2" data-name="Path 2108" d="M313.15,882.971s.115-25.849,22.691-27.432C335.948,855.547,337.762,878.345,313.15,882.971Z" transform="translate(-226.124 -659.432)" fill="#4b6d00" />
            <path id="Path_2109-2" data-name="Path 2109" d="M240.449,878.085s-6.093-24.6-32.849-17.612C207.608,860.367,212.687,878.424,240.449,878.085Z" transform="translate(-207.6 -660.079)" fill="#4b6d00" />
            <path id="Path_2110-2" data-name="Path 2110" d="M280.007,887.493s8.014-20.992-10.612-31.942C269.511,855.551,261.39,876.205,280.007,887.493Z" transform="translate(-218.152 -659.434)" fill="#4b6d00" />
            <path id="Path_2111-2" data-name="Path 2111" d="M272.344,959.375h-.561c0-48.531-24.265-76.861-24.6-77.207l.454-.338C247.848,882.168,272.344,910.614,272.344,959.375Z" transform="translate(-214.546 -664.046)" fill="#3f4249" />
            <path id="Path_2112-2" data-name="Path 2112" d="M287.021,958.584h-.561c2.82-49.776,5.079-58.466,9.482-75.624.453-1.806.907-3.611,1.468-5.64l.561.115c-.453,2.028-1.014,3.834-1.468,5.64C292.1,900.225,289.84,908.808,287.021,958.584Z" transform="translate(-221.438 -663.254)" fill="#3f4249" />
            <path id="Path_2113-2" data-name="Path 2113" d="M262.265,951.236H261.7c3.051-37.359-5.64-63.776-13.431-79.351-9.144-18.172-20.431-28.33-25.733-30.812l.223-.453c5.417,2.482,16.82,12.755,25.964,31.043C256.617,887.237,265.423,913.762,262.265,951.236Z" transform="translate(-210.221 -656.814)" fill="#3f4249" />
            <path id="Path_2114-2" data-name="Path 2114" d="M260.726,961.061h-.561c.115-.453,6.769-48.193-22.575-64l.223-.453c8.575,4.625,19.417,15.014,22.691,38.373A100.483,100.483,0,0,1,260.726,961.061Z" transform="translate(-212.863 -666.639)" fill="#3f4249" />
            <path id="Path_2115-2" data-name="Path 2115" d="M292.091,964.046l-.561-.223c0-.115,2.713-7.792,10.95-14.223l.338.454A33.681,33.681,0,0,0,292.091,964.046Z" transform="translate(-222.328 -675.939)" fill="#3f4249" />
            <path id="Path_2116-2" data-name="Path 2116" d="M295.418,945.385l-.338-.453c.676-.561,17.043-14.9,25.395-17.381l.115.561C312.354,930.486,295.533,945.163,295.418,945.385Z" transform="translate(-222.951 -672.069)" fill="#3f4249" />
            <path id="Path_2118-2" data-name="Path 2118" d="M247,907.351s-6.662-25.28-32.956-19.417C214.04,887.942,218.558,909.494,247,907.351Z" transform="translate(-208.731 -664.962)" fill="#c6de39" />
            <path id="Path_2119-2" data-name="Path 2119" d="M264.338,940.339s-4.518-17.95-28.668-12.079C235.67,928.26,243.569,946.21,264.338,940.339Z" transform="translate(-212.526 -671.987)" fill="#4b6d00" />
            <path id="Path_2120-2" data-name="Path 2120" d="M290.29,922.168s2.259-28.215,31.719-31.719C321.894,890.564,323.361,918.1,290.29,922.168Z" transform="translate(-222.111 -665.558)" fill="#4b6d00" />
            <path id="Path_2121-2" data-name="Path 2121" d="M280.731,949.333h-.561c.223-20.094,1.352-123.71,1.352-124.724h.561C281.967,825.623,280.838,929.24,280.731,949.333Z" transform="translate(-220.335 -654.004)" fill="#3f4249" />
            <path id="Path_2123-2" data-name="Path 2123" d="M282.062,914.246S256.89,915.6,256.89,887.721C256.89,887.721,281.271,888.059,282.062,914.246Z" transform="translate(-216.25 -665.08)" fill="#c6de39" />
            <path id="Path_2126-2" data-name="Path 2126" d="M294.514,975.189H250.04v5.64h44.474Z" transform="translate(-215.047 -680.429)" fill="#3f4249" />
            <path id="Path_2128-2" data-name="Path 2128" d="M289.548,1018.492H256.362l-2.482-36.453h38.15Z" transform="translate(-215.721 -681.631)" fill="#3f4249" />
            <path id="Path_2129-2" data-name="Path 2129" d="M295.188,975.189h-7.107v5.648h-34.2l.107,1.575h27.432l.115,34.878h8.014l2.482-36.452h3.158Z" transform="translate(-215.721 -680.429)" opacity="0.2" />
            <path id="Path_2131-2" data-name="Path 2131" d="M280.71,843.024s-12.3-18.626,3.727-36.344A33.2,33.2,0,0,1,280.71,843.024Z" transform="translate(-219.639 -650.858)" fill="#c6de39" />
            <path id="Path_2133-2" data-name="Path 2133" d="M294.414,886.147s-13.547-28.784,10.611-46.618C305.026,839.644,321.615,865.715,294.414,886.147Z" transform="translate(-222.171 -656.623)" fill="#c6de39" />
            <path id="Path_2134-2" data-name="Path 2134" d="M299.19,953.7s3.273-12.532,20.431-9.935C319.621,943.765,316.793,956.52,299.19,953.7Z" transform="translate(-223.672 -674.852)" fill="#4b6d00" />
            <path id="Path_2136-2" data-name="Path 2136" d="M270.67,957.082s-3.727-24.381,18.51-27.2C289.18,929.881,294.482,950.989,270.67,957.082Z" transform="translate(-218.627 -672.478)" fill="#c6de39" />
            <path id="Path_2137-2" data-name="Path 2137" d="M215.41,836.371s13.431-1.352,16.029,11.51C231.323,847.882,219.244,849.349,215.41,836.371Z" transform="translate(-208.97 -656.061)" fill="#7bb200" />
            <path id="Path_2140-2" data-name="Path 2140" d="M253.368,834.74s-16.7,17.381-.338,36.229C253.03,870.969,269.4,852.459,253.368,834.74Z" transform="translate(-214.311 -655.782)" fill="#c6de39" />
          </g>
        </g>
      </g>
      <g id="Group_982" data-name="Group 982" transform="translate(-25.223 -187.764)">
        <g id="Group_977" data-name="Group 977" transform="translate(1126.223 373.744)">
          <g id="Group_973" data-name="Group 973" transform="translate(282.75 219.048)">
            <path id="Path_1902" data-name="Path 1902" d="M928.041,646.612a3.981,3.981,0,1,0-3.981-3.981A3.979,3.979,0,0,0,928.041,646.612Z" transform="translate(-924.06 -516.231)" />
            <path id="Path_1903" data-name="Path 1903" d="M928.229,644.208a1.389,1.389,0,1,0-1.389-1.389A1.393,1.393,0,0,0,928.229,644.208Z" transform="translate(-924.249 -516.419)" fill="#fff" />
            <path id="Path_1904" data-name="Path 1904" d="M928.33,656.7V532.869" transform="translate(-924.35 -532.869)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="3" />
          </g>
          <g id="Group_976" data-name="Group 976" transform="translate(22.393 0)">
            <path id="Path_1895" data-name="Path 1895" d="M957.077,515.719H583.069a1.249,1.249,0,0,1-1.249-1.249V280.9a1.249,1.249,0,0,1,1.249-1.249H957.077a1.249,1.249,0,0,1,1.249,1.249V514.461A1.24,1.24,0,0,1,957.077,515.719Z" transform="translate(-581.82 -279.65)" fill="#3f4249" />
            <path id="Path_1896" data-name="Path 1896" d="M948.844,287.74H589.83V506.548H948.844V287.74Z" transform="translate(-580.685 -280.198)" fill="#7bb200" />
          </g>
          <g id="Group_975" data-name="Group 975" transform="translate(105 219.374)">
            <path id="Path_1899" data-name="Path 1899" d="M561.781,679.523a3.981,3.981,0,1,0-3.981-3.981A3.979,3.979,0,0,0,561.781,679.523Z" transform="translate(-557.8 -518.586)" />
            <path id="Path_1901" data-name="Path 1901" d="M562.07,661.612V503.76h18.413" transform="translate(-558.09 -503.76)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="3" />
            <path id="Path_1900" data-name="Path 1900" d="M561.969,677.118a1.389,1.389,0,1,0-1.389-1.389A1.393,1.393,0,0,0,561.969,677.118Z" transform="translate(-557.989 -517.961)" fill="#fff" />
          </g>
        </g>
        <g id="Group_90" data-name="Group 90" transform="translate(1154.639 380.061)">
          <path id="Path_661" data-name="Path 661" d="M613.427,330.659H266.775a8.684,8.684,0,0,1-8.925-8.4V121.119a6.362,6.362,0,0,1,6.539-6.154H615.7a6.472,6.472,0,0,1,6.652,6.26V322.26A8.684,8.684,0,0,1,613.427,330.659Z" transform="translate(-257.599 -107.62)" fill="#f2f2f2" />
          <path id="Path_663" data-name="Path 663" d="M622.288,114.285H257.665v-4.407c0-2.028-.633-5.78.89-7.628,1.321-1.644,5.217-1.051,8.07-1.053h346.7c2.112,0,4.521-.412,6.435,1.087,1.672,1.277,2.522,4.848,2.526,7.595Z" transform="translate(-257.536 -101.094)" fill="#ccc" />
          <circle id="Ellipse_63" data-name="Ellipse 63" cx="2.247" cy="2.247" r="2.247" transform="translate(13.67 4.413)" fill="#fff" />
          <circle id="Ellipse_64" data-name="Ellipse 64" cx="2.247" cy="2.247" r="2.247" transform="translate(22.199 4.413)" fill="#fff" />
          <circle id="Ellipse_65" data-name="Ellipse 65" cx="2.247" cy="2.247" r="2.247" transform="translate(30.727 4.413)" fill="#fff" />
          <path id="Path_665" data-name="Path 665" d="M371.884,336.227H604.813a8.525,8.525,0,0,0,8.65-8.393V229.974a8.525,8.525,0,0,0-8.65-8.393H371.884a8.525,8.525,0,0,0-8.65,8.393v97.859A8.525,8.525,0,0,0,371.884,336.227Z" transform="translate(-307.701 -163.221)" fill="#f2f2f2" />
          <circle id="Ellipse_66" data-name="Ellipse 66" cx="4.721" cy="4.721" r="4.721" transform="translate(86.698 78.964)" fill="#ccc" />
          <circle id="Ellipse_67" data-name="Ellipse 67" cx="4.721" cy="4.721" r="4.721" transform="translate(86.698 96.076)" fill="#ccc" />
          <circle id="Ellipse_68" data-name="Ellipse 68" cx="4.721" cy="4.721" r="4.721" transform="translate(86.698 113.187)" fill="#ccc" />
          <circle id="Ellipse_69" data-name="Ellipse 69" cx="4.721" cy="4.721" r="4.721" transform="translate(86.698 130.299)" fill="#ccc" />
          <path id="Path_667" data-name="Path 667" d="M627.954,300.9H472.679a.525.525,0,1,1,0-1.049H627.954a.525.525,0,1,1,0,1.049Z" transform="translate(-355.243 -199.597)" fill="#ccc" />
          <path id="Path_668" data-name="Path 668" d="M627.954,334.9H472.679a.525.525,0,1,1,0-1.049H627.954a.525.525,0,1,1,0,1.049Z" transform="translate(-355.243 -216.459)" fill="#ccc" />
          <path id="Path_669" data-name="Path 669" d="M627.954,368.9H472.679a.525.525,0,1,1,0-1.049H627.954a.525.525,0,1,1,0,1.049Z" transform="translate(-355.243 -233.321)" fill="#ccc" />
          <path id="Path_670" data-name="Path 670" d="M512.99,386.265H497.777a2.626,2.626,0,0,1-2.623-2.623V369.478a2.626,2.626,0,0,1,2.623-2.623H512.99a2.626,2.626,0,0,1,2.623,2.623v14.164a2.626,2.626,0,0,1-2.623,2.623Z" transform="translate(-367.638 -233.301)" fill="#3e5a01" />
          <path id="Path_671" data-name="Path 671" d="M577.99,370.1H562.777a2.626,2.626,0,0,1-2.623-2.623v-32a2.626,2.626,0,0,1,2.623-2.623H577.99a2.626,2.626,0,0,1,2.623,2.623v32A2.626,2.626,0,0,1,577.99,370.1Z" transform="translate(-397.779 -216.858)" fill="#3e5a01" />
          <path id="Path_672" data-name="Path 672" d="M643.99,353.936H628.777a2.626,2.626,0,0,1-2.623-2.623V301.478a2.626,2.626,0,0,1,2.623-2.623H643.99a2.626,2.626,0,0,1,2.623,2.623v49.835A2.626,2.626,0,0,1,643.99,353.936Z" transform="translate(-428.385 -200.358)" fill="#3e5a01" />
          <path id="Path_673" data-name="Path 673" d="M711.99,338.247H696.777a2.626,2.626,0,0,1-2.623-2.623V268.478a2.626,2.626,0,0,1,2.623-2.623H711.99a2.626,2.626,0,0,1,2.623,2.623v67.146A2.626,2.626,0,0,1,711.99,338.247Z" transform="translate(-459.917 -184.27)" fill="#3e5a01" />
          <path id="Path_674" data-name="Path 674" d="M627.954,402.9H472.679a.525.525,0,1,1,0-1.049H627.954a.525.525,0,1,1,0,1.049Z" transform="translate(-355.243 -250.183)" fill="#3f3d56" />
        </g>
      </g>
    </g>
  </g>
</svg>;