import React, { useEffect } from "react";
import { projectName } from '../../utils';


// Images
import Bitcoin from "../../assets/svg/bitcoin.svg";
import Coding from "../../assets/svg/coding.svg";
import Cloud from "../../assets/svg/cloud.svg";
import Customer from "../../assets/svg/customer.svg";
import Data from "../../assets/svg/data.svg";
import Fingerprint from "../../assets/svg/fingerprint.svg"
import Mobile from "../../assets/svg/mobile.svg";
import Projects from "../../assets/svg/projects.svg";
import Checklist from '../../assets/svg/checklist.svg';

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <title>{projectName}</title>
      <div className="services">
        <div className="container">
          <div className="services__main">
            <div className="has-shadow-text u-margin-bottom-large">
              <h4 className="header-tertiary u-text-center">Our Services</h4>
              <h1 className="shadow-text align-center">Services</h1>
            </div>
            {/* <p className="services__text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere accusamus, tempora perferendis quasi dignissimos minima praesentium est aliquid obcaecati itaque eos aut architecto a sapiente modi, ipsum quae numquam suscipit.</p> */}
          </div>
          <div className="services__list">

            <div className="service">

              <div className="service__icon" style={{ backgroundImage: `url(${Coding})` }}>
                <h1 data-content="01"><span>01</span></h1>
              </div>
              <div className="service__content">
                <h1 className="title">Web Development</h1>
                <p className="service__text">Our company offers end-to-end web development services to equip your company, customers, and clients with the digital tools that make them efficient, innovative, and successful and keep your business at the top of the competition. Whether you are looking for professional website development from scratch or need to redesign, upgrade or scale up your existing web assets, our team will help you implement your ideas. And since we adhere to proactive management, you should expect to get even a better system than you’ve imagined.</p>
              </div>

            </div>

            <div className="service">

              <div className="service__icon" style={{ backgroundImage: `url(${Cloud})` }}>
                <h1 data-content="02"><span>02</span></h1>
              </div>
              <div className="service__content">
                <h1 className="title">Cloud Services</h1>
                <p className="service__text">Our services are designed to offer easy, scalable, and affordable access to applications and resources, without the need for internal infrastructure or hardware.</p>
              </div>

            </div>

            <div className="service">

              <div className="service__icon" style={{ backgroundImage: `url(${Mobile})` }}>
                <h1 data-content="03"><span>03</span></h1>
              </div>
              <div className="service__content">
                <h1 className="title">Mobile App Development</h1>
                <p className="service__text">We build mobile applications on different platforms using top-notch technologies and proven approaches. Our smartphone app development services include Leveraging next-gen technologies, intelligent algorithms, and modern UI Design and development of native Android and iOS mobile apps.</p>
              </div>

            </div>

            <div className="service">

              <div className="service__icon" style={{ backgroundImage: `url(${Fingerprint})` }}>
                <h1 data-content="04"><span>04</span></h1>
              </div>
              <div className="service__content">
                <h1 className="title">Identity Management Solutions</h1>
                <p className="service__text">We design and deploy innovative, scalable, and practical biometric identity management solutions to increase security and convenience.</p>
              </div>

            </div>

            <div className="service">

              <div className="service__icon" style={{ backgroundImage: `url(${Projects})` }}>
                <h1 data-content="05"><span>05</span></h1>
              </div>
              <div className="service__content">
                <h1 className="title">Project Management</h1>
                <p className="service__text">Many IT projects fail. Yours should not! We can help streamline your IT project management activities from pre-project planning to post-project review to get work done efficiently.</p>
              </div>

            </div>

            <div className="service">

              <div className="service__icon" style={{ backgroundImage: `url(${Data})` }}>
                <h1 data-content="06"><span>06</span></h1>
              </div>
              <div className="service__content">
                <h1 className="title">Data Recovery Services</h1>
                <p className="service__text">System crashes can affect any business. Our backup solution uses an easy-to-deploy dedicated local system that incorporates local backup, cloud backup, and object-level exchange restore options.</p>
              </div>

            </div>

            <div className="service">

              <div className="service__icon" style={{ backgroundImage: `url(${Bitcoin})` }}>
                <h1 data-content="07"><span>07</span></h1>
              </div>
              <div className="service__content">
                <h1 className="title">Blockchain</h1>
                <p className="service__text">We develop and provide blockchain technology services that help you materialize your ideas into sustainable business models.</p>
              </div>

            </div>



            <div className="service">

              <div className="service__icon" style={{ backgroundImage: `url(${Customer})` }}>
                <h1 data-content="08"><span>08</span></h1>
              </div>
              <div className="service__content">
                <h1 className="title">Customer Support Services</h1>
                <p className="service__text">At Microbridge technologies limited, we offer timely, empathetic support services that keep your needs at the forefront of every interaction helping you get the most out of your products.</p>
              </div>

            </div>

            <div className="service">

              <div className="service__icon" style={{ backgroundImage: `url(${Checklist})` }}>
                <h1 data-content="09"><span>09</span></h1>
              </div>
              <div className="service__content">
                <h1 className="title">IT Compliance Auditing</h1>
                <p className="service__text">We perform comprehensive reviews of an organization’s adherence to regulatory guidelines.  We evaluate the strength and thoroughness of compliance preparations, security policies, user access controls, and risk management procedures for organizations.</p>
              </div>

            </div>

          </div>
        </div>

      </div>
    </>
  );
}

export default Services;