import React, { useEffect } from "react";
import { projectName } from '../../utils';
import Buiding from "../../assets/images/building.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faCertificate,
  faHeart,
  faHandsHelping,
  faDumbbell
} from "@fortawesome/free-solid-svg-icons";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <title>{projectName}</title>
      <div className="about">
        <div className="container">
          <section className="about__main">
            <div className="has-shadow-text u-margin-bottom-large">
              <h4 className="header-tertiary u-text-center">About Us</h4>
              <h1 className="shadow-text align-center">About Us</h1>
            </div>
            <div className="about__banner">
              <h1 className="about__banner__title">Design.<br />Innovate.<br />Deliver.</h1>
              <div className="about__banner__photo">
                <img src={Buiding} alt="Bulding" />
              </div>
            </div>
            <p className="about__text">At Microbridge Technology Limited, we work together to design, create and deliver products and IT services for people and organisations. As a technology company, we innovate for people with one belief:  there's always a way to make life better.</p>
          </section>
          <section className="about__statement">
            <h1 className="title">Our Mission</h1>
            <p>To solve challenging performance problems of businesses and organisations using information and communications technology.</p>
          </section>
          <section className="about__statement vision">
            <h1 className="title">Our Vision</h1>
            <p>To bring our clients into the 21st century through innovation, modern technology, and excellent support services.</p>
          </section>
          <section className="about__values">
            <h1 className="title">Our Values</h1>
            <div className="about__values__list">
              <div className="value">
                <div className="icon">
                  <FontAwesomeIcon icon={faThumbsUp} />
                </div>
                <h3 className="title">Intergrity</h3>
                <p>We uphold principles, ethics, transparency, and fairness.</p>
              </div>
              <div className="value">
                <div className="icon">
                  <FontAwesomeIcon icon={faCertificate} />
                </div>
                <h3 className="title">Trust</h3>
                <p>We are authentic in our interactions and communicate openly, honestly, and respectfully.</p>
              </div>
              <div className="value">
                <div className="icon">
                  <FontAwesomeIcon icon={faHandsHelping} />
                </div>
                <h3 className="title">Teamwork</h3>
                <p>We work collaboratively towards a common goal and shared purpose.</p>
              </div>
              <div className="value">
                <div className="icon">
                  <FontAwesomeIcon icon={faHeart} />
                </div>
                <h3 className="title">Empathy</h3>
                <p>We care for each other, our clients, and communities - understanding that empathy makes us stronger.</p>
              </div>
              <div className="value">
                <div className="icon">
                  <FontAwesomeIcon icon={faDumbbell} />
                </div>
                <h3 className="title">Employee Development</h3>
                <p>We genuinely care for our employees through a commitment to support their professional and personal growth.</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default About;