import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../assets/images/mb-logo.png";
import Facebook from "../../assets/svg/facebook.svg";
import LinkedIn from "../../assets/svg/linkedin.svg";
import Twitter from "../../assets/svg/twitter.svg";
import WhatsApp from "../../assets/svg/whatsapp.svg";

const footer = () => {
  const scrollUp = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  const year = new Date().getFullYear();
  return (
    <footer className="footer">

      <div className="container">

        <div className="footer__logo">
          <img src={Logo} alt="Microbridge Logo" />
        </div>

        <ul className="footer__links">
          <li>
            <NavLink to="/services">Services</NavLink>
            <span className="dot">&bull;</span>
          </li>
          <li>
            <NavLink to="/about">About us</NavLink>
            <span className="dot">&bull;</span>
          </li>
          <li>
            <NavLink to="/careers">Careers</NavLink>
            <span className="dot">&bull;</span>
          </li>
          <li>
            <NavLink to="/contact">Contact us</NavLink>
          </li>
        </ul>

        <div className="footer__end">
          <p className="footer__end__copyright">&copy; {year} Microbridge. All rights reserved.</p>
          <div className="footer__end__socials">
            <a href="https://www.facebook.com/MicrobridgeL" target="_blank" rel="noreferrer" className="social-icon">
              <img src={Facebook} alt="Facebook" />
            </a>
            <a href="https://www.linkedin.com/company/microbridge-technology-solution-ltd" target="_blank" rel="noreferrer" className="social-icon">
              <img src={LinkedIn} alt="LinkedIn" />
            </a>
            <a href="https://twitter.com/MicrobridgeL" target="_blank" rel="noreferrer" className="social-icon">
              <img src={Twitter} alt="Twitter" />
            </a>
            <a href="https://api.whatsapp.com/send?phone=2348121447470&fbclid=IwAR2nud69BsEHqIBoHApQniLROQOT5QBrEbhkC7WEauuvKXeEU87j482rB54" target="_blank" rel="noreferrer" className="social-icon">
              <img src={WhatsApp} alt="WhatsApp" />
            </a>
          </div>
          <div className="footer__end__up">
            <div className="up-button" onClick={scrollUp}>
              <FontAwesomeIcon icon={faAngleUp} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default footer;