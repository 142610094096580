import React from 'react';
import Overlay from '../Overlay/Overlay';

const modal = props => {
  return (
    <>
      <Overlay show={props.show} closeModal={props.closeModal} />
      <div
        style={{
          transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
          opacity: props.show ? 1 : 0
        }}
        className="modal">
        {props.children}
      </div>
    </>
  )
}

export default modal;