import React from "react";

const button = props => {
  let classes = props.classes;
  return (
    <button
      type={props.type}
      onClick={props.onClick}
      className={classes}
      disabled={props.disabled}>
      {props.children}
    </button>
  );
}

export default button;