import { createStore, combineReducers, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import messageReducer from "./reducers/MessageReducer";

const rootReducers = combineReducers({
  messageReducer
});

const store = createStore(
  rootReducers,
  applyMiddleware(ReduxThunk)
);

export default store;