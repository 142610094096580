import React from "react";
import NavigationItem from "./NavigationItem/NavigationItem";

let headerNavList = null;

const navigationItems = props => {
  headerNavList =
    <ul className="header__nav__list">
      <NavigationItem link="/" classes={["header__nav__link"]}>Home</NavigationItem>
      <NavigationItem link="/services" classes={["header__nav__link"]}>Services</NavigationItem>
      <NavigationItem link="about" classes={["header__nav__link"]}>About Us</NavigationItem>
      <NavigationItem link="careers" classes={["header__nav__link"]}>Careers</NavigationItem>
      <NavigationItem link="contact" classes={["header__nav__link"]}>Contact Us</NavigationItem>
    </ul>

  if (props.type === "mobile") {
    let mobileListClasses = ["header__mobile-nav__list"];
    if (props.showMenu) {
      mobileListClasses.push("show");
    }
    headerNavList = <ul className={mobileListClasses.join(" ")}>
      <NavigationItem link="/" type="mobile" classes={["header__mobile-nav__link"]}>Home</NavigationItem>
      <NavigationItem link="services" type="mobile" classes={["header__mobile-nav__link"]}>Services</NavigationItem>
      <NavigationItem link="about" type="mobile" classes={["header__mobile-nav__link"]}>About Us</NavigationItem>
      <NavigationItem link="careers" type="mobile" classes={["header__mobile-nav__link"]}>Careers</NavigationItem>
      <NavigationItem link="contact" type="mobile" classes={["header__mobile-nav__link"]}>Contact Us</NavigationItem>
    </ul>
  };

  return headerNavList;
}

export default navigationItems;