import { MESSAGE_CONSTANTS } from "../../constants/message";

export const sendMessage = (name, email, phone, message) => {

  return async dispatch => {

    try {

      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      const data = JSON.stringify({
        "name": name,
        "email": email,
        "phone": phone,
        "message": message
      });

      let requestOptions = {
        method: 'POST',
        headers: headers,
        body: data
      };

      const response = await fetch("https://react-my-burger-93ff3.firebaseio.com/message.json", requestOptions);

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.error.message;
        throw new Error(errorMessage);
      }

      const resData = await response.json();

      dispatch({
        type: MESSAGE_CONSTANTS.SEND_MESSAGE,
        response: resData
      });

      return resData;

    } catch (error) {
      throw error;
    }
  }
}