import { Switch } from 'react-router-dom';
import './sass/main.scss';
import Layout from './components/Layout/Layout';
import Routes from './components/Routes/Routes';

function App() {
  return (
    <Switch>
      <Layout>
        <Routes />
      </Layout>
    </Switch>
  );
}

export default App;
