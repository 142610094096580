export const svgRep = <svg xmlns="http://www.w3.org/2000/svg" width="783.643" height="497.636" viewBox="0 0 783.643 497.636">
  <g id="buttom_illustration" data-name="buttom illustration" transform="translate(-943.357 -3051.453)">
    <path id="Path_2929" data-name="Path 2929" d="M997.587,1027.36H214.815a.395.395,0,0,1,0-.79H997.668a.376.376,0,0,1,.395.395A.443.443,0,0,1,997.587,1027.36Z" transform="translate(728.938 2521.729)" fill="#b0b4b9" />
    <g id="Group_1306" data-name="Group 1306" transform="translate(0 -71)">
      <path id="Path_2748" data-name="Path 2748" d="M655.088,685.93l5.728,5.4a1.337,1.337,0,0,1-.542,2.255l-1.994.575a2.938,2.938,0,0,1-2.275-.268l-9.475-5.386,9.435-2.529" transform="translate(585.959 2705.441)" fill="#fc9d9d" />
      <path id="Path_2749" data-name="Path 2749" d="M655.088,685.93l5.728,5.4a1.337,1.337,0,0,1-.542,2.255l-1.994.575a2.938,2.938,0,0,1-2.275-.268l-9.475-5.386" transform="translate(585.959 2705.441)" opacity="0.15" />
      <path id="Path_2750" data-name="Path 2750" d="M632.832,683.06l9.843-1.646a5.5,5.5,0,0,1,3.854.79l7.862,5.045a1.131,1.131,0,0,1-1.064,1.987l-7.474-3.245-7.079,1.606a.752.752,0,0,0-.013,1.465l4.028.977a2.58,2.58,0,0,1,1.927,2.937.646.646,0,0,1-.636.535l-18.708-.02-5.032-11.9Z" transform="translate(594.625 2706.96)" fill="#fc9d9d" />
      <path id="Path_2751" data-name="Path 2751" d="M618.986,734.381h56.179a.881.881,0,0,1,.857.856v1.425a.881.881,0,0,1-.857.856H618.986a.881.881,0,0,1-.856-.856v-1.425A.837.837,0,0,1,618.986,734.381Z" transform="translate(595.356 2689.41)" fill="#21252b" />
      <path id="Path_2752" data-name="Path 2752" d="M618.986,734.381h56.179a.881.881,0,0,1,.857.856v1.425a.881.881,0,0,1-.857.856H618.986a.881.881,0,0,1-.856-.856v-1.425A.837.837,0,0,1,618.986,734.381Z" transform="translate(595.356 2689.41)" opacity="0.6" />
      <path id="Path_2753" data-name="Path 2753" d="M677.906,734.381h97.945a.881.881,0,0,1,.856.856v1.425a.881.881,0,0,1-.856.856H677.906a.881.881,0,0,1-.857-.856v-1.425A.921.921,0,0,1,677.906,734.381Z" transform="translate(575.861 2689.41)" fill="#21252b" />
      <g id="Group_1105" data-name="Group 1105" transform="translate(1251.8 3423.797)" opacity="0.6">
        <path id="Path_2754" data-name="Path 2754" d="M775.376,734.953H675.671a.281.281,0,0,1,0-.562h99.705a.263.263,0,0,1,.281.281A.3.3,0,0,1,775.376,734.953Z" transform="translate(-675.39 -734.391)" opacity="0.6" />
      </g>
      <path id="Path_2755" data-name="Path 2755" d="M799.51,699.9h14.754a2.371,2.371,0,0,0,2.335-1.934l12.365-65.011a2.351,2.351,0,0,0-2.335-2.79H811.876a2.371,2.371,0,0,0-2.335,1.934l-12.365,65.011A2.378,2.378,0,0,0,799.51,699.9Z" transform="translate(536.123 2723.895)" fill="#21252b" />
      <path id="Path_2756" data-name="Path 2756" d="M799.51,699.9h14.754a2.371,2.371,0,0,0,2.335-1.934l12.365-65.011a2.351,2.351,0,0,0-2.335-2.79H811.876a2.371,2.371,0,0,0-2.335,1.934l-12.365,65.011A2.378,2.378,0,0,0,799.51,699.9Z" transform="translate(536.123 2723.895)" opacity="0.6" />
      <path id="Path_2757" data-name="Path 2757" d="M677.692,699.9h94.747a2.371,2.371,0,0,0,2.335-1.934l12.365-65.011a2.351,2.351,0,0,0-2.335-2.79H690.117a2.371,2.371,0,0,0-2.335,1.934l-12.365,65.011A2.307,2.307,0,0,0,677.692,699.9Z" transform="translate(576.417 2723.895)" fill="#21252b" />
      <path id="Path_2758" data-name="Path 2758" d="M680.974,699.05h91.14a2.275,2.275,0,0,0,2.228-1.847l11.783-61.94A2.243,2.243,0,0,0,783.9,632.6H692.81a2.275,2.275,0,0,0-2.228,1.847L678.8,696.387A2.2,2.2,0,0,0,680.974,699.05Z" transform="translate(575.296 2723.087)" fill="#fff" />
      <path id="Path_2759" data-name="Path 2759" d="M791.37,636.871l.361-1.954a1.955,1.955,0,0,0-1.927-2.308H698.236a2.132,2.132,0,0,0-2.1,1.753l-.455,2.509Z" transform="translate(569.696 2723.084)" fill="#61656d" />
      <path id="Path_2760" data-name="Path 2760" d="M700.823,635.5a1.24,1.24,0,0,1-1.151.883.681.681,0,0,1-.7-.89,1.24,1.24,0,0,1,1.151-.884A.683.683,0,0,1,700.823,635.5Z" transform="translate(568.615 2722.423)" />
      <path id="Path_2761" data-name="Path 2761" d="M705.973,635.509a1.239,1.239,0,0,1-1.151.883.681.681,0,0,1-.7-.89,1.24,1.24,0,0,1,1.151-.883A.683.683,0,0,1,705.973,635.509Z" transform="translate(566.911 2722.419)" />
      <path id="Path_2762" data-name="Path 2762" d="M711.153,635.509a1.24,1.24,0,0,1-1.151.883.681.681,0,0,1-.7-.89,1.24,1.24,0,0,1,1.151-.883A.683.683,0,0,1,711.153,635.509Z" transform="translate(565.197 2722.419)" />
      <path id="Path_2763" data-name="Path 2763" d="M468.9,752.43H464.21V935.862H468.9Z" transform="translate(646.286 2683.438)" fill="#acb0b5" />
      <path id="Path_2764" data-name="Path 2764" d="M839.451,752.43h-4.69V935.708h4.69Z" transform="translate(523.678 2683.438)" fill="#acb0b5" />
      <path id="Path_2765" data-name="Path 2765" d="M891.382,752.43H841.76V935.7h49.622Z" transform="translate(521.361 2683.438)" fill="#61656d" />
      <path id="Path_2766" data-name="Path 2766" d="M891.382,752.43H841.76V935.7h49.622Z" transform="translate(521.361 2683.438)" fill="#80848b" />
      <path id="Path_2767" data-name="Path 2767" d="M520.852,752.43H471.23V935.7h49.622Z" transform="translate(643.963 2683.438)" fill="#61656d" />
      <path id="Path_2768" data-name="Path 2768" d="M520.852,752.43H471.23V935.862h49.622Z" transform="translate(643.963 2683.438)" fill="#80848b" />
      <path id="Path_2769" data-name="Path 2769" d="M665.16,752.938l113.482-.508v30.732l-113.482.5Z" transform="translate(579.795 2683.438)" fill="#acb0b5" />
      <g id="Group_1106" data-name="Group 1106" transform="translate(1300.189 3447.258)" opacity="0.5">
        <path id="Path_2770" data-name="Path 2770" d="M754.615,772.16a3.5,3.5,0,0,1-4.182,4.208,3.465,3.465,0,0,1-2.63-2.589,3.5,3.5,0,1,1,6.812-1.619Z" transform="translate(-747.708 -769.454)" opacity="0.5" />
      </g>
      <path id="Path_2771" data-name="Path 2771" d="M771.79,738.779h95.69a4.57,4.57,0,1,1,0,9.14H771.79" transform="translate(544.513 2687.955)" fill="#61656d" />
      <path id="Path_2772" data-name="Path 2772" d="M771.79,738.779h95.69a4.57,4.57,0,1,1,0,9.14H771.79" transform="translate(544.513 2687.955)" fill="#80848b" />
      <path id="Path_2773" data-name="Path 2773" d="M452.94,738.779H717.067a4.57,4.57,0,0,1,0,9.14H452.94a4.57,4.57,0,0,1,0-9.14Z" transform="translate(651.527 2687.955)" fill="#acb0b5" />
      <path id="Path_2774" data-name="Path 2774" d="M832.954,752.43H665.16v1.559H832.954Z" transform="translate(579.795 2683.438)" opacity="0.5" />
      <path id="Path_2775" data-name="Path 2775" d="M518.522,752.43H464.21v1.559h54.312Z" transform="translate(646.286 2683.438)" opacity="0.5" />
      <path id="Path_2776" data-name="Path 2776" d="M487,849.641H464.21V854.2h23.459Z" transform="translate(646.286 2651.272)" opacity="0.5" />
      <path id="Path_2777" data-name="Path 2777" d="M680.9,728.885l91.348-.006a2.154,2.154,0,0,0,2.121-1.773l.776-4.336h-95.69l-.683,3.526A2.17,2.17,0,0,0,680.9,728.885Z" transform="translate(575.306 2693.252)" fill="#61656d" />
      <path id="Path_2778" data-name="Path 2778" d="M748.954,726.516a2.331,2.331,0,0,1-2.155,1.659,1.275,1.275,0,0,1-1.3-1.666,2.331,2.331,0,0,1,2.155-1.659A1.28,1.28,0,0,1,748.954,726.516Z" transform="translate(553.23 2692.563)" />
      <path id="Path_2779" data-name="Path 2779" d="M760.214,726.535a2.331,2.331,0,0,1-2.155,1.659,1.275,1.275,0,0,1-1.3-1.666,2.331,2.331,0,0,1,2.155-1.659A1.277,1.277,0,0,1,760.214,726.535Z" transform="translate(549.505 2692.557)" />
      <path id="Path_2780" data-name="Path 2780" d="M737.724,726.5a2.331,2.331,0,0,1-2.155,1.659,1.275,1.275,0,0,1-1.3-1.666,2.331,2.331,0,0,1,2.155-1.659A1.277,1.277,0,0,1,737.724,726.5Z" transform="translate(556.947 2692.57)" />
      <path id="Path_2781" data-name="Path 2781" d="M772.762,712.655h17.156a.429.429,0,0,0,.422-.348l1.954-10.237a.421.421,0,0,0-.422-.5H774.723a.429.429,0,0,0-.422.348l-1.954,10.237A.426.426,0,0,0,772.762,712.655Z" transform="translate(544.33 2700.267)" fill="#4d61fc" />
      <path id="Path_2782" data-name="Path 2782" d="M698.152,712.655h17.156a.429.429,0,0,0,.422-.348l1.954-10.237a.421.421,0,0,0-.422-.5H700.113a.429.429,0,0,0-.422.348l-1.954,10.237A.426.426,0,0,0,698.152,712.655Z" transform="translate(569.018 2700.267)" fill="#4d61fc" />
      <path id="Path_2783" data-name="Path 2783" d="M735.461,712.655h17.156a.429.429,0,0,0,.422-.348l1.954-10.237a.421.421,0,0,0-.422-.5H737.422a.429.429,0,0,0-.422.348l-1.954,10.237A.42.42,0,0,0,735.461,712.655Z" transform="translate(556.673 2700.267)" fill="#4d61fc" />
      <path id="Path_2784" data-name="Path 2784" d="M713.942,680.428H767.19a1.318,1.318,0,0,0,1.3-1.077l6.056-31.77a1.3,1.3,0,0,0-1.3-1.552H720.031a1.318,1.318,0,0,0-1.3,1.077l-6.056,31.77A1.282,1.282,0,0,0,713.942,680.428Z" transform="translate(564.081 2718.644)" fill="#4d61fc" />
      <g id="Group_1107" data-name="Group 1107" transform="translate(1276.732 3364.673)" opacity="0.5">
        <path id="Path_2785" data-name="Path 2785" d="M713.942,680.428H767.19a1.318,1.318,0,0,0,1.3-1.077l6.056-31.77a1.3,1.3,0,0,0-1.3-1.552H720.031a1.318,1.318,0,0,0-1.3,1.077l-6.056,31.77A1.282,1.282,0,0,0,713.942,680.428Z" transform="translate(-712.651 -646.029)" fill="#fff" opacity="0.5" />
      </g>
      <path id="Path_2786" data-name="Path 2786" d="M776.576,685.7l-1.2-3.439,5.085-2.462v-6.149a3.087,3.087,0,0,0-2.616-3.051l-14.56-1.726a2.945,2.945,0,0,0-2.971,1.425l-4.476,7.641L759.433,679l.2,8.879h16.949V685.7Z" transform="translate(549.791 2711.095)" fill="#4d61fc" />
      <path id="Path_2787" data-name="Path 2787" d="M760.716,678.748a.073.073,0,0,0,.06-.027l.542-.509a.09.09,0,1,0-.12-.133l-.542.508a.093.093,0,0,0-.007.127A.075.075,0,0,0,760.716,678.748Z" transform="translate(548.207 2708.047)" fill="#fff" />
      <path id="Path_2788" data-name="Path 2788" d="M761.255,678.762a.073.073,0,0,0,.06-.027.093.093,0,0,0,.007-.127l-.508-.542a.09.09,0,0,0-.134.12l.509.542A.093.093,0,0,0,761.255,678.762Z" transform="translate(548.196 2708.053)" fill="#fff" />
      <path id="Path_2789" data-name="Path 2789" d="M770.086,674.257a.073.073,0,0,0,.06-.027l.542-.509a.09.09,0,0,0-.12-.134l-.542.509a.094.094,0,0,0,.06.161Z" transform="translate(545.106 2709.532)" fill="#fff" />
      <path id="Path_2790" data-name="Path 2790" d="M770.625,674.262a.073.073,0,0,0,.06-.027.093.093,0,0,0,.007-.127l-.509-.542a.09.09,0,0,0-.134.12l.509.542A.074.074,0,0,0,770.625,674.262Z" transform="translate(545.097 2709.542)" fill="#fff" />
      <path id="Path_2791" data-name="Path 2791" d="M777.46,677.795a.656.656,0,1,1,.656.656A.657.657,0,0,1,777.46,677.795Zm.181,0a.482.482,0,1,0,.482-.482A.48.48,0,0,0,777.641,677.795Z" transform="translate(542.637 2708.35)" fill="#fff" />
      <path id="Path_2792" data-name="Path 2792" d="M787.34,680.022a.089.089,0,0,0,.087-.087.475.475,0,1,1,.95,0,.087.087,0,0,0,.174,0,.656.656,0,0,0-1.311,0A.113.113,0,0,0,787.34,680.022Z" transform="translate(539.4 2707.642)" fill="#fff" />
      <path id="Path_2793" data-name="Path 2793" d="M788.6,675.742l1.211-.408a.089.089,0,0,0-.06-.167l-1.218.408a.084.084,0,0,0-.053.114A.108.108,0,0,0,788.6,675.742Z" transform="translate(538.994 2709.005)" fill="#fff" />
      <path id="Path_2794" data-name="Path 2794" d="M771.167,678.164h1.285a.087.087,0,1,0,0-.174h-1.285a.087.087,0,1,0,0,.174Z" transform="translate(544.748 2708.069)" fill="#fff" />
      <path id="Path_2795" data-name="Path 2795" d="M766.538,671.584a.072.072,0,0,0,.06-.027.093.093,0,0,0,0-.127l-.9-.9a.09.09,0,0,0-.127.127l.9.9A.1.1,0,0,0,766.538,671.584Z" transform="translate(546.58 2710.546)" fill="#fff" />
      <path id="Path_2796" data-name="Path 2796" d="M776.35,678.538a.589.589,0,1,1,.589.589A.59.59,0,0,1,776.35,678.538Zm.04,0a.549.549,0,1,0,.549-.549A.551.551,0,0,0,776.39,678.538Z" transform="translate(543.004 2708.082)" fill="#fff" />
      <path id="Path_2797" data-name="Path 2797" d="M786.15,680.679h.033a.549.549,0,1,1,1.1,0h.033a.582.582,0,1,0-1.164,0Z" transform="translate(539.762 2707.374)" fill="#fff" />
      <path id="Path_2798" data-name="Path 2798" d="M788.8,676.316l-1.217.412.011.031,1.217-.412Z" transform="translate(539.289 2708.623)" fill="#fff" />
      <path id="Path_2799" data-name="Path 2799" d="M771.275,678.8H769.99v.033h1.285Z" transform="translate(545.108 2707.801)" fill="#fff" />
      <path id="Path_2800" data-name="Path 2800" d="M764.444,671.336l-.023.023.909.909.023-.024Z" transform="translate(546.951 2710.27)" fill="#fff" />
      <path id="Path_2801" data-name="Path 2801" d="M762.676,691.574a.316.316,0,0,1-.141-.194.289.289,0,0,1,.04-.234.315.315,0,0,1,.194-.14.288.288,0,0,1,.234.04.316.316,0,0,1,.141.194.289.289,0,0,1-.04.234.316.316,0,0,1-.194.141.229.229,0,0,1-.067.007A.288.288,0,0,1,762.676,691.574Zm.134-.395a.131.131,0,0,0-.087.06.138.138,0,0,0-.02.1.131.131,0,0,0,.06.087.138.138,0,0,0,.1.02.131.131,0,0,0,.087-.06.138.138,0,0,0,.02-.1.131.131,0,0,0-.06-.087.135.135,0,0,0-.074-.02C762.83,691.173,762.823,691.173,762.81,691.18Z" transform="translate(547.578 2703.765)" fill="#fff" />
      <path id="Path_2802" data-name="Path 2802" d="M784.626,674.423a.316.316,0,0,1-.141-.194.289.289,0,0,1,.04-.234.316.316,0,0,1,.194-.14.289.289,0,0,1,.234.04.316.316,0,0,1,.141.194.289.289,0,0,1-.04.234.316.316,0,0,1-.194.14.227.227,0,0,1-.067.007A.245.245,0,0,1,784.626,674.423Zm.134-.395a.132.132,0,0,0-.087.06.138.138,0,0,0-.02.1.132.132,0,0,0,.06.087.138.138,0,0,0,.1.02.131.131,0,0,0,.087-.06.138.138,0,0,0,.02-.1.131.131,0,0,0-.06-.087.135.135,0,0,0-.074-.02Z" transform="translate(540.315 2709.44)" fill="#fff" />
      <path id="Path_2803" data-name="Path 2803" d="M763.806,675.484a.316.316,0,0,1-.141-.194.289.289,0,0,1,.04-.234.316.316,0,0,1,.194-.141.289.289,0,0,1,.234.04.316.316,0,0,1,.141.194.289.289,0,0,1-.04.234.317.317,0,0,1-.268.147A.281.281,0,0,1,763.806,675.484Zm.134-.395a.131.131,0,0,0-.087.06.137.137,0,0,0-.02.1.132.132,0,0,0,.06.087.136.136,0,0,0,.207-.14.131.131,0,0,0-.06-.087.135.135,0,0,0-.074-.02C763.96,675.083,763.953,675.083,763.94,675.089Z" transform="translate(547.204 2709.089)" fill="#fff" />
      <path id="Path_2804" data-name="Path 2804" d="M773.857,660.256s4.945-3.1,5.139.689l.074.984.335,4.918.007.054a.546.546,0,0,1-.134.375l-2.864,1.954a.241.241,0,0,1-.281-.013l-2.121-1.807a.547.547,0,0,1-.194-.5l.08-.723.241-2.221a3.792,3.792,0,0,1-.1-.87C773.985,661.6,773.857,660.256,773.857,660.256Z" transform="translate(543.844 2714.332)" fill="#fa9b9b" />
      <path id="Path_2805" data-name="Path 2805" d="M772.393,659.535a1.673,1.673,0,1,0-1.673-1.673A1.673,1.673,0,0,0,772.393,659.535Z" transform="translate(544.867 2715.282)" fill="#3f4249" />
      <path id="Path_2806" data-name="Path 2806" d="M772.838,656.782s-1.76,7.374,2.027,7.869c3.272.435,3.64-4.67,3.64-4.67l-.161-3.312Z" transform="translate(544.302 2715.124)" fill="#fa9b9b" />
      <path id="Path_2807" data-name="Path 2807" d="M772.81,655.606l3.346.241a.77.77,0,0,1,.7.81.757.757,0,0,0,.7.81.224.224,0,0,1,.194.221l-.047.743a.16.16,0,0,0,.161.181.162.162,0,0,0,.181-.134l.147-.7.147-.228.79.482a5,5,0,0,0,.669-3.76,2.3,2.3,0,0,0-1.84-1.445.534.534,0,0,1-.375-.241,1.948,1.948,0,0,0-3.212-.04.514.514,0,0,1-.642.181,1.445,1.445,0,0,0-1.418.107A1.576,1.576,0,0,0,772.81,655.606Z" transform="translate(544.551 2716.762)" fill="#3f4249" />
      <path id="Path_2808" data-name="Path 2808" d="M781.432,663.656a.808.808,0,0,1-.482-.107l.535.823Z" transform="translate(541.482 2712.847)" opacity="0.15" />
      <path id="Path_2809" data-name="Path 2809" d="M781.235,660.543a.678.678,0,0,1,.97-.375c.823.361.656,2.228-.743,2.342-.729.06-.582-.361-.462-.937Z" transform="translate(541.498 2713.993)" fill="#fa9b9b" />
      <path id="Path_2810" data-name="Path 2810" d="M772.984,657.561l.388.033c.033,0,.087-.027.087-.074a.078.078,0,0,0-.074-.087L773,657.4a.631.631,0,0,1-.147-.027,1.794,1.794,0,0,1-.448-.221.073.073,0,0,0-.107.027.075.075,0,0,0,.027.106,1.73,1.73,0,0,0,.482.228A1.8,1.8,0,0,0,772.984,657.561Z" transform="translate(544.35 2714.968)" fill="#fa9b9b" />
      <path id="Path_2811" data-name="Path 2811" d="M773.96,668.765l.127-1.178a2.491,2.491,0,0,0,2.228.542h0a2.556,2.556,0,0,0,1.325-.823.076.076,0,1,1,.12.094,2.757,2.757,0,0,1-1.746.937A2.683,2.683,0,0,1,773.96,668.765Z" transform="translate(543.795 2711.613)" opacity="0.15" />
      <path id="Path_2812" data-name="Path 2812" d="M771.57,661.263v.341a1.191,1.191,0,1,0,2.382,0v-.341a.428.428,0,0,0-.087-.268h.462a.42.42,0,0,0-.094.268v.341a1.191,1.191,0,1,0,2.382,0v-.341a.434.434,0,0,0-.435-.435h-4.162A.437.437,0,0,0,771.57,661.263Zm3.085-.254h1.506a.268.268,0,0,1,.268.268v.341a1.01,1.01,0,0,1-2.021-.013v-.341A.245.245,0,0,1,774.655,661.009Zm-2.924.268a.268.268,0,0,1,.268-.268H773.5a.268.268,0,0,1,.268.268v.341a1.01,1.01,0,1,1-2.021-.013Z" transform="translate(544.586 2713.747)" fill="#21252b" />
      <path id="Path_2813" data-name="Path 2813" d="M789.9,685.219H784.37l1.786,8.083h3.734Z" transform="translate(540.351 2705.677)" fill="#fa9b9b" />
      <path id="Path_2814" data-name="Path 2814" d="M785.393,687.668l-1.2-1.639-.89-3.58a1.386,1.386,0,0,0-.214-.475,1.356,1.356,0,0,0-.549-.428l-2.958-1.425a.389.389,0,0,0-.361.688l2.462,1.486-2.222-.709a.38.38,0,0,0-.5.375.393.393,0,0,0,.221.335l2.6,1.1-1.981-.836a.348.348,0,0,0-.489.329.366.366,0,0,0,.167.3l2.128,1.291-1.405-.842a.307.307,0,0,0-.462.187.313.313,0,0,0,.08.294l1.218,1.211,2.054,6.257h1.546C785.059,689.9,785.393,687.668,785.393,687.668Z" transform="translate(542.138 2707.376)" fill="#fa9b9b" />
      <path id="Path_2815" data-name="Path 2815" d="M789.362,695.022l-1.947-2.563-3.205,1.372.622,1.867h4.885A2.338,2.338,0,0,0,789.362,695.022Z" transform="translate(540.403 2703.281)" fill="#fa9b9b" />
      <path id="Path_2816" data-name="Path 2816" d="M785.867,686.486l-.91-3.566a1.206,1.206,0,0,0-.636-.783l-.334-.167-.007-.3.776.368a1.209,1.209,0,0,1,.662.823l.81,3.506,3.533,4.49-.007,1.271Z" transform="translate(540.479 2706.852)" opacity="0.15" />
      <path id="Path_2817" data-name="Path 2817" d="M773.46,680.119H760.82l2.315,11.5h12.64Z" transform="translate(548.143 2707.364)" fill="#21252b" />
      <path id="Path_2818" data-name="Path 2818" d="M754.345,682.408l-3.065,5.052,5.045.883,2.262-4.7Z" transform="translate(551.3 2706.607)" fill="#fa9b9b" />
      <path id="Path_2819" data-name="Path 2819" d="M756.156,685.406l.8.455L758.7,684.5a1.351,1.351,0,0,1,1-.027l3.138.97a.392.392,0,0,1-.2.756l-2.817-.582,2.108,1a.382.382,0,0,1-.281.709l-2.656-.964,2.021.736a.354.354,0,0,1,.134.575.363.363,0,0,1-.328.107l-2.61-.535,1.766.375a.308.308,0,0,1,.207.455.3.3,0,0,1-.261.161l-1.72.06-1.94,2.3a2.868,2.868,0,0,1-3.693.616,1.433,1.433,0,0,1-.127-.087l-.067-.047C751.627,689.488,756.156,685.406,756.156,685.406Z" transform="translate(550.966 2705.952)" fill="#fa9b9b" />
      <path id="Path_2820" data-name="Path 2820" d="M755.7,685.91l-2.857,1.439a2.323,2.323,0,1,0,1.331,4.416l1.78-1.753Z" transform="translate(551.418 2705.448)" fill="#fa9b9b" />
      <path id="Path_2821" data-name="Path 2821" d="M756.775,685.978l1.713-1.238-1.679.783-4.336.984-.723,1.245Z" transform="translate(551.144 2705.835)" opacity="0.15" />
      <path id="Path_2822" data-name="Path 2822" d="M761.858,683.943l-.254.087-.234-1.171,1.265.314Z" transform="translate(547.961 2706.458)" fill="#21252b" />
      <path id="Path_2823" data-name="Path 2823" d="M780.317,683.033l.435-.977-.529-.308-.114.234Z" transform="translate(541.76 2706.825)" fill="#21252b" />
      <path id="Path_2824" data-name="Path 2824" d="M781.359,679.423l1.151-2.656a.318.318,0,0,0-.161-.422.326.326,0,0,0-.435.174l-1.084,2.663Z" transform="translate(541.521 2708.622)" fill="#21252b" />
      <g id="Group_1108" data-name="Group 1108" transform="translate(1322 3389.902)" opacity="0.15">
        <path id="Path_2825" data-name="Path 2825" d="M782.115,684.682a.13.13,0,0,1-.047-.007l-1.693-.723a.108.108,0,0,1-.06-.147.11.11,0,0,1,.147-.06l1.693.723a.108.108,0,0,1,.06.147A.1.1,0,0,1,782.115,684.682Z" transform="translate(-780.305 -683.734)" opacity="0.15" />
      </g>
      <path id="Path_2826" data-name="Path 2826" d="M782.256,683.177l-1.452-.468-.094.207Z" transform="translate(541.562 2706.507)" opacity="0.15" />
      <g id="Group_1109" data-name="Group 1109" transform="translate(1322.238 3390.915)" opacity="0.15">
        <path id="Path_2827" data-name="Path 2827" d="M782.038,686.232a.194.194,0,0,1-.06-.013l-1.265-.763a.112.112,0,1,1,.114-.194l1.265.763a.117.117,0,0,1,.04.154A.107.107,0,0,1,782.038,686.232Z" transform="translate(-780.66 -685.248)" opacity="0.15" />
      </g>
      <g id="Group_1110" data-name="Group 1110" transform="translate(1309.082 3393.194)" opacity="0.15">
        <path id="Path_2828" data-name="Path 2828" d="M761,688.724a.084.084,0,0,1,.107-.067l1.4.281.348.074a.293.293,0,0,1,.234.227l-2.021-.408A.1.1,0,0,1,761,688.724Z" transform="translate(-760.998 -688.655)" opacity="0.15" />
      </g>
      <path id="Path_2829" data-name="Path 2829" d="M762.192,687.474a.089.089,0,1,1,.06-.167l.923.341-.93-.335,1.077.388,1,.368a.366.366,0,0,1,.174.248.025.025,0,0,1-.02-.007Z" transform="translate(547.708 2704.987)" opacity="0.15" />
      <path id="Path_2830" data-name="Path 2830" d="M767.29,676.049l-1.76,2.723h.964Z" transform="translate(546.584 2708.711)" opacity="0.15" />
      <path id="Path_2831" data-name="Path 2831" d="M783.533,680.488l-.074-3.3-.85,2.857Z" transform="translate(540.933 2708.334)" opacity="0.15" />
      <path id="Path_2832" data-name="Path 2832" d="M773.582,669.686l-.08.723a.547.547,0,0,0,.194.5l2.121,1.807a.241.241,0,0,0,.281.013l2.864-1.954a.546.546,0,0,0,.134-.375l-.007-.054.783.094-.094.335a.462.462,0,0,1-.181.254l-3.372,2.369a.473.473,0,0,1-.575-.033l-2.636-2.349a.46.46,0,0,1-.154-.381l.08-1.01Z" transform="translate(544.16 2710.838)" opacity="0.6" />
      <path id="Path_2833" data-name="Path 2833" d="M790.545,684.888h-4.082l-.154-.669h4.235Z" transform="translate(539.709 2706.008)" opacity="0.6" />
      <path id="Path_2834" data-name="Path 2834" d="M755.86,682.074l.395-.676,3.292.977.167.823Z" transform="translate(549.784 2706.941)" opacity="0.6" />
      <path id="Path_2835" data-name="Path 2835" d="M729.08,658.409l.776-4.45,13.188.02-.776,4.423H729.08Z" transform="translate(558.645 2716.02)" fill="#fff" />
      <path id="Path_2836" data-name="Path 2836" d="M742.9,656.3h-3.734a.2.2,0,1,1,0-.388H742.9a.221.221,0,0,1,.241.194A.225.225,0,0,1,742.9,656.3Z" transform="translate(555.386 2715.375)" fill="#61656d" />
      <path id="Path_2837" data-name="Path 2837" d="M733.918,657.936a1.358,1.358,0,1,0-1.358-1.358A1.358,1.358,0,0,0,733.918,657.936Z" transform="translate(557.493 2715.603)" fill="#4d61fc" />
      <path id="Path_2838" data-name="Path 2838" d="M733.918,657.936a1.358,1.358,0,1,0-1.358-1.358A1.358,1.358,0,0,0,733.918,657.936Z" transform="translate(557.493 2715.603)" fill="#fff" opacity="0.4" />
      <path id="Path_2839" data-name="Path 2839" d="M733.978,657.559l-.455-.455a.07.07,0,0,1,0-.087l.12-.12a.07.07,0,0,1,.087,0l.294.294.709-.709a.07.07,0,0,1,.087,0l.12.12a.07.07,0,0,1,0,.087l-.877.877A.063.063,0,0,1,733.978,657.559Z" transform="translate(557.18 2715.19)" fill="#fff" />
      <path id="Path_2840" data-name="Path 2840" d="M727.62,668.868l.776-4.45,13.188.02-.776,4.443Z" transform="translate(559.128 2712.559)" fill="#fff" />
      <path id="Path_2841" data-name="Path 2841" d="M741.445,666.767h-3.734a.2.2,0,1,1,0-.388h3.734a.221.221,0,0,1,.241.194A.225.225,0,0,1,741.445,666.767Z" transform="translate(555.869 2711.911)" fill="#61656d" />
      <path id="Path_2842" data-name="Path 2842" d="M732.458,668.395a1.358,1.358,0,1,0-1.358-1.358A1.358,1.358,0,0,0,732.458,668.395Z" transform="translate(557.977 2712.143)" fill="#4d61fc" />
      <path id="Path_2843" data-name="Path 2843" d="M732.458,668.395a1.358,1.358,0,1,0-1.358-1.358A1.358,1.358,0,0,0,732.458,668.395Z" transform="translate(557.977 2712.143)" fill="#fff" opacity="0.4" />
      <path id="Path_2844" data-name="Path 2844" d="M732.518,668.018l-.455-.455a.07.07,0,0,1,0-.087l.12-.12a.07.07,0,0,1,.087,0l.294.294.709-.709a.07.07,0,0,1,.087,0l.12.12a.07.07,0,0,1,0,.087l-.877.877A.063.063,0,0,1,732.518,668.018Z" transform="translate(557.663 2711.73)" fill="#fff" />
      <path id="Path_2845" data-name="Path 2845" d="M726.18,679.339l.776-4.45,13.188.02-.776,4.443Z" transform="translate(559.604 2709.095)" fill="#fff" />
      <path id="Path_2846" data-name="Path 2846" d="M740,677.226h-3.734a.2.2,0,1,1,0-.388H740a.221.221,0,0,1,.241.194A.225.225,0,0,1,740,677.226Z" transform="translate(556.346 2708.45)" fill="#61656d" />
      <path id="Path_2847" data-name="Path 2847" d="M731.018,678.865a1.358,1.358,0,1,0-1.358-1.358A1.358,1.358,0,0,0,731.018,678.865Z" transform="translate(558.453 2708.678)" fill="#4d61fc" />
      <path id="Path_2848" data-name="Path 2848" d="M731.018,678.865a1.358,1.358,0,1,0-1.358-1.358A1.358,1.358,0,0,0,731.018,678.865Z" transform="translate(558.453 2708.678)" fill="#fff" opacity="0.4" />
      <path id="Path_2849" data-name="Path 2849" d="M731.078,678.489l-.455-.455a.07.07,0,0,1,0-.087l.12-.12a.07.07,0,0,1,.087,0l.294.294.709-.709a.07.07,0,0,1,.087,0l.12.12a.07.07,0,0,1,0,.087l-.877.877A.063.063,0,0,1,731.078,678.489Z" transform="translate(558.14 2708.265)" fill="#fff" />
      <path id="Path_2850" data-name="Path 2850" d="M724.72,689.8l.776-4.45,13.188.02-.776,4.443Z" transform="translate(560.088 2705.633)" fill="#fff" />
      <path id="Path_2851" data-name="Path 2851" d="M738.545,687.7h-3.734a.2.2,0,1,1,0-.388h3.734a.2.2,0,1,1,0,.388Z" transform="translate(556.828 2704.984)" fill="#61656d" />
      <path id="Path_2852" data-name="Path 2852" d="M729.558,689.326a1.358,1.358,0,1,0-1.358-1.358A1.358,1.358,0,0,0,729.558,689.326Z" transform="translate(558.937 2705.217)" fill="#4d61fc" />
      <path id="Path_2853" data-name="Path 2853" d="M729.558,689.326a1.358,1.358,0,1,0-1.358-1.358A1.358,1.358,0,0,0,729.558,689.326Z" transform="translate(558.937 2705.217)" fill="#fff" opacity="0.4" />
      <path id="Path_2854" data-name="Path 2854" d="M729.618,688.95l-.455-.455a.07.07,0,0,1,0-.087l.12-.12a.07.07,0,0,1,.087,0l.294.294.709-.709a.07.07,0,0,1,.087,0l.12.12a.07.07,0,0,1,0,.087l-.877.877A.063.063,0,0,1,729.618,688.95Z" transform="translate(558.623 2704.804)" fill="#fff" />
      <g id="Group_1111" data-name="Group 1111" transform="translate(1266.75 3401.835)" opacity="0.7">
        <path id="Path_2855" data-name="Path 2855" d="M698.152,712.655h17.156a.429.429,0,0,0,.422-.348l1.954-10.237a.421.421,0,0,0-.422-.5H700.113a.429.429,0,0,0-.422.348l-1.954,10.237A.426.426,0,0,0,698.152,712.655Z" transform="translate(-697.732 -701.568)" fill="#fff" opacity="0.7" />
      </g>
      <g id="Group_1112" data-name="Group 1112" transform="translate(1291.713 3401.835)" opacity="0.7">
        <path id="Path_2856" data-name="Path 2856" d="M735.461,712.655h17.156a.429.429,0,0,0,.422-.348l1.954-10.237a.421.421,0,0,0-.422-.5H737.422a.429.429,0,0,0-.422.348l-1.954,10.237A.42.42,0,0,0,735.461,712.655Z" transform="translate(-735.039 -701.568)" fill="#fff" opacity="0.7" />
      </g>
      <g id="Group_1113" data-name="Group 1113" transform="translate(1316.672 3401.835)" opacity="0.7">
        <path id="Path_2857" data-name="Path 2857" d="M772.762,712.655h17.156a.429.429,0,0,0,.422-.348l1.954-10.237a.421.421,0,0,0-.422-.5H774.723a.429.429,0,0,0-.422.348l-1.954,10.237A.426.426,0,0,0,772.762,712.655Z" transform="translate(-772.342 -701.568)" fill="#fff" opacity="0.7" />
      </g>
      <path id="Path_2858" data-name="Path 2858" d="M539.566,849.641H528.439l-.629,4.563h11.756Z" transform="translate(625.242 2651.272)" opacity="0.5" />
      <path id="Path_2859" data-name="Path 2859" d="M553.93,786.219l16.507,19.023a24.352,24.352,0,0,1,.984,6.464Z" transform="translate(616.6 2672.258)" opacity="0.15" />
      <path id="Path_2860" data-name="Path 2860" d="M529.755,681.6a1.388,1.388,0,1,1,1.211,1.546A1.386,1.386,0,0,1,529.755,681.6Z" transform="translate(624.602 2707.277)" fill="#4d61fc" />
      <g id="Group_1116" data-name="Group 1116" transform="translate(1151.341 3380.812)" opacity="0.15">
        <g id="Group_1115" data-name="Group 1115" opacity="0.15">
          <g id="Group_1114" data-name="Group 1114" opacity="0.15">
            <path id="Path_2861" data-name="Path 2861" d="M525.532,670.685a2.738,2.738,0,0,1,2.121-.482,2.579,2.579,0,0,1,1.633,1.392,5.171,5.171,0,0,1,.669,2.743l-.02.729c0,.535-.95.689-.937.02l-.033-.8a4.836,4.836,0,0,0-.529-2.262,1.554,1.554,0,0,0-2.523-.636.41.41,0,1,1-.381-.7Z" transform="translate(-525.253 -670.148)" opacity="0.15" />
          </g>
        </g>
      </g>
      <g id="Group_1119" data-name="Group 1119" transform="translate(1154.349 3387.945)" opacity="0.15">
        <g id="Group_1118" data-name="Group 1118" opacity="0.15">
          <g id="Group_1117" data-name="Group 1117" opacity="0.15">
            <path id="Path_2862" data-name="Path 2862" d="M531.6,683.158a1.506,1.506,0,0,1-1.479-1.579,1.586,1.586,0,0,1,.294-.769,1.542,1.542,0,0,0-.669,1.117,1.369,1.369,0,0,0,1.365,1.432,1.216,1.216,0,0,0,.83-.234C531.81,683.117,531.676,683.1,531.6,683.158Z" transform="translate(-529.748 -680.809)" opacity="0.15" />
          </g>
        </g>
      </g>
      <path id="Path_2863" data-name="Path 2863" d="M509.561,545.919l-3.205,26.316a4.935,4.935,0,0,1-4.021,4.2l-3.085.575,6.5,4.844a25.8,25.8,0,0,0,22.536,4.269,24.707,24.707,0,0,0,4.115-1.552l7.9-3.767-3.279-1.265a4.894,4.894,0,0,1-3.138-4.43l-.509-31.683Z" transform="translate(634.692 2752.592)" fill="#f59696" />
      <path id="Path_2864" data-name="Path 2864" d="M538.745,560.552l-1.2.361a16.3,16.3,0,0,1-20.154-11.227l-6.243-22.041a5.439,5.439,0,0,1,3.767-6.725l20.609-5.9a7.075,7.075,0,0,1,8.732,4.878l5.795,20.488A16.453,16.453,0,0,1,538.745,560.552Z" transform="translate(630.828 2762.081)" fill="#f59696" />
      <path id="Path_2865" data-name="Path 2865" d="M566.98,544.91a30.96,30.96,0,0,1,3.8,4.068c1.178,1.72-2.221,2.6-2.221,2.6Z" transform="translate(612.281 2752.103)" fill="#f59696" />
      <g id="Group_1122" data-name="Group 1122" transform="translate(1140.325 3310.556)" opacity="0.15">
        <g id="Group_1121" data-name="Group 1121" opacity="0.15">
          <g id="Group_1120" data-name="Group 1120" opacity="0.15">
            <path id="Path_2866" data-name="Path 2866" d="M508.937,565.15h2.5l-2.65,5.3Z" transform="translate(-508.79 -565.15)" opacity="0.15" />
          </g>
        </g>
      </g>
      <g id="Group_1124" data-name="Group 1124" transform="translate(1162.573 3322.914)" opacity="0.15">
        <g id="Group_1123" data-name="Group 1123" opacity="0.15">
          <path id="Path_2867" data-name="Path 2867" d="M542.04,584.268a16.073,16.073,0,0,0,5.942-.649l.054,4.53Z" transform="translate(-542.04 -583.619)" opacity="0.15" />
        </g>
      </g>
      <g id="Group_1127" data-name="Group 1127" transform="translate(1175.64 3305.916)" opacity="0.15">
        <g id="Group_1126" data-name="Group 1126" opacity="0.15">
          <g id="Group_1125" data-name="Group 1125" opacity="0.15">
            <path id="Path_2868" data-name="Path 2868" d="M567.283,561a7.871,7.871,0,0,1-5.607-2.161.364.364,0,1,1,.509-.522,7.525,7.525,0,0,0,5.166,1.954Z" transform="translate(-561.567 -558.216)" opacity="0.15" />
          </g>
        </g>
      </g>
      <path id="Path_2869" data-name="Path 2869" d="M467.681,746.869H462.89v187h4.791Z" transform="translate(646.723 2685.278)" fill="#acb0b5" />
      <path id="Path_2870" data-name="Path 2870" d="M520.672,746.869H470.04v187h50.632Z" transform="translate(644.357 2685.278)" fill="#61656d" />
      <path id="Path_2871" data-name="Path 2871" d="M520.672,746.869H470.04v187h50.632Z" transform="translate(644.357 2685.278)" fill="#80848b" />
      <g id="Group_1130" data-name="Group 1130" transform="translate(1109.613 3432.147)" opacity="0.5">
        <g id="Group_1129" data-name="Group 1129" transform="translate(0 0)" opacity="0.5">
          <g id="Group_1128" data-name="Group 1128" opacity="0.5">
            <path id="Path_2872" data-name="Path 2872" d="M518.306,746.869H462.89v1.586h55.416Z" transform="translate(-462.89 -746.869)" opacity="0.5" />
          </g>
        </g>
      </g>
      <path id="Path_2873" data-name="Path 2873" d="M525.054,640.914l16.206,32.392,23.841-6.5-17.009-50.418a29.072,29.072,0,0,0-6.684-11.087,18.462,18.462,0,0,0-13.663-6.009l-38.314-.475H476.483a19.935,19.935,0,0,0-19.933,19.933v92.2h77.243l-.114-29.816a4.487,4.487,0,0,1,1.238-3.125c3.546-3.7,8.852-8,5.627-19.4" transform="translate(648.82 2734.265)" fill="#7bb200" />
      <path id="Path_2874" data-name="Path 2874" d="M503.917,528.783s-5.186,16.915,3.493,26.49c4.992,5.507,10.251,1.733,17.37,9.936,0,0-7.635,0-12.325,6.3-5.534,7.434-18.595,39.4-40.776,40.227s-31.843-4.723-32.238-13.141c-1.091-23.412,21.124-26.778,22.469-42.429.83-9.649-4.684-49.18,21.78-58.481,24.985-8.786,39.552,5.206,39.3,10.271S523.6,520.138,503.917,528.783Z" transform="translate(654.495 2768.665)" />
      <path id="Path_2875" data-name="Path 2875" d="M681.844,935.641l-9.026-6.223V891.828l-.033-.074c-.181-56.186-1.1-90.712-3.566-111.769-2.328-19.94-22.917-3.459-28.685-6.163-3.459-1.626-5.748-8.09-12.66-8.431-12.171-.6-16.173-9.461-16.173-9.461l-5.721,38.8,31.475,1.4c-.863,32.539,12.446,56.734,18.555,90.926-3.352,2.134-5.835,6.142-5.835,12.158,14.821,4.925,13.208,32.024,13.208,32.024Z" transform="translate(599.377 2682.28)" fill="#f59696" />
      <g id="Group_1134" data-name="Group 1134" transform="translate(1205.356 3438.203)" opacity="0.2">
        <g id="Group_1133" data-name="Group 1133" opacity="0.2">
          <g id="Group_1131" data-name="Group 1131" transform="translate(43.232 14.053)" opacity="0.2">
            <path id="Path_2876" data-name="Path 2876" d="M672.464,780.768c2.543,1.191,7.976-1.352,13.449-2.85-4.128-2.255-10.164-.207-15.323,1.485A7.066,7.066,0,0,0,672.464,780.768Z" transform="translate(-670.59 -776.922)" opacity="0.2" />
          </g>
          <g id="Group_1132" data-name="Group 1132" opacity="0.2">
            <path id="Path_2877" data-name="Path 2877" d="M637.455,796.127c-.863,32.539,12.439,56.734,18.548,90.926-3.352,2.134-5.835,6.142-5.835,12.158,14.821,4.931,13.208,32.024,13.208,32.024l18.461,4.4-9.026-6.223V906.01h.007c-2.971-4.1-6.952-14.928-7.675-18.956-6.109-34.192-19.458-58.287-18.6-90.819L616,795.291l5.915-31.77c-6.838-4.376-11.107-7.6-11.107-7.6l-4.824,38.8" transform="translate(-605.98 -755.92)" opacity="0.2" />
          </g>
        </g>
      </g>
      <path id="Path_2878" data-name="Path 2878" d="M703,1000.993H687.662a4.3,4.3,0,0,1-4.249-3.68c-1.1-7.561-4.3-24.824-11.529-33.248,0,0-2.195-8.759,5.835-12.165,0,0,8.036,5.313,5.447,21.164l4.463,17.143s10.866,3.419,16.072,9.24A.932.932,0,0,1,703,1000.993Z" transform="translate(577.661 2617.436)" fill="#3f3d56" />
      <path id="Path_2879" data-name="Path 2879" d="M671.256,970.08l-1.546,36.929h1.546l4.028-36.929Z" transform="translate(578.289 2611.421)" fill="#3f3d56" />
      <path id="Path_2880" data-name="Path 2880" d="M616.493,935.919l-9.026-6.223V892.106l-.033-.074c-.181-56.186-1.1-90.711-3.566-111.769-2.328-19.94-22.917-3.46-28.685-6.163-3.453-1.626-5.748-8.089-12.66-8.43-12.171-.6-30.866-8.9-30.866-8.9L524.51,798.8l47.6,1.1c-.863,32.539,12.446,53.255,18.555,87.44-3.352,2.135-5.835,6.143-5.835,12.159,14.821,4.925,13.208,32.024,13.208,32.024Z" transform="translate(626.334 2682.002)" fill="#f59696" />
      <g id="Group_1138" data-name="Group 1138" transform="translate(1150.837 3438.772)" opacity="0.2">
        <g id="Group_1137" data-name="Group 1137" opacity="0.2">
          <g id="Group_1135" data-name="Group 1135" transform="translate(59.351 13.484)" opacity="0.2">
            <path id="Path_2881" data-name="Path 2881" d="M615.074,780.768c2.543,1.191,7.976-1.352,13.449-2.85-4.128-2.255-10.164-.207-15.323,1.485A7.175,7.175,0,0,0,615.074,780.768Z" transform="translate(-613.2 -776.922)" opacity="0.2" />
          </g>
          <g id="Group_1136" data-name="Group 1136" opacity="0.2">
            <path id="Path_2882" data-name="Path 2882" d="M607.464,906.29c-2.971-4.095-6.952-14.927-7.675-18.955-6.109-34.192-18.006-54.908-17.143-87.441l-47.6-1.1,5.948-34.995c-6.838-4.376-9.348-7.032-9.348-7.032L524.5,798.8l47.6,1.1c-.863,32.539,12.446,53.248,18.555,87.44-3.352,2.135-5.835,6.143-5.835,12.159,14.821,4.931,13.208,32.024,13.208,32.024l18.461,4.4-9.026-6.223Z" transform="translate(-524.5 -756.77)" opacity="0.2" />
          </g>
        </g>
      </g>
      <path id="Path_2883" data-name="Path 2883" d="M645.618,1000.993H630.282a4.3,4.3,0,0,1-4.249-3.68c-1.1-7.561-4.3-24.824-11.529-33.248,0,0-2.195-8.759,5.835-12.165,0,0,8.036,5.313,5.447,21.164l4.463,17.143s10.866,3.419,16.072,9.24A.934.934,0,0,1,645.618,1000.993Z" transform="translate(596.646 2617.436)" fill="#3f3d56" />
      <path id="Path_2884" data-name="Path 2884" d="M613.879,970.08l-1.539,36.929h1.539l4.028-36.929Z" transform="translate(597.272 2611.421)" fill="#3f3d56" />
      <path id="Path_2885" data-name="Path 2885" d="M571.69,877.447c-7.093-30.692-18.822-53.288-17.471-84.8l-64.991-.529a17.531,17.531,0,0,1-17.384-17.3l-.335-26.062s54.988-.6,67.159,0c6.912.341,15.523,18.1,18.976,19.719,5.761,2.71,26.35-13.777,28.685,6.162,2.322,19.906,3.272,51.87,3.526,102.81Z" transform="translate(643.871 2684.743)" fill="#3f4249" />
      <g id="Group_1142" data-name="Group 1142" transform="translate(1150.83 3438.765)" opacity="0.2">
        <g id="Group_1141" data-name="Group 1141" transform="translate(0 0)" opacity="0.2">
          <g id="Group_1139" data-name="Group 1139" transform="translate(57.631 13.49)" opacity="0.2">
            <path id="Path_2886" data-name="Path 2886" d="M611.865,780.768c2.784,1.311,9.013-1.854,14.975-3.239-4.577-1.8-11.054.81-16.219,2.429A5.989,5.989,0,0,0,611.865,780.768Z" transform="translate(-610.62 -776.92)" opacity="0.2" />
          </g>
          <g id="Group_1140" data-name="Group 1140" opacity="0.2">
            <path id="Path_2887" data-name="Path 2887" d="M597.4,880.165c-6.4-31.7-17.946-49.113-17.123-80.281l-47.6-1.1,6.624-38.956c-4.53-1.686-7.668-3.071-7.668-3.071l-7.146,42.034,47.6,1.1c-.83,31.167,10.726,48.591,17.123,80.294Z" transform="translate(-524.49 -756.76)" opacity="0.2" />
          </g>
        </g>
      </g>
      <path id="Path_2888" data-name="Path 2888" d="M638.356,807.66l-.689-39.766a19.839,19.839,0,0,0-19.819-19.5l-112.218-.107.642,27.266A17.527,17.527,0,0,0,523.6,792.672l80.649.943c-.7,30.906,9.448,49.2,16.942,82.73H639.54C639.4,847.954,639.045,825.458,638.356,807.66Z" transform="translate(632.581 2684.808)" fill="#3f4249" />
      <path id="Path_2889" data-name="Path 2889" d="M539.4,742.99s-11.087,1.833-27.514,8.337c-18.347,7.267-38.2.194-38.2.194V732.17Z" transform="translate(643.149 2690.141)" fill="#7bb200" />
      <g id="Group_1144" data-name="Group 1144" transform="translate(1114.933 3433.197)" opacity="0.2">
        <g id="Group_1143" data-name="Group 1143" transform="translate(0 0)" opacity="0.2">
          <path id="Path_2890" data-name="Path 2890" d="M619.578,876.488c-6.363-31.542-18.441-51.823-17.752-82.73l-101.485-1.138a23.153,23.153,0,0,1-22.73-23.145v-21.03l-6-.007-.776,20.127c0,12.62,5.159,24.061,20.4,24.061l101.485,1.138c-.7,30.906,10.572,51.087,16.942,82.629l18.354.107h-8.444Z" transform="translate(-470.84 -748.439)" opacity="0.2" />
        </g>
      </g>
      <path id="Path_2891" data-name="Path 2891" d="M454.67,626.329S465.4,640.454,494.7,638.835l-8.424-17.685Z" transform="translate(649.442 2726.876)" />
      <path id="Path_2892" data-name="Path 2892" d="M366.963,959.944,390.4,837.362c.455-3.078-1-5.038,2.114-5.038h51.435A6.956,6.956,0,0,1,450.529,838l17.979,122.656h6.611L458.157,837.335c-.776-5.233-7.635-9.495-12.927-9.495H391.239a11.5,11.5,0,0,0-11.014,9.495L356.13,959.944Z" transform="translate(682.048 2658.486)" fill="#acb0b5" />
      <path id="Path_2894" data-name="Path 2894" d="M370.328,960.132H366.3l23.974-123.941a10.006,10.006,0,0,1,9.428-7.782h55.262a9.55,9.55,0,0,1,9.16,7.916l18.34,124.523h-3.995L460.445,836.861a5.786,5.786,0,0,0-5.473-4.731H399.709a6.21,6.21,0,0,0-5.774,4.764Z" transform="translate(678.683 2658.297)" fill="#acb0b5" />
      <g id="Group_1150" data-name="Group 1150" transform="translate(1044.982 3486.707)" opacity="0.5">
        <g id="Group_1149" data-name="Group 1149" opacity="0.5">
          <g id="Group_1148" data-name="Group 1148" opacity="0.5">
            <path id="Path_2895" data-name="Path 2895" d="M370.328,960.132H366.3l23.974-123.941a10.006,10.006,0,0,1,9.428-7.782h55.262a9.55,9.55,0,0,1,9.16,7.916l18.34,124.523h-3.995L460.445,836.861a5.786,5.786,0,0,0-5.473-4.731H399.709a6.21,6.21,0,0,0-5.774,4.764Z" transform="translate(-366.3 -828.41)" opacity="0.5" />
          </g>
        </g>
      </g>
      <path id="Path_2896" data-name="Path 2896" d="M379.933,816.359c-10.171-.676-2.268-23.513-3.794-32.653l-.3-1.84,3.673-.616.3,1.84c2.262,13.57-4.5,29.548.12,29.548,2.161,0,5.3-.1,9.649-.241,11.4-.361,30.492-.977,64.851-.977h1.86v3.72h-1.86c-34.306,0-53.349.609-64.73.977C385.313,816.258,382.155,816.359,379.933,816.359Z" transform="translate(675.923 2673.902)" fill="#fff" />
      <path id="Path_2897" data-name="Path 2897" d="M460.289,815.732H385.321c-5.948,0-5.861-4.992-4.276-13.8a53.982,53.982,0,0,0,.482-18.28l3.673-.616c2.208,13.282-4.563,28.966.12,28.966H460.3v3.727Z" transform="translate(674.108 2673.31)" fill="#80848b" />
      <path id="Path_2898" data-name="Path 2898" d="M553.043,832.307a39.424,39.424,0,0,1-19.813,5.433H434.188c-22.047,0-45.687-1.6-49.207-10.545s151.368-5.708,151.368-5.708,12.211,2.188,13.489,3.151S553.043,832.307,553.043,832.307Z" transform="translate(672.521 2660.777)" fill="#80848b" />
      <path id="Path_2899" data-name="Path 2899" d="M374.477,724.443l-13.148-96c-.248-1.673-8.511-2.362-8.183-3.847,1.184-5.326,6.263-13.777,19.572-13.777h67.092a16.849,16.849,0,0,1,16.119,13.9s14.292,94.446,13.757,96.527-2.449,6.39-5.962,6.39-88.665.636-88.665.636Z" transform="translate(683.039 2730.294)" fill="#acb0b5" />
      <path id="Path_2900" data-name="Path 2900" d="M467.724,826.516H388.7a6.708,6.708,0,1,1,0-13.416h79.023a6.708,6.708,0,1,1,0,13.416Z" transform="translate(673.491 2663.363)" fill="#acb0b5" />
      <path id="Path_2901" data-name="Path 2901" d="M597.169,837.2a5.593,5.593,0,0,1-5.534-4.864c-.114-.529-1.68-6.391-14.593-6.391H508.592a5.713,5.713,0,0,1-5.768-5.219,5.592,5.592,0,0,1,5.58-5.962h68.645c20.615,0,25.6,12.359,25.728,16.681a5.59,5.59,0,0,1-5.266,5.741A3.21,3.21,0,0,1,597.169,837.2Zm-5.594-5.607a4.53,4.53,0,0,0,.054.73c-.013-.1-.027-.2-.033-.3C591.582,831.879,591.575,831.738,591.575,831.6Z" transform="translate(633.514 2662.81)" fill="#afb3b8" />
      <g id="Group_1153" data-name="Group 1153" transform="translate(1141.49 3477.581)" opacity="0.15">
        <g id="Group_1152" data-name="Group 1152" opacity="0.15">
          <g id="Group_1151" data-name="Group 1151" opacity="0.15">
            <path id="Path_2902" data-name="Path 2902" d="M599.724,837.2a5.593,5.593,0,0,1-5.534-4.864c-.114-.529-1.679-6.391-14.593-6.391H516.312a5.713,5.713,0,0,1-5.768-5.219,5.592,5.592,0,0,1,5.58-5.962H579.6c20.615,0,25.6,12.359,25.728,16.681a5.59,5.59,0,0,1-5.266,5.741A3.208,3.208,0,0,1,599.724,837.2ZM594.13,831.6a4.531,4.531,0,0,0,.054.73c-.013-.1-.027-.2-.033-.3C594.137,831.879,594.13,831.738,594.13,831.6Z" transform="translate(-510.531 -814.77)" fill="#80848b" opacity="0.15" />
          </g>
        </g>
      </g>
      <path id="Path_2903" data-name="Path 2903" d="M588.274,815.141H513.881a4.337,4.337,0,0,1-4.249-4.543c.1-8.036,2.984-12.245.368-27.942l-1.05-.79,4.349-.616.3,1.84c2.6,15.61-3.834,28.33.261,28.33h74.412Z" transform="translate(631.482 2673.902)" fill="#acb0b5" />
      <path id="Path_2904" data-name="Path 2904" d="M592.026,816.341H518.1c-9.187,0-.689-16.139-3.881-32.613l3.76-.689c2.262,13.59-3.252,29.575,1.358,29.575h72.686Z" transform="translate(629.918 2673.31)" fill="#80848b" />
      <path id="Path_2905" data-name="Path 2905" d="M603.618,958.021,585.679,830.48a5.745,5.745,0,0,0-5.447-4.724H524.97a5.757,5.757,0,0,0-5.447,4.724l-17.9,127.541H497.63l18.207-128.063a9.512,9.512,0,0,1,9.133-7.929h55.262a9.511,9.511,0,0,1,9.133,7.929l18.207,128.063Z" transform="translate(635.229 2660.408)" fill="#acb0b5" />
      <path id="Path_2906" data-name="Path 2906" d="M498.11,958.545,515.6,830.984c.455-3.078-1-6.169,2.114-6.169l51.435,1.131a6.956,6.956,0,0,1,6.584,5.674l17.892,126.209H600.2L583.348,830.957c-.776-5.232-7.822-9.294-13.121-9.294l-53.79-.194a11.5,11.5,0,0,0-11.014,9.488L487.31,958.538Z" transform="translate(638.643 2660.594)" fill="#acb0b5" />
      <g id="Group_1156" data-name="Group 1156" transform="translate(1132.744 3482.438)" opacity="0.5">
        <g id="Group_1155" data-name="Group 1155" opacity="0.5">
          <g id="Group_1154" data-name="Group 1154" opacity="0.5">
            <path id="Path_2907" data-name="Path 2907" d="M603.562,958.021,585.623,830.48a5.745,5.745,0,0,0-5.447-4.724H524.914a5.757,5.757,0,0,0-5.447,4.724l-18,128.25H497.46l18.314-128.772a9.512,9.512,0,0,1,9.133-7.929h55.262a9.511,9.511,0,0,1,9.133,7.929L607.51,958.021Z" transform="translate(-497.46 -822.029)" opacity="0.5" />
          </g>
        </g>
      </g>
      <path id="Path_2908" data-name="Path 2908" d="M369.3,726.278l-13.155-96c-.248-1.673-5.955-2.362-5.627-3.847,1.184-5.326,11.576-10.063,17.618-10.063h67.092a16.843,16.843,0,0,1,16.119,13.911l12.941,98.942-3.834.9H369.878Z" transform="translate(683.911 2728.458)" fill="#80848b" />
      <path id="Path_2909" data-name="Path 2909" d="M368.141,616.369h67.092a16.76,16.76,0,0,1,13.208,6.986,1.765,1.765,0,0,1-.85-.6c-1.914-2.128-3.62,2.342-3.62,4.048,0,1.345-60.361,7.086-86.958,9.321l-.863-5.848c-.248-1.673-5.955-2.362-5.627-3.847C351.7,621.106,362.1,616.369,368.141,616.369Z" transform="translate(683.908 2728.458)" fill="#80848b" />
      <path id="Path_2910" data-name="Path 2910" d="M361.181,732.016,348.026,636a11.8,11.8,0,0,1,12-13.9h70.291A16.843,16.843,0,0,1,446.433,636l13.155,96s-20.876,5.326-54.1,5.326S361.181,732.016,361.181,732.016Z" transform="translate(684.779 2726.562)" fill="#acb0b5" />
      <g id="Group_1159" data-name="Group 1159" transform="translate(1058.466 3489.651)" opacity="0.5">
        <g id="Group_1158" data-name="Group 1158" opacity="0.5">
          <g id="Group_1157" data-name="Group 1157" opacity="0.5">
            <path id="Path_2911" data-name="Path 2911" d="M472.532,832.809H386.45l1.318,1.385H472.15Z" transform="translate(-386.45 -832.809)" opacity="0.5" />
          </g>
        </g>
      </g>
      <g id="Group_1162" data-name="Group 1162" transform="translate(1109.613 3498.51)" opacity="0.5">
        <g id="Group_1161" data-name="Group 1161" opacity="0.5">
          <g id="Group_1160" data-name="Group 1160" opacity="0.5">
            <path id="Path_2912" data-name="Path 2912" d="M486.142,846.049H462.89v4.657h23.934Z" transform="translate(-462.89 -846.049)" opacity="0.5" />
          </g>
        </g>
      </g>
      <g id="Group_1165" data-name="Group 1165" transform="translate(1153.032 3498.51)" opacity="0.5">
        <g id="Group_1164" data-name="Group 1164" opacity="0.5">
          <g id="Group_1163" data-name="Group 1163" opacity="0.5">
            <path id="Path_2913" data-name="Path 2913" d="M539.777,846.049H528.422l-.642,4.657h12Z" transform="translate(-527.78 -846.049)" opacity="0.5" />
          </g>
        </g>
      </g>
      <path id="Path_2914" data-name="Path 2914" d="M533.935,553.667s-7.032.288-8.264-6.664c-1.224-7.012,7.421-7.969,8.377-.923l.468,7.655Z" transform="translate(625.988 2753.315)" fill="#f59696" />
      <path id="Path_2915" data-name="Path 2915" d="M659.86,622.653l.83-9.916a.89.89,0,0,1,.676-.79,1.6,1.6,0,0,1,1.981,1.633l-.207,3.968Z" transform="translate(581.549 2729.937)" fill="#fc9d9d" />
      <path id="Path_2916" data-name="Path 2916" d="M659.86,622.653l.83-9.916a.89.89,0,0,1,.676-.79,1.6,1.6,0,0,1,1.981,1.633l-.207,3.968Z" transform="translate(581.549 2729.937)" opacity="0.15" />
      <path id="Path_2917" data-name="Path 2917" d="M663.91,608.734l4.436-8.324a1.266,1.266,0,0,1,2.282,1.091l-3.968,9.234,4.53-7.441a1.124,1.124,0,0,1,1.954,1.111l-4.356,8.244,3.934-4.911a.775.775,0,0,1,1.03-.194.762.762,0,0,1,.161,1.01l-3.767,7.387-.549,3.667a13.873,13.873,0,0,1-5.079,8.792l-.147.12-6.751,9.856-6.33-10.124,3.64-3.4,4.724-15.657,4.992-10a1.139,1.139,0,0,1,2.1.863Z" transform="translate(584.385 2734.347)" fill="#fc9d9d" />
      <path id="Path_2918" data-name="Path 2918" d="M567,659.979l13.081,31.6a12.377,12.377,0,0,0,20.194,1.639l33.115-47.293-8.571-6.591-27.655,27.521-9.816-29.428Z" transform="translate(612.274 2721.489)" fill="#3f3d56" />
      <path id="Path_2919" data-name="Path 2919" d="M570.83,680.379l7.233,17.892a16.485,16.485,0,0,1-2.737,4.028Z" transform="translate(611.008 2707.278)" opacity="0.2" />
      <g id="Group_1166" data-name="Group 1166" transform="translate(1268.744 3474.972)" opacity="0.15">
        <path id="Path_2920" data-name="Path 2920" d="M700.938,814.65a.227.227,0,0,1-.074-.442,15.145,15.145,0,0,0,5.059-3.272.227.227,0,0,1,.321.321,15.558,15.558,0,0,1-5.239,3.386A.186.186,0,0,1,700.938,814.65Z" transform="translate(-700.712 -810.871)" opacity="0.15" />
      </g>
      <g id="Group_1167" data-name="Group 1167" transform="translate(1269.507 3481.02)" opacity="0.15">
        <path id="Path_2921" data-name="Path 2921" d="M702.078,821.475a.223.223,0,0,1-.221-.181.234.234,0,0,1,.174-.274,11.566,11.566,0,0,0,2.509-1.077.227.227,0,0,1,.315.087.223.223,0,0,1-.087.308,12.106,12.106,0,0,1-2.636,1.124A.09.09,0,0,1,702.078,821.475Z" transform="translate(-701.852 -819.91)" opacity="0.15" />
      </g>
      <path id="Path_2922" data-name="Path 2922" d="M537.624,543.546l-.214-1,18.963-6.069.214,1Z" transform="translate(622.065 2754.892)" fill="#fff" />
      <path id="Path_2923" data-name="Path 2923" d="M566.111,540.261a.511.511,0,0,1-.408-.368l-2.563-9.033a.5.5,0,0,1,.355-.629.518.518,0,0,1,.636.355l2.563,9.033a.513.513,0,0,1-.355.636A.655.655,0,0,1,566.111,540.261Z" transform="translate(613.559 2756.966)" fill="#fff" />
      <path id="Path_2924" data-name="Path 2924" d="M537.15,543.891a14.836,14.836,0,0,1,.836,4.168.866.866,0,0,0,1.726-.134l-.448-6.155Z" transform="translate(622.151 2753.142)" />
      <g id="Group_1169" data-name="Group 1169" transform="translate(1153.154 3295.761)" opacity="0.3">
        <g id="Group_1168" data-name="Group 1168" opacity="0.3">
          <path id="Path_2925" data-name="Path 2925" d="M528.068,543.916a2.138,2.138,0,0,1,3.433-.027,4.78,4.78,0,0,1,1.144,2.181l.227.729a.4.4,0,0,1-.776.187l-.227-.729a4.618,4.618,0,0,0-.943-1.813,1.357,1.357,0,0,0-2.268.02.4.4,0,0,1-.589-.549Z" transform="translate(-527.963 -543.039)" opacity="0.3" />
        </g>
      </g>
      <path id="Path_2926" data-name="Path 2926" d="M532.265,555.036a1.388,1.388,0,1,1,1.211,1.546A1.386,1.386,0,0,1,532.265,555.036Z" transform="translate(623.771 2749.156)" fill="#4d61fc" />
      <g id="Group_1172" data-name="Group 1172" transform="translate(1156.028 3303.255)" opacity="0.15">
        <g id="Group_1171" data-name="Group 1171" opacity="0.15">
          <g id="Group_1170" data-name="Group 1170" opacity="0.15">
            <path id="Path_2927" data-name="Path 2927" d="M534.113,556.589a1.506,1.506,0,0,1-1.479-1.579,1.587,1.587,0,0,1,.294-.77,1.542,1.542,0,0,0-.669,1.117,1.369,1.369,0,0,0,1.365,1.432,1.216,1.216,0,0,0,.83-.234C534.32,556.548,534.186,556.528,534.113,556.589Z" transform="translate(-532.258 -554.24)" opacity="0.15" />
          </g>
        </g>
      </g>
      <path id="Path_2928" data-name="Path 2928" d="M533.6,559.109s1.278.321,1.713.388,1.853.147,1.853.147-1.793,2.422-3.573,2.924C533.6,562.568,533.5,560.073,533.6,559.109Z" transform="translate(623.342 2747.404)" opacity="0.15" />
      <path id="Path_2939" data-name="Path 2939" d="M1028.6,906.421l-13.456-.321,3.673,80.608h19.565l3.673-80.608Z" transform="translate(463.992 2632.591)" fill="#c2ae88" />
      <path id="Path_2940" data-name="Path 2940" d="M1015.14,906.1l3.673,80.608h19.565l3.673-80.608Z" transform="translate(463.992 2632.591)" fill="#83878e" />
      <path id="Path_2941" data-name="Path 2941" d="M1044.872,846.455s-.91-9.575,6.343-15.47,18.474-3.854,18.474-3.854S1065.956,847.077,1044.872,846.455Z" transform="translate(454.167 2658.833)" fill="#97be40" />
      <path id="Path_2942" data-name="Path 2942" d="M1044.872,846.455s-.91-9.575,6.343-15.47,18.474-3.854,18.474-3.854S1065.956,847.077,1044.872,846.455Z" transform="translate(454.167 2658.833)" fill="#3e5a01" opacity="0.76" />
      <path id="Path_2943" data-name="Path 2943" d="M1044.89,846.467s8.043-14.9,24.818-19.324C1069.708,827.143,1045.3,822.774,1044.89,846.467Z" transform="translate(454.148 2658.821)" fill="#97be40" />
      <path id="Path_2944" data-name="Path 2944" d="M1042.283,823.315s-3.894-1.231-5.447-7.829,1.786-13.857,1.786-13.857,5.808,4.67,6.276,12.091S1042.283,823.315,1042.283,823.315Z" transform="translate(456.95 2667.158)" fill="#97be40" />
      <path id="Path_2945" data-name="Path 2945" d="M1016.913,866.911s-4.343.83-8.9-4.416-4.664-13.536-4.664-13.536,7.782,1.352,11.669,7.9S1016.913,866.911,1016.913,866.911Z" transform="translate(467.894 2651.498)" fill="#c6de39" />
      <path id="Path_2946" data-name="Path 2946" d="M1030.778,869.088l-1.278-70.13,1.278-4.878,1.164,75.008Z" transform="translate(459.241 2669.656)" fill="#21252b" />
      <path id="Path_2947" data-name="Path 2947" d="M1025.067,890.271l-2.743-32.171-1.1.749L1024,890.666Z" transform="translate(461.98 2648.473)" fill="#21252b" />
      <path id="Path_2948" data-name="Path 2948" d="M1043.41,879.139l1.184-54.52,1.191.776-1.051,53.743Z" transform="translate(454.639 2659.552)" fill="#21252b" />
      <path id="Path_2949" data-name="Path 2949" d="M1038.1,868.039a22.751,22.751,0,0,1-8.371-11.482c-2.59-7.835-2.355-12.707-2.355-12.707s9.395,4.2,11.93,11.127S1038.1,868.039,1038.1,868.039Z" transform="translate(459.945 2653.188)" fill="#c6de39" />
      <path id="Path_2950" data-name="Path 2950" d="M1029.6,796.677a15.309,15.309,0,0,1-.1-10.324c1.961-5.56,11.509-8.752,11.509-8.752S1037.3,790.608,1029.6,796.677Z" transform="translate(459.521 2675.109)" fill="#7bb200" />
      <path id="Path_2951" data-name="Path 2951" d="M1029.516,796.673s-.241-6.27,2.536-11.208,8.872-7.875,8.872-7.875S1023.948,781.785,1029.516,796.673Z" transform="translate(459.606 2675.113)" fill="#c6de39" />
      <path id="Path_2952" data-name="Path 2952" d="M1027.38,843.85s8.966,7.635,11.549,24.262C1038.929,868.105,1047.152,854.161,1027.38,843.85Z" transform="translate(459.942 2653.188)" fill="#c6de39" />
      <path id="Path_2954" data-name="Path 2954" d="M1016.913,866.911s-4.343.83-8.9-4.416-4.664-13.536-4.664-13.536,7.782,1.352,11.669,7.9S1016.913,866.911,1016.913,866.911Z" transform="translate(467.894 2651.498)" fill="#7bb200" opacity="0.3" />
      <path id="Path_2957" data-name="Path 2957" d="M1024.97,889.343s-2.5-8.284,2.837-14.767,15.6-6.684,15.6-6.684S1043.665,886.145,1024.97,889.343Z" transform="translate(460.934 2645.234)" fill="#97be40" />
      <path id="Path_2958" data-name="Path 2958" d="M1024.97,889.343s-2.5-8.284,2.837-14.767,15.6-6.684,15.6-6.684S1043.665,886.145,1024.97,889.343Z" transform="translate(460.934 2645.234)" fill="#3e5a01" opacity="0.67" />
      <path id="Path_2959" data-name="Path 2959" d="M1025.01,889.345s1.479-10.947,18.441-21.445C1043.451,867.9,1020.882,868.148,1025.01,889.345Z" transform="translate(460.894 2645.23)" fill="#7bb200" />
      <path id="Path_2960" data-name="Path 2960" d="M1044.96,822.928a15.289,15.289,0,0,1-3.72-9.629c-.114-5.895,7.708-12.238,7.708-12.238S1050.045,814.537,1044.96,822.928Z" transform="translate(455.357 2667.346)" fill="#3e5a01" />
      <path id="Path_2961" data-name="Path 2961" d="M1044.689,822.924s-4.965-12.807,3.988-21.873C1048.684,801.051,1033.14,811.248,1044.689,822.924Z" transform="translate(455.628 2667.35)" fill="#c6de39" />
      <path id="Path_2962" data-name="Path 2962" d="M1045.724,822.924a21.3,21.3,0,0,1-2.175-9.481c-.114-5.895,6.162-12.392,6.162-12.392S1050.809,814.534,1045.724,822.924Z" transform="translate(454.593 2667.35)" fill="#3e5a01" opacity="0.3" />
      <path id="Path_2963" data-name="Path 2963" d="M1028.119,832s-4.068-.341-7.046-6.437-1.312-13.911-1.312-13.911,6.691,3.272,8.786,10.411S1028.119,832,1028.119,832Z" transform="translate(462.616 2663.843)" fill="#97be40" />
      <path id="Path_2965" data-name="Path 2965" d="M1019.945,858.021a15.279,15.279,0,0,1-4.764-9.16c-.769-5.841,6.3-13.021,6.3-13.021S1024.053,849.122,1019.945,858.021Z" transform="translate(463.998 2655.839)" fill="#3e5a01" />
      <path id="Path_2966" data-name="Path 2966" d="M1019.945,858.021a15.279,15.279,0,0,1-4.764-9.16c-.769-5.841,6.3-13.021,6.3-13.021S1024.053,849.122,1019.945,858.021Z" transform="translate(463.998 2655.839)" fill="#3e5a01" opacity="0.3" />
      <path id="Path_2967" data-name="Path 2967" d="M1019.929,858.021s-5.065-10.231,1.532-22.181C1021.461,835.84,1008.173,846.82,1019.929,858.021Z" transform="translate(464.015 2655.839)" fill="#c6de39" />
      <path id="Path_2968" data-name="Path 2968" d="M1049.976,906.1l-7.916.054-2.911,80.555h7.153Z" transform="translate(456.048 2632.591)" fill="#61656d" />
      <path id="Path_2969" data-name="Path 2969" d="M1232.855,590.859H1138.63V882.4h94.225Z" transform="translate(423.132 2736.899)" fill="#afb3b8" />
      <path id="Path_2970" data-name="Path 2970" d="M1199.255,792.859H1105.03V949.246h94.225Z" transform="translate(434.249 2670.06)" fill="#e6ede9" />
      <path id="Path_2971" data-name="Path 2971" d="M1199.255,590.859H1105.03V882.4h94.225Z" transform="translate(434.249 2736.899)" fill="#d7dadc" />
      <g id="Group_1173" data-name="Group 1173" transform="translate(1543.943 3471.257)" opacity="0.15">
        <path id="Path_2972" data-name="Path 2972" d="M1196.9,865.139H1112V805.32h84.911v59.819Zm-83.9-1h82.9V806.324H1113Z" transform="translate(-1112 -805.32)" opacity="0.15" />
      </g>
      <path id="Path_2973" data-name="Path 2973" d="M1178.864,821.4h-16.728a1.645,1.645,0,0,1-1.646-1.646V817.1a1.645,1.645,0,0,1,1.646-1.646h16.728a1.645,1.645,0,0,1,1.646,1.646v2.656A1.645,1.645,0,0,1,1178.864,821.4Z" transform="translate(415.898 2662.585)" fill="#afb3b8" />
      <g id="Group_1174" data-name="Group 1174" transform="translate(1543.943 3538.169)" opacity="0.15">
        <path id="Path_2974" data-name="Path 2974" d="M1196.9,965.139H1112V905.32h84.911v59.819Zm-83.9-1h82.9V906.324H1113Z" transform="translate(-1112 -905.32)" opacity="0.15" />
      </g>
      <path id="Path_2975" data-name="Path 2975" d="M1178.864,921.4h-16.728a1.645,1.645,0,0,1-1.646-1.646V917.1a1.645,1.645,0,0,1,1.646-1.646h16.728a1.645,1.645,0,0,1,1.646,1.646v2.656A1.645,1.645,0,0,1,1178.864,921.4Z" transform="translate(415.898 2629.497)" fill="#afb3b8" />
      <g id="Group_1175" data-name="Group 1175" transform="translate(1543.943 3404.346)" opacity="0.15">
        <path id="Path_2976" data-name="Path 2976" d="M1196.9,765.139H1112V705.32h84.911v59.819Zm-83.9-1h82.9V706.324H1113Z" transform="translate(-1112 -705.32)" opacity="0.15" />
      </g>
      <path id="Path_2977" data-name="Path 2977" d="M1178.864,721.4h-16.728a1.645,1.645,0,0,1-1.646-1.646V717.1a1.645,1.645,0,0,1,1.646-1.646h16.728a1.645,1.645,0,0,1,1.646,1.646v2.656A1.645,1.645,0,0,1,1178.864,721.4Z" transform="translate(415.898 2695.673)" fill="#afb3b8" />
      <g id="Group_1176" data-name="Group 1176" transform="translate(1543.943 3337.434)" opacity="0.15">
        <path id="Path_2978" data-name="Path 2978" d="M1196.9,665.139H1112V605.32h84.911v59.819Zm-83.9-1h82.9V606.324H1113Z" transform="translate(-1112 -605.32)" opacity="0.15" />
      </g>
      <path id="Path_2979" data-name="Path 2979" d="M1178.864,621.4h-16.728a1.645,1.645,0,0,1-1.646-1.646V617.1a1.645,1.645,0,0,1,1.646-1.646h16.728a1.645,1.645,0,0,1,1.646,1.646v2.656A1.645,1.645,0,0,1,1178.864,621.4Z" transform="translate(415.898 2728.762)" fill="#afb3b8" />
      <path id="Path_2984" data-name="Path 2984" d="M1159.113,570.74H1126.52V584.2h32.593Z" transform="translate(427.139 2743.556)" fill="#d7dadc" />
      <path id="Path_2985" data-name="Path 2985" d="M1126.51,570.74h77.765V584.2h-45.165V572.961h-32.6Z" transform="translate(427.142 2743.556)" fill="#afb3b8" />
      <path id="Path_2986" data-name="Path 2986" d="M1126.51,570.74h77.765V584.2h-45.165V572.961h-32.6Z" transform="translate(427.142 2743.556)" fill="#afb3b8" />
      <path id="Path_2987" data-name="Path 2987" d="M1159.113,587.541H1126.52v2.221h32.593Z" transform="translate(427.139 2737.997)" fill="#afb3b8" />
      <path id="Path_2988" data-name="Path 2988" d="M1159.113,587.541H1126.52v2.221h32.593Z" transform="translate(427.139 2737.997)" fill="#afb3b8" />
      <path id="Path_2989" data-name="Path 2989" d="M1205.593,577.73H1186.53v4.818h19.063Z" transform="translate(407.282 2741.243)" fill="#d7dadc" />
      <path id="Path_2990" data-name="Path 2990" d="M1222.13,578.834a3.975,3.975,0,1,1,3.974,3.975A3.975,3.975,0,0,1,1222.13,578.834Zm1.358,0a2.616,2.616,0,1,0,2.616-2.616A2.62,2.62,0,0,0,1223.488,578.834Z" transform="translate(395.503 2742.193)" fill="#d7dadc" />
      <path id="Path_2991" data-name="Path 2991" d="M1170.659,553.891H1144.41v11.275h26.249Z" transform="translate(421.219 2749.131)" fill="#d7dadc" />
      <path id="Path_2992" data-name="Path 2992" d="M1144.41,553.891h62.623v11.275h-36.373v-9.488H1144.41Z" transform="translate(421.219 2749.131)" fill="#afb3b8" />
      <path id="Path_2993" data-name="Path 2993" d="M1171.195,567.42H1144.41v2.221h26.785Z" transform="translate(421.219 2744.655)" fill="#afb3b8" />
      <path id="Path_2994" data-name="Path 2994" d="M1144.41,553.891h62.623v11.275h-36.373v-9.488H1144.41Z" transform="translate(421.219 2749.131)" fill="#afb3b8" />
      <path id="Path_2995" data-name="Path 2995" d="M1170.659,567.42H1144.41v2.221h26.249Z" transform="translate(421.219 2744.655)" fill="#afb3b8" />
      <path id="Path_2996" data-name="Path 2996" d="M1208.1,559.521H1192.75V563.4H1208.1Z" transform="translate(405.225 2747.268)" fill="#d7dadc" />
      <path id="Path_2997" data-name="Path 2997" d="M1221.42,560.409a3.2,3.2,0,1,1,3.2,3.2A3.2,3.2,0,0,1,1221.42,560.409Zm1.091,0a2.108,2.108,0,1,0,2.108-2.108A2.107,2.107,0,0,0,1222.511,560.409Z" transform="translate(395.738 2748.032)" fill="#d7dadc" />
      <path id="Path_2998" data-name="Path 2998" d="M1207.033,569.67H1144.41v.716h62.623Z" transform="translate(421.219 2743.91)" opacity="0.15" />
      <path id="Path_2999" data-name="Path 2999" d="M1014.017,428.552H898.568a2.958,2.958,0,0,1-2.958-2.957V353.557a2.958,2.958,0,0,1,2.958-2.957h115.449a2.958,2.958,0,0,1,2.958,2.957v72.037A2.958,2.958,0,0,1,1014.017,428.552Z" transform="translate(503.543 2816.396)" fill="#3e5a01" />
      <path id="Path_3000" data-name="Path 3000" d="M898.544,350.59h115.483a2.944,2.944,0,0,1,2.944,2.944v4.7H895.6v-4.7A2.944,2.944,0,0,1,898.544,350.59Z" transform="translate(503.546 2816.4)" />
      <path id="Path_3001" data-name="Path 3001" d="M1014.017,428.552H898.568a2.958,2.958,0,0,1-2.958-2.957V353.557a2.958,2.958,0,0,1,2.958-2.957h115.449a2.958,2.958,0,0,1,2.958,2.957v72.037A2.958,2.958,0,0,1,1014.017,428.552Z" transform="translate(503.543 2816.396)" fill="#fff" opacity="0.2" />
      <path id="Path_3002" data-name="Path 3002" d="M1010.217,428.529H894.767a2.958,2.958,0,0,1-2.957-2.957V353.527a2.958,2.958,0,0,1,2.957-2.957h115.449a2.958,2.958,0,0,1,2.957,2.957v72.037A2.964,2.964,0,0,1,1010.217,428.529Z" transform="translate(504.801 2816.406)" fill="#e9e9e9" />
      <g id="Group_1179" data-name="Group 1179" transform="translate(1396.61 3166.977)" opacity="0.6">
        <g id="Group_1178" data-name="Group 1178" transform="translate(0 0)" opacity="0.6">
          <g id="Group_1177" data-name="Group 1177" opacity="0.6">
            <path id="Path_3003" data-name="Path 3003" d="M1010.217,428.529H894.767a2.958,2.958,0,0,1-2.957-2.957V353.527a2.958,2.958,0,0,1,2.957-2.957h115.449a2.969,2.969,0,0,1,2.957,3v72.037A2.955,2.955,0,0,1,1010.217,428.529Z" transform="translate(-891.81 -350.57)" fill="#fff" opacity="0.6" />
          </g>
        </g>
      </g>
      <path id="Path_3004" data-name="Path 3004" d="M894.744,350.57h115.483a2.944,2.944,0,0,1,2.944,2.944v4.7H891.8v-4.7A2.944,2.944,0,0,1,894.744,350.57Z" transform="translate(504.804 2816.406)" fill="#3f4249" />
      <path id="Path_3005" data-name="Path 3005" d="M900.143,355.651a1.251,1.251,0,1,1-1.251-1.251A1.254,1.254,0,0,1,900.143,355.651Z" transform="translate(502.871 2815.139)" />
      <path id="Path_3006" data-name="Path 3006" d="M906.453,355.651A1.251,1.251,0,1,1,905.2,354.4,1.254,1.254,0,0,1,906.453,355.651Z" transform="translate(500.783 2815.139)" />
      <path id="Path_3007" data-name="Path 3007" d="M912.772,355.651a1.251,1.251,0,1,1-1.251-1.251A1.254,1.254,0,0,1,912.772,355.651Z" transform="translate(498.692 2815.139)" />
      <path id="Path_3008" data-name="Path 3008" d="M899.311,356.473a.4.4,0,1,0-.4-.4A.4.4,0,0,0,899.311,356.473Z" transform="translate(502.451 2814.719)" fill="#fff" />
      <path id="Path_3009" data-name="Path 3009" d="M909.58,384.817v-4.256h22.248v4.256Z" transform="translate(498.921 2806.483)" fill="#3e5a01" fill-rule="evenodd" />
      <path id="Path_3010" data-name="Path 3010" d="M909.58,394.147v-4.256h10.137v4.256Z" transform="translate(498.921 2803.396)" fill="#3e5a01" fill-rule="evenodd" />
      <path id="Path_3011" data-name="Path 3011" d="M909.58,403.477v-4.256h7.139v4.256Z" transform="translate(498.921 2800.309)" fill="#3e5a01" fill-rule="evenodd" />
      <path id="Path_3012" data-name="Path 3012" d="M909.58,375.486V371.23h4.751v4.256Z" transform="translate(498.921 2809.571)" fill="#3e5a01" fill-rule="evenodd" />
      <path id="Path_3013" data-name="Path 3013" d="M935.908,395.447H906.32V369.3a.315.315,0,0,1,.629,0v25.513h28.959a.314.314,0,0,1,0,.629Z" transform="translate(499.999 2810.312)" fill="#3f4249" />
      <path id="Path_3014" data-name="Path 3014" d="M1025.181,447.724l.121-31.428a5.886,5.886,0,0,0-5.393-5.888L998.7,408.641a.185.185,0,0,1-.007.06l-8.451,2.7c-3.018.937-5.962,4.684-5.774,8.792l1.405,27.534Z" transform="translate(474.145 2797.192)" fill="#f16a03" />
      <path id="Path_3015" data-name="Path 3015" d="M1008.729,404.553c-.1-3.312,2.469-6.631,5.754-6.725s6.042,3.065,6.136,6.377c.034,1.2-1.117.877-1.68,2.2-.984,2.328-2.422,6.116-4.517,6.176-2.068.06-2.7-3.352-3.827-5.58C1009.926,405.683,1008.762,405.777,1008.729,404.553Z" transform="translate(466.115 2800.77)" fill="#f69797" />
      <path id="Path_3016" data-name="Path 3016" d="M1003.83,384.963a3.01,3.01,0,1,1,.234-6.015c.107.007.335-.074.308.027C1003.689,381.625,1004.479,384.984,1003.83,384.963Z" transform="translate(468.692 2807.025)" fill="#21252b" />
      <path id="Path_3017" data-name="Path 3017" d="M1008.15,385.434s10.418-6.972,11.074,1.158l.221,2.1,1.044,10.646c.007.314-12.031.769-11.991.375l.488-6.343a8,8,0,0,1-.261-1.867C1008.518,388.311,1008.15,385.434,1008.15,385.434Z" transform="translate(466.306 2805.791)" fill="#f69797" />
      <path id="Path_3018" data-name="Path 3018" d="M1005.919,375.29s-3.413,17.631,4.731,18.461c7.052.729,7.508-10.244,7.508-10.244l-.549-7.093-5.467-1.994Z" transform="translate(467.278 2808.515)" fill="#f69797" />
      <path id="Path_3019" data-name="Path 3019" d="M1013.956,372.492a4.542,4.542,0,0,0-1.934.147.493.493,0,0,1-.616-.361,3,3,0,0,0-5.326-1.051.5.5,0,0,1-.729.094,4.5,4.5,0,0,0-3.607-1.138,4.543,4.543,0,1,0,4.871,3.078,23.694,23.694,0,0,1,4.189-.04c-.027.02-.054.033-.08.054l2.717.923s.85,7.26,1.291,7.113a4.546,4.546,0,0,0-.776-8.819Z" transform="translate(469.74 2809.974)" fill="#21252b" />
      <path id="Path_3020" data-name="Path 3020" d="M1009.484,378.4a4.906,4.906,0,0,1,3.66-2.255,3.277,3.277,0,0,1,3.433,3.5l-.033.408a.737.737,0,0,0,.662.79.735.735,0,0,1,.669.7l.067,1.954a.281.281,0,0,0,.562.013l.12-1.358-1.325-7.474-6.229-1.646-2.589,2.215Z" transform="translate(466.196 2808.975)" fill="#21252b" />
      <path id="Path_3021" data-name="Path 3021" d="M1023.472,385.559a1.458,1.458,0,0,1,1.967-1.064c1.853.555,1.994,4.577-.943,5.179-1.532.328-1.338-.616-1.231-1.867Z" transform="translate(461.327 2805.211)" fill="#f69797" />
      <path id="Path_3022" data-name="Path 3022" d="M1026.033,386a1.17,1.17,0,0,0-.883-.127,1.038,1.038,0,0,0-.6.629,2.134,2.134,0,0,0-.147,1.117l.013.341a.182.182,0,0,0,.361-.04l-.013-.341a2.158,2.158,0,0,1,.12-.93.859.859,0,0,1,.388-.442.877.877,0,0,1,.589.08.205.205,0,0,0,.254-.087C1026.16,386.165,1026.12,386.064,1026.033,386Z" transform="translate(460.935 2804.74)" opacity="0.3" />
      <path id="Path_3023" data-name="Path 3023" d="M1024.521,392.361a1.793,1.793,0,0,1-1.051-.2l1.2,1.733Z" transform="translate(461.236 2802.645)" opacity="0.15" />
      <path id="Path_3024" data-name="Path 3024" d="M1004.953,383.924l.823-.154a.175.175,0,0,0,.134-.2.16.16,0,0,0-.2-.134l-.823.154a1.227,1.227,0,0,1-.315.033,4.109,4.109,0,0,1-1.057-.181.167.167,0,0,0-.094.321,4.043,4.043,0,0,0,1.138.187C1004.685,383.951,1004.819,383.938,1004.953,383.924Z" transform="translate(467.91 2805.534)" fill="#f69797" />
      <path id="Path_3025" data-name="Path 3025" d="M1008.87,403.663l.2-2.529a5.338,5.338,0,0,0,4.818,1.017h0a5.362,5.362,0,0,0,2.784-1.853.167.167,0,1,1,.268.2,5.843,5.843,0,0,1-3.687,2.114A5.779,5.779,0,0,1,1008.87,403.663Z" transform="translate(466.067 2799.974)" opacity="0.15" />
      <path id="Path_3026" data-name="Path 3026" d="M1010.86,395.858a6.1,6.1,0,0,0,2.027-.455,4.218,4.218,0,0,0,1.325-.883,1.585,1.585,0,0,1-.9,1.954A1.943,1.943,0,0,1,1010.86,395.858Z" transform="translate(465.408 2801.864)" fill="#fff" />
      <path id="Path_3027" data-name="Path 3027" d="M1014.819,389.378a.649.649,0,1,0-.649-.649A.647.647,0,0,0,1014.819,389.378Z" transform="translate(464.313 2803.995)" fill="#21252b" />
      <path id="Path_3028" data-name="Path 3028" d="M1014.637,388.815a.127.127,0,1,0-.127-.127A.13.13,0,0,0,1014.637,388.815Z" transform="translate(464.201 2803.836)" fill="#fff" />
      <path id="Path_3029" data-name="Path 3029" d="M1007.119,389.648a.649.649,0,1,0-.649-.649A.648.648,0,0,0,1007.119,389.648Z" transform="translate(466.861 2803.906)" fill="#21252b" />
      <path id="Path_3030" data-name="Path 3030" d="M1006.947,389.084a.127.127,0,1,0-.127-.127A.129.129,0,0,0,1006.947,389.084Z" transform="translate(466.745 2803.747)" fill="#fff" />
      <g id="Group_1180" data-name="Group 1180" transform="translate(1475.58 3194.999)" opacity="0.3">
        <path id="Path_3031" data-name="Path 3031" d="M1010.579,394.009l.863.027a.194.194,0,1,0,.013-.388l-.863-.027a.392.392,0,1,1,.033-.783.208.208,0,0,0,.2-.187c.02-.107-.08-.181-.187-.2a.78.78,0,1,0-.06,1.559Z" transform="translate(-1009.83 -392.449)" opacity="0.3" />
      </g>
      <path id="Path_3032" data-name="Path 3032" d="M1003.25,387.7l.02.729a2.55,2.55,0,0,0,5.1-.147l-.02-.729a.907.907,0,0,0-.2-.569l.99-.027a.933.933,0,0,0-.194.575l.02.729a2.55,2.55,0,1,0,5.1-.147l-.02-.729a.932.932,0,0,0-.964-.91l-3.2.094h-.027l-2.469.074-.388.013-2.837.08A.935.935,0,0,0,1003.25,387.7Zm6.591-.736h.027l3.2-.094a.574.574,0,0,1,.589.555l.02.729a2.172,2.172,0,0,1-4.343.1l-.02-.729A.546.546,0,0,1,1009.841,386.967Zm-6.25.756a.574.574,0,0,1,.555-.589l2.837-.08.388-.013a.574.574,0,0,1,.589.555l.02.729a2.172,2.172,0,1,1-4.343.1Z" transform="translate(467.927 2804.525)" fill="#21252b" />
      <path id="Path_3033" data-name="Path 3033" d="M929.391,392.91s-1.238.348-.482,2.63,5.058,6.129,5.058,6.129l1.492-6.912Z" transform="translate(492.605 2802.397)" fill="#f69797" />
      <path id="Path_3034" data-name="Path 3034" d="M939.922,394.285l-.6-3.232a2.78,2.78,0,0,0-.227-.689l-2.155-4.456s-.569-.12-.783.609a9.4,9.4,0,0,0-.147,2.221l-4.021-7.354a.683.683,0,0,0-1.224.6l1.706,3.961s-1.526-.676-1.566,1.224c0,0-1.693.555-.93,2.837s4.363,7.728,4.363,7.728h2.884Z" transform="translate(492.238 2806.327)" fill="#f69797" />
      <g id="Group_1182" data-name="Group 1182" transform="translate(1423.15 3192.355)" opacity="0.15">
        <g id="Group_1181" data-name="Group 1181" transform="translate(0)" opacity="0.15">
          <path id="Path_3035" data-name="Path 3035" d="M933.037,388.5a8,8,0,0,1,.8,1.271c.241.435.5.91.79,1.419a3.145,3.145,0,0,1,.388.923,1.136,1.136,0,0,1-.04.575.839.839,0,0,1-.4.468,1.165,1.165,0,0,1-1.131-.094,2.124,2.124,0,0,1-.662-.776c-.288-.555-.515-1.044-.716-1.465a7.6,7.6,0,0,1-.582-1.385,7.217,7.217,0,0,1,.77,1.285q.351.652.763,1.425a1.915,1.915,0,0,0,.582.662.888.888,0,0,0,.836.074.654.654,0,0,0,.288-.729,2.956,2.956,0,0,0-.348-.843c-.261-.522-.508-1.01-.729-1.452A6.689,6.689,0,0,1,933.037,388.5Z" transform="translate(-931.475 -388.498)" opacity="0.15" />
        </g>
      </g>
      <g id="Group_1184" data-name="Group 1184" transform="translate(1422.053 3195.313)" opacity="0.15">
        <g id="Group_1183" data-name="Group 1183" transform="translate(0)" opacity="0.15">
          <path id="Path_3036" data-name="Path 3036" d="M931.966,393.241c.214.616.462,1.224.7,1.833a1.083,1.083,0,0,1,.08.288.59.59,0,0,1-.261.656.808.808,0,0,1-.749-.067,1.725,1.725,0,0,1-.522-.6c-.248-.462-.475-.89-.689-1.278a6.9,6.9,0,0,0-.689-1.158,6.544,6.544,0,0,0,.522,1.245c.181.375.381.816.642,1.318a1.94,1.94,0,0,0,.6.7,1.049,1.049,0,0,0,1.017.087.722.722,0,0,0,.361-.422,1.045,1.045,0,0,0,.033-.515,3.037,3.037,0,0,0-.348-.83l-.187-.348s-.174-.254-.214-.315c-.094-.161-.3-.589-.3-.589Z" transform="translate(-929.835 -392.92)" opacity="0.15" />
        </g>
      </g>
      <g id="Group_1186" data-name="Group 1186" transform="translate(1421.52 3196.899)" opacity="0.15">
        <g id="Group_1185" data-name="Group 1185" opacity="0.15">
          <path id="Path_3037" data-name="Path 3037" d="M931.481,397.036a.775.775,0,0,1,.027.12.825.825,0,0,1-.027.4.588.588,0,0,1-.281.328.8.8,0,0,1-.79-.067,1.464,1.464,0,0,1-.462-.542c-.2-.388-.361-.729-.5-1.024a5.869,5.869,0,0,1-.408-.964,5.365,5.365,0,0,1,.535.9c.161.3.341.636.535,1a1.357,1.357,0,0,0,.408.462.624.624,0,0,0,.582.054.458.458,0,0,0,.2-.509,1.7,1.7,0,0,0-.114-.341,1.771,1.771,0,0,0,.154.114C931.388,396.983,931.481,397.036,931.481,397.036Z" transform="translate(-929.037 -395.289)" opacity="0.15" />
        </g>
      </g>
      <g id="Group_1188" data-name="Group 1188" transform="translate(1427.633 3194.838)" opacity="0.15">
        <g id="Group_1187" data-name="Group 1187" opacity="0.15">
          <path id="Path_3038" data-name="Path 3038" d="M938.553,397.1c-.02.02-.234-.221-.334-.716a2.491,2.491,0,0,1,.388-1.86,2.227,2.227,0,0,1,.5-.529l-.047.134a8.181,8.181,0,0,1-.321-1.92,8.489,8.489,0,0,1,.575,1.86l.02.08-.067.053a2.3,2.3,0,0,0-.435.475,2.559,2.559,0,0,0-.422,1.68C938.452,396.812,938.6,397.08,938.553,397.1Z" transform="translate(-938.174 -392.209)" opacity="0.15" />
        </g>
      </g>
      <path id="Path_3039" data-name="Path 3039" d="M948.786,426.664,936.26,404.094l5.875-3.285,11.8,14.587,17.517-8.19a8.891,8.891,0,0,1,3.8-.836l9.261.054-1.6,9.9-25.159,12.118A6.426,6.426,0,0,1,948.786,426.664Z" transform="translate(490.093 2799.783)" fill="#f16a03" />
      <path id="Path_3040" data-name="Path 3040" d="M1007.27,402l-.147,1.947a.773.773,0,0,0,.328.736l2.73,2.208a1.136,1.136,0,0,1,.388.575l1.813,6.531.314,8.344-5.032-11.683.535-2.422-1.854,4.242-2.71-5.366a1.183,1.183,0,0,1,.147-1.291Z" transform="translate(467.841 2799.389)" fill="#fff" />
      <path id="Path_3041" data-name="Path 3041" d="M990.138,450.083l18.173-13.436a.947.947,0,0,1,1.345.221l14.065,20.087h-29.7l-4.095-5.534A.965.965,0,0,1,990.138,450.083Z" transform="translate(472.394 2787.987)" fill="#3f3d56" />
      <path id="Path_3042" data-name="Path 3042" d="M993.177,453.16a.651.651,0,0,1,.134-.91l16.46-12.171a.654.654,0,0,1,.917.147l12.533,17.865H996.83Z" transform="translate(471.303 2786.831)" fill="#fff" />
      <path id="Path_3043" data-name="Path 3043" d="M993.177,453.16a.651.651,0,0,1,.134-.91l16.46-12.171a.654.654,0,0,1,.917.147l12.533,17.865H996.83Z" transform="translate(471.303 2786.831)" fill="#fff" />
      <path id="Path_3044" data-name="Path 3044" d="M1031.484,465.624l-.254.5-.241-.5-.549-.08.4-.388-.087-.549.489.268.5-.254-.1.542.395.395Z" transform="translate(458.93 2778.676)" fill="#61656d" opacity="0.3" />
      <path id="Path_3045" data-name="Path 3045" d="M1016.993,452.208l-12.706,9.067a.427.427,0,0,1-.5-.7l12.707-9.067a.428.428,0,0,1,.6.1A.433.433,0,0,1,1016.993,452.208Z" transform="translate(467.807 2783.032)" fill="#61656d" opacity="0.3" />
      <path id="Path_3046" data-name="Path 3046" d="M1015.013,449.5l-12.706,9.067a.427.427,0,0,1-.5-.7l12.707-9.067a.427.427,0,1,1,.5.7Z" transform="translate(468.462 2783.929)" fill="#61656d" opacity="0.3" />
      <path id="Path_3047" data-name="Path 3047" d="M1019.833,454.926l-10.886,7.769a.427.427,0,0,1-.5-.7l10.887-7.769a.427.427,0,0,1,.5.7Z" transform="translate(466.265 2782.133)" fill="#61656d" opacity="0.3" />
      <path id="Path_3048" data-name="Path 3048" d="M1027.61,467.057h-.074l-.007-.047Z" transform="translate(459.893 2777.878)" fill="#61656d" opacity="0.3" />
      <path id="Path_3049" data-name="Path 3049" d="M1022.078,456.961a.427.427,0,1,1,.5.7l-8.478,6.049h-1.465Z" transform="translate(464.823 2781.229)" fill="#61656d" opacity="0.3" />
      <path id="Path_3050" data-name="Path 3050" d="M1028.967,467.029l-.007.033h-.06Z" transform="translate(459.439 2777.872)" fill="#61656d" opacity="0.3" />
      <path id="Path_3051" data-name="Path 3051" d="M1025.874,459.783a.428.428,0,0,1-.1.6l-5.928,4.229h-1.465l6.9-4.925A.427.427,0,0,1,1025.874,459.783Z" transform="translate(462.921 2780.329)" fill="#61656d" opacity="0.3" />
      <g id="Group_1189" data-name="Group 1189" transform="translate(1468.886 3229.657)" opacity="0.4">
        <path id="Path_3052" data-name="Path 3052" d="M1000.049,449.937a1.152,1.152,0,0,1,.241-1.606l5.219-3.861a1.146,1.146,0,1,1,1.365,1.84l-5.219,3.861A1.144,1.144,0,0,1,1000.049,449.937Z" transform="translate(-999.826 -444.246)" opacity="0.4" />
      </g>
      <path id="Path_3053" data-name="Path 3053" d="M1000.049,449.937a1.152,1.152,0,0,1,.241-1.606l5.219-3.861a1.146,1.146,0,1,1,1.365,1.84l-5.219,3.861A1.144,1.144,0,0,1,1000.049,449.937Z" transform="translate(469.06 2785.411)" fill="#3f3d56" />
      <path id="Path_3054" data-name="Path 3054" d="M1040.646,416.51a9.487,9.487,0,0,0-7.9-5.687l-9.856-.863,5.447,14.915,5.233,12.626-18.133,10.672,19.3-.02,9.281-3.734a5.608,5.608,0,0,0,3.218-6.872Z" transform="translate(463.894 2796.756)" fill="#f16a03" />
      <path id="Path_3055" data-name="Path 3055" d="M1023.1,401.689l.167,1.726a1.19,1.19,0,0,1-.442.923l-3.071,2.449a1.136,1.136,0,0,0-.388.575l-1.847,6.531-.274,8.344,5.032-11.683-.535-2.422,1.853,4.242,2.71-5.366a1.183,1.183,0,0,0-.147-1.291Z" transform="translate(463.294 2799.492)" fill="#fff" />
      <path id="Path_3056" data-name="Path 3056" d="M1038.93,445.612l-4.624-11.141,2.308,10.746-4.985,2.576,1.05,1.5Z" transform="translate(458.536 2788.645)" opacity="0.2" />
      <path id="Path_3057" data-name="Path 3057" d="M922.668,420.42a11.961,11.961,0,0,1,1.907.154v3.767a8.544,8.544,0,0,0-1.907-.221,8.413,8.413,0,0,0-8.411,8.411,8.664,8.664,0,0,0,.167,1.679H910.67a12.638,12.638,0,0,1-.12-1.679A12.122,12.122,0,0,1,922.668,420.42Z" transform="translate(498.6 2793.294)" fill="#3f3d56" />
      <path id="Path_3058" data-name="Path 3058" d="M938.024,432.607a8.41,8.41,0,0,0-6.5-8.19V420.65a12.124,12.124,0,0,1,9.769,15.189h-3.908A8.54,8.54,0,0,0,938.024,432.607Z" transform="translate(491.661 2793.218)" fill="#7bb200" />
      <path id="Path_3060" data-name="Path 3060" d="M914.484,441.031a8.414,8.414,0,0,0,8.243,6.731c.134,0,.261-.007.395-.007v3.7c-.127.007-.261.007-.395.007a12.121,12.121,0,0,1-12-10.432Z" transform="translate(498.54 2786.474)" fill="#e0ebc8" opacity="0.7" />
      <path id="Path_3061" data-name="Path 3061" d="M936.634,443.34h3.908a12.111,12.111,0,0,1-11.281,8.879v-3.7A8.421,8.421,0,0,0,936.634,443.34Z" transform="translate(492.409 2785.71)" fill="#3f3d56" />
      <path id="Path_3062" data-name="Path 3062" d="M729.967,537.029H614.517a2.958,2.958,0,0,1-2.957-2.958V462.027a2.958,2.958,0,0,1,2.957-2.957H729.967a2.958,2.958,0,0,1,2.957,2.957v72.037A2.96,2.96,0,0,1,729.967,537.029Z" transform="translate(597.53 2780.506)" fill="#3e5a01" />
      <path id="Path_3063" data-name="Path 3063" d="M614.494,459.07H729.977a2.944,2.944,0,0,1,2.944,2.944v4.7H611.55v-4.7A2.944,2.944,0,0,1,614.494,459.07Z" transform="translate(597.534 2780.506)" />
      <path id="Path_3064" data-name="Path 3064" d="M729.967,537.029H614.517a2.958,2.958,0,0,1-2.957-2.958V462.027a2.958,2.958,0,0,1,2.957-2.957H729.967a2.958,2.958,0,0,1,2.957,2.957v72.037A2.96,2.96,0,0,1,729.967,537.029Z" transform="translate(597.53 2780.506)" fill="#fff" opacity="0.2" />
      <path id="Path_3065" data-name="Path 3065" d="M726.4,537.029H610.947a2.958,2.958,0,0,1-2.957-2.958V462.027a2.958,2.958,0,0,1,2.957-2.957H726.4a2.958,2.958,0,0,1,2.958,2.957v72.037A2.951,2.951,0,0,1,726.4,537.029Z" transform="translate(598.712 2780.506)" fill="#e9e9e9" />
      <path id="Path_3067" data-name="Path 3067" d="M610.934,459.07H726.417a2.944,2.944,0,0,1,2.944,2.944v4.7H607.99v-4.7A2.944,2.944,0,0,1,610.934,459.07Z" transform="translate(598.712 2780.506)" fill="#3f4249" />
      <path id="Path_3068" data-name="Path 3068" d="M616.323,464.161a1.251,1.251,0,1,1-1.251-1.251A1.241,1.241,0,0,1,616.323,464.161Z" transform="translate(596.782 2779.235)" />
      <path id="Path_3069" data-name="Path 3069" d="M622.643,464.161a1.251,1.251,0,1,1-1.251-1.251A1.254,1.254,0,0,1,622.643,464.161Z" transform="translate(594.691 2779.235)" />
      <path id="Path_3070" data-name="Path 3070" d="M628.953,464.161a1.251,1.251,0,1,1-1.251-1.251A1.254,1.254,0,0,1,628.953,464.161Z" transform="translate(592.604 2779.235)" />
      <path id="Path_3071" data-name="Path 3071" d="M615.491,464.983a.4.4,0,1,0-.4-.4A.4.4,0,0,0,615.491,464.983Z" transform="translate(596.362 2778.815)" fill="#fff" />
      <path id="Path_3072" data-name="Path 3072" d="M651.079,529.043l-4.256-4.235a.5.5,0,0,0-.656-.04l-.013.007a1.22,1.22,0,0,0-.3,1.6l.95,1.559Z" transform="translate(586.242 2758.802)" fill="#906363" />
      <g id="Group_1195" data-name="Group 1195" transform="translate(1231.918 3283.466)" opacity="0.15">
        <g id="Group_1194" data-name="Group 1194" opacity="0.15">
          <g id="Group_1193" data-name="Group 1193" opacity="0.15">
            <path id="Path_3073" data-name="Path 3073" d="M651.079,529.043l-4.256-4.235a.5.5,0,0,0-.656-.04l-.013.007a1.22,1.22,0,0,0-.3,1.6l.95,1.559Z" transform="translate(-645.676 -524.664)" opacity="0.15" />
          </g>
        </g>
      </g>
      <path id="Path_3074" data-name="Path 3074" d="M635.059,522.333a.544.544,0,0,1,.622.381l.943,3.125a.567.567,0,0,0,.5.4l6.678.562a3.326,3.326,0,0,1,2.937,2.476l.77,2.9-5.36,2.041-.569-1.867a.84.84,0,0,0-.636-.582l-4.59-.99a2.242,2.242,0,0,1-1.338-.87l-4.483-6.042a.52.52,0,0,1,.12-.736.532.532,0,0,1,.7.067l1.981,2.134-1.084-3.439a.554.554,0,0,1,1.037-.381l2.068,4.8L634.625,523A.563.563,0,0,1,635.059,522.333Z" transform="translate(591.287 2759.953)" fill="#906363" />
      <g id="Group_1198" data-name="Group 1198" transform="translate(1224.212 3284.868)" opacity="0.15">
        <g id="Group_1197" data-name="Group 1197" opacity="0.15">
          <g id="Group_1196" data-name="Group 1196" opacity="0.15">
            <path id="Path_3075" data-name="Path 3075" d="M634.7,528.339a.171.171,0,0,1-.161-.12l-.368-1.245a.169.169,0,0,1,.114-.207.159.159,0,0,1,.2.114l.368,1.245a.169.169,0,0,1-.114.207A.236.236,0,0,1,634.7,528.339Z" transform="translate(-634.16 -526.76)" opacity="0.15" />
          </g>
        </g>
      </g>
      <path id="Path_3076" data-name="Path 3076" d="M710.616,494.731s4.838,2.509,3.078,9.127c-.662,2.482,1.746,3.051,2.978,4.262,3.2,3.152.87,9.16-.984,9.067l-23.995,9.682s-7.541-13.115-3.928-18.682c1.606-2.476,1.3-2.844.669-5.453-.97-4,1.86-5.54,1.86-5.54l1.425-.141,6.29-10.813,9.02,7.648,1.459.214Z" transform="translate(572.64 2771.516)" />
      <path id="Path_3077" data-name="Path 3077" d="M695.7,517.264l-3.667,9.348c2.215,3.319,12.76,1.94,15.577-2.208L711,516.521l-.783-.214a1.785,1.785,0,0,1-1.332-1.68l-.743-8.752-9.261-1.245-.308,7.16-.154,3.56a1.8,1.8,0,0,1-1.312,1.532Z" transform="translate(570.904 2765.43)" fill="#906363" />
      <path id="Path_3078" data-name="Path 3078" d="M702.634,509.944l.488.013a6.345,6.345,0,0,0,6.537-6.142l.274-8.9a2.12,2.12,0,0,0-2.047-2.181l-8.3-.254a2.745,2.745,0,0,0-2.83,2.662l-.254,8.264A6.34,6.34,0,0,0,702.634,509.944Z" transform="translate(569.427 2769.451)" fill="#906363" />
      <path id="Path_3079" data-name="Path 3079" d="M705.449,503.882a.687.687,0,1,0,.729-.642A.68.68,0,0,0,705.449,503.882Z" transform="translate(566.465 2765.891)" fill="#21252b" />
      <path id="Path_3080" data-name="Path 3080" d="M705.66,504.381a.151.151,0,1,0,.161-.141A.155.155,0,0,0,705.66,504.381Z" transform="translate(566.395 2765.56)" fill="#fff" />
      <path id="Path_3081" data-name="Path 3081" d="M697.519,503.761a.687.687,0,1,0,.729-.642A.688.688,0,0,0,697.519,503.761Z" transform="translate(569.089 2765.931)" fill="#21252b" />
      <path id="Path_3082" data-name="Path 3082" d="M697.8,504.3a.151.151,0,1,0,.161-.141A.16.16,0,0,0,697.8,504.3Z" transform="translate(568.995 2765.586)" fill="#fff" />
      <g id="Group_1201" data-name="Group 1201" transform="translate(1269.042 3272.059)" opacity="0.3">
        <g id="Group_1200" data-name="Group 1200" opacity="0.3">
          <g id="Group_1199" data-name="Group 1199" opacity="0.3">
            <path id="Path_3083" data-name="Path 3083" d="M701.922,509.258l.93.06a.2.2,0,0,0,.214-.187.2.2,0,0,0-.187-.214l-.93-.06a.413.413,0,0,1-.388-.442.419.419,0,0,1,.442-.395.2.2,0,0,0,.027-.4.821.821,0,0,0-.107,1.639Z" transform="translate(-701.158 -507.617)" opacity="0.3" />
          </g>
        </g>
      </g>
      <path id="Path_3084" data-name="Path 3084" d="M692.03,495.86a1.967,1.967,0,0,0,.616-2.054v.033c-.923-7.213,4.048-11.228,8.2-11.6a8.979,8.979,0,0,1,9.675,7.983,3.574,3.574,0,0,0,1.827,3.185s-1.472,3.58-1.907,4.891c-.187.562-1.251-1.746-1.726-2.007a5.345,5.345,0,0,0-.79,1.017.387.387,0,0,1-.683-.067l-.033-.114a9.385,9.385,0,0,1-.455-3.192,3.916,3.916,0,0,1-2.569-4.041,7.387,7.387,0,0,1-9.026,3.68L695.1,496.9a8.154,8.154,0,0,1-1.178-1.526l-1.044.756Z" transform="translate(570.904 2772.849)" />
      <path id="Path_3085" data-name="Path 3085" d="M715.3,507.418a3.486,3.486,0,0,0,3.861-2.489c.857-2.944-2.81-3.76-3.553-.79l-.642,3.252Z" transform="translate(563.313 2766.21)" fill="#906363" />
      <g id="Group_1204" data-name="Group 1204" transform="translate(1279.526 3269.169)" opacity="0.3">
        <g id="Group_1203" data-name="Group 1203" transform="translate(0)" opacity="0.3">
          <g id="Group_1202" data-name="Group 1202" opacity="0.3">
            <path id="Path_3086" data-name="Path 3086" d="M718.964,503.614a1.046,1.046,0,0,0-1.666.221,2.324,2.324,0,0,0-.428,1.138l-.04.321c-.033.234.375.341.4.06l.047-.348a2.305,2.305,0,0,1,.335-.943.776.776,0,0,1,.509-.368.816.816,0,0,1,.6.227.193.193,0,0,0,.274-.033A.206.206,0,0,0,718.964,503.614Z" transform="translate(-716.827 -503.297)" opacity="0.3" />
          </g>
        </g>
      </g>
      <path id="Path_3087" data-name="Path 3087" d="M693.807,503.645a2.756,2.756,0,1,0,5.5.361,2.728,2.728,0,0,0-.314-1.452,2.183,2.183,0,0,1,1.365.007,2.628,2.628,0,0,0-.214.9,2.765,2.765,0,1,0,.375-1.211,2.528,2.528,0,0,0-1.706.007,2.775,2.775,0,0,0-2.074-1.171A2.746,2.746,0,0,0,693.807,503.645Zm6.684-.181a2.4,2.4,0,1,1,2.242,2.556A2.4,2.4,0,0,1,700.491,503.464Zm-6.33.2a2.4,2.4,0,1,1,2.242,2.556A2.4,2.4,0,0,1,694.162,503.665Z" transform="translate(570.318 2766.67)" fill="#fff" />
      <path id="Path_3088" data-name="Path 3088" d="M715.9,508.112a.6.6,0,1,0,.642-.563A.605.605,0,0,0,715.9,508.112Z" transform="translate(563.007 2764.465)" fill="#fff" />
      <g id="Group_1207" data-name="Group 1207" transform="translate(1279.127 3272.148)" opacity="0.15">
        <g id="Group_1206" data-name="Group 1206" transform="translate(0)" opacity="0.15">
          <g id="Group_1205" data-name="Group 1205" opacity="0.15">
            <path id="Path_3089" data-name="Path 3089" d="M716.37,508.714a.657.657,0,0,0,.7-.609.645.645,0,0,0-.08-.355.6.6,0,1,1-.756.937A.522.522,0,0,0,716.37,508.714Z" transform="translate(-716.23 -507.75)" opacity="0.15" />
          </g>
        </g>
      </g>
      <g id="Group_1210" data-name="Group 1210" transform="translate(1278.23 3273.647)" opacity="0.15">
        <g id="Group_1209" data-name="Group 1209" opacity="0.15">
          <g id="Group_1208" data-name="Group 1208" opacity="0.15">
            <path id="Path_3090" data-name="Path 3090" d="M715.874,509.99h-.984l1.117,1.954Z" transform="translate(-714.89 -509.99)" opacity="0.15" />
          </g>
        </g>
      </g>
      <g id="Group_1213" data-name="Group 1213" transform="translate(1269.331 3278.209)" opacity="0.15">
        <g id="Group_1212" data-name="Group 1212" opacity="0.15">
          <g id="Group_1211" data-name="Group 1211" opacity="0.15">
            <path id="Path_3091" data-name="Path 3091" d="M708.6,517.163a7.483,7.483,0,0,1-2.9.97,5.56,5.56,0,0,1-3.319,1.178,2.049,2.049,0,0,1-.79-.114l.08-1.88.181.074a9.361,9.361,0,0,0,3.352.415c.1-.007.194-.013.288-.027a.229.229,0,0,0,.067-.007c.154-.02.3-.04.442-.067.06-.013.12-.027.174-.033a7.017,7.017,0,0,0,2.235-.836.182.182,0,0,1,.254.067A.191.191,0,0,1,708.6,517.163Z" transform="translate(-701.59 -516.808)" opacity="0.15" />
          </g>
        </g>
      </g>
      <path id="Path_3092" data-name="Path 3092" d="M701.492,511.681a.118.118,0,0,0-.1.181,1.953,1.953,0,0,0,3.533-.047.118.118,0,0,0-.107-.174Z" transform="translate(567.813 2763.111)" fill="#fff" />
      <path id="Path_3093" data-name="Path 3093" d="M697.483,498.772h.02a8.391,8.391,0,0,0,7.762-4,.216.216,0,1,0-.375-.214,7.968,7.968,0,0,1-7.347,3.787.212.212,0,0,0-.234.194A.209.209,0,0,0,697.483,498.772Z" transform="translate(569.158 2768.8)" />
      <path id="Path_3094" data-name="Path 3094" d="M677.289,535.345l1.974-7.032,2.5-5.092-4.41.984a6.27,6.27,0,0,0-4.035,2.917l-14.339,24.055-5.795-18.842-5.808,1.586,3.881,24.336H667.3l3.58-4.349-.321,4.349h10.4Z" transform="translate(585.678 2759.279)" fill="#21252b" />
      <g id="Group_1214" data-name="Group 1214" transform="translate(1249.157 3307.108)" opacity="0.2">
        <path id="Path_3095" data-name="Path 3095" d="M678.834,566.069l.515-5.594a.434.434,0,0,0-.79-.288l-7.119,10.231h3.807Z" transform="translate(-671.44 -559.998)" opacity="0.2" />
      </g>
      <path id="Path_3096" data-name="Path 3096" d="M712.505,523.442l-4.537-1.352c-2.054,7.26-8.859,11.168-13.282,10.117-5.025-1.2,0-9.555,0-9.555l-.843.227-7.862,13.891a10.566,10.566,0,0,0-.207,9.241l1.392,3.038a5.291,5.291,0,0,1,.468,2.6l-.448,6.229h20.85Z" transform="translate(573.293 2759.653)" fill="#fff" />
      <path id="Path_3097" data-name="Path 3097" d="M732.708,532.045a8.669,8.669,0,0,0-5.935-6.865l-8.692-2.75s-4.543,12.927-5.7,35.564h13.289l.348-5.875,1.265,5.875h9.662Z" transform="translate(564.171 2759.541)" fill="#21252b" />
      <g id="Group_1215" data-name="Group 1215" transform="translate(1290.181 3300.181)" opacity="0.2">
        <path id="Path_3098" data-name="Path 3098" d="M733.613,549.776a.137.137,0,0,0-.274,0l-.589,11.348L734.015,567h.7Z" transform="translate(-732.75 -549.645)" opacity="0.2" />
      </g>
      <g id="Group_1217" data-name="Group 1217" transform="translate(1244.66 3303.717)" opacity="0.2">
        <g id="Group_1216" data-name="Group 1216" transform="translate(0)" opacity="0.2">
          <path id="Path_3099" data-name="Path 3099" d="M664.72,561.668l.154,3.7,3.861-10.438Z" transform="translate(-664.72 -554.93)" opacity="0.2" />
        </g>
      </g>
      <path id="Path_3100" data-name="Path 3100" d="M656.106,489.2a11.536,11.536,0,1,0-11.536-11.536A11.536,11.536,0,0,0,656.106,489.2Z" transform="translate(586.608 2778.169)" fill="#c7c7c7" />
      <path id="Path_3101" data-name="Path 3101" d="M669.03,496.548l7.307,7.434-6.089-8.531Z" transform="translate(578.515 2768.468)" fill="#c7c7c7" />
      <path id="Path_3102" data-name="Path 3102" d="M653.737,483.875a1,1,0,1,0-1-1A1,1,0,0,0,653.737,483.875Z" transform="translate(583.904 2772.958)" fill="#fff" />
      <path id="Path_3103" data-name="Path 3103" d="M661.317,483.875a1,1,0,1,0-1-1A1,1,0,0,0,661.317,483.875Z" transform="translate(581.396 2772.958)" fill="#fff" />
      <path id="Path_3104" data-name="Path 3104" d="M668.9,483.875a1,1,0,1,0-1-1A1,1,0,0,0,668.9,483.875Z" transform="translate(578.889 2772.958)" fill="#fff" />
      <path id="Path_3105" data-name="Path 3105" d="M797.607,361.988H682.157a2.958,2.958,0,0,1-2.958-2.957V286.987a2.958,2.958,0,0,1,2.958-2.958H797.607a2.958,2.958,0,0,1,2.958,2.958v72.037A2.951,2.951,0,0,1,797.607,361.988Z" transform="translate(575.149 2838.424)" fill="#3e5a01" />
      <path id="Path_3106" data-name="Path 3106" d="M682.144,284.029H797.627a2.944,2.944,0,0,1,2.944,2.944v4.7H679.2v-4.7A2.944,2.944,0,0,1,682.144,284.029Z" transform="translate(575.149 2838.424)" />
      <path id="Path_3107" data-name="Path 3107" d="M797.607,361.988H682.157a2.958,2.958,0,0,1-2.958-2.957V286.987a2.958,2.958,0,0,1,2.958-2.958H797.607a2.958,2.958,0,0,1,2.958,2.958v72.037A2.951,2.951,0,0,1,797.607,361.988Z" transform="translate(575.149 2838.424)" fill="#fff" opacity="0.2" />
      <path id="Path_3108" data-name="Path 3108" d="M794.027,361.988H678.577a2.958,2.958,0,0,1-2.957-2.957V286.987a2.958,2.958,0,0,1,2.957-2.958H794.027a2.958,2.958,0,0,1,2.957,2.958v72.037A2.951,2.951,0,0,1,794.027,361.988Z" transform="translate(576.334 2838.424)" fill="#e9e9e9" />
      <g id="Group_1220" data-name="Group 1220" transform="translate(1251.954 3122.453)" opacity="0.6">
        <g id="Group_1219" data-name="Group 1219" transform="translate(0 0)" opacity="0.6">
          <g id="Group_1218" data-name="Group 1218" opacity="0.6">
            <path id="Path_3109" data-name="Path 3109" d="M794.027,361.988H678.577a2.958,2.958,0,0,1-2.957-2.957V286.987a2.958,2.958,0,0,1,2.957-2.958H794.027a2.969,2.969,0,0,1,2.957,3V359.07A2.941,2.941,0,0,1,794.027,361.988Z" transform="translate(-675.62 -284.029)" fill="#fff" opacity="0.6" />
          </g>
        </g>
      </g>
      <path id="Path_3110" data-name="Path 3110" d="M678.564,284.029H794.047a2.944,2.944,0,0,1,2.944,2.944v4.7H675.62v-4.7A2.944,2.944,0,0,1,678.564,284.029Z" transform="translate(576.334 2838.424)" fill="#3f4249" />
      <path id="Path_3111" data-name="Path 3111" d="M683.953,289.12a1.251,1.251,0,1,1-1.251-1.251A1.241,1.241,0,0,1,683.953,289.12Z" transform="translate(574.405 2837.153)" />
      <path id="Path_3112" data-name="Path 3112" d="M690.273,289.12a1.251,1.251,0,1,1-1.251-1.251A1.254,1.254,0,0,1,690.273,289.12Z" transform="translate(572.313 2837.153)" />
      <path id="Path_3113" data-name="Path 3113" d="M696.583,289.12a1.251,1.251,0,1,1-1.251-1.251A1.254,1.254,0,0,1,696.583,289.12Z" transform="translate(570.226 2837.153)" />
      <path id="Path_3114" data-name="Path 3114" d="M683.121,289.942a.4.4,0,1,0-.4-.4A.4.4,0,0,0,683.121,289.942Z" transform="translate(573.984 2836.733)" fill="#fff" />
      <path id="Path_3115" data-name="Path 3115" d="M803.154,375.568l-2.589-7.421,10.987-5.319V349.553a6.674,6.674,0,0,0-5.647-6.591l-31.428-3.727a6.371,6.371,0,0,0-6.417,3.078l-9.669,16.5,7.748,2.3.435,19.17h36.587Z" transform="translate(548.947 2820.179)" fill="#fc5f4d" />
      <path id="Path_3116" data-name="Path 3116" d="M768.9,360.552a.212.212,0,0,0,.134-.054l1.178-1.1a.194.194,0,0,0-.268-.281l-1.178,1.1a.194.194,0,0,0,.134.335Z" transform="translate(545.532 2813.598)" fill="#fff" />
      <path id="Path_3117" data-name="Path 3117" d="M770.068,360.574a.212.212,0,0,0,.134-.053.192.192,0,0,0,.007-.274l-1.1-1.178a.194.194,0,0,0-.281.268l1.1,1.178A.209.209,0,0,0,770.068,360.574Z" transform="translate(545.513 2813.615)" fill="#fff" />
      <path id="Path_3118" data-name="Path 3118" d="M789.144,350.851a.212.212,0,0,0,.134-.054l1.178-1.1a.194.194,0,1,0-.268-.281l-1.178,1.1a.192.192,0,0,0-.007.274A.182.182,0,0,0,789.144,350.851Z" transform="translate(538.836 2816.808)" fill="#fff" />
      <path id="Path_3119" data-name="Path 3119" d="M790.3,350.873a.212.212,0,0,0,.134-.054.192.192,0,0,0,.007-.274l-1.1-1.178a.194.194,0,0,0-.281.268l1.1,1.178A.193.193,0,0,0,790.3,350.873Z" transform="translate(538.819 2816.824)" fill="#fff" />
      <path id="Path_3120" data-name="Path 3120" d="M805.07,358.487a1.419,1.419,0,1,1,1.419,1.419A1.419,1.419,0,0,1,805.07,358.487Zm.388,0a1.037,1.037,0,1,0,1.037-1.037A1.038,1.038,0,0,0,805.458,358.487Z" transform="translate(533.501 2814.256)" fill="#fff" />
      <path id="Path_3121" data-name="Path 3121" d="M826.407,363.3a.194.194,0,0,0,.194-.194,1.03,1.03,0,1,1,2.061,0,.194.194,0,1,0,.388,0,1.415,1.415,0,1,0-2.83,0A.185.185,0,0,0,826.407,363.3Z" transform="translate(526.503 2812.727)" fill="#fff" />
      <path id="Path_3122" data-name="Path 3122" d="M829.113,354.041l2.623-.89a.195.195,0,1,0-.127-.368l-2.623.89a.194.194,0,0,0-.12.248A.2.2,0,0,0,829.113,354.041Z" transform="translate(525.631 2815.677)" fill="#fff" />
      <path id="Path_3123" data-name="Path 3123" d="M791.474,359.277h2.77a.194.194,0,1,0,0-.388h-2.77a.194.194,0,0,0,0,.388Z" transform="translate(538.064 2813.654)" fill="#fff" />
      <path id="Path_3124" data-name="Path 3124" d="M781.487,345.083a.191.191,0,0,0,.134-.328l-1.954-1.954a.194.194,0,1,0-.274.274l1.954,1.954A.216.216,0,0,0,781.487,345.083Z" transform="translate(542.016 2818.995)" fill="#fff" />
      <path id="Path_3125" data-name="Path 3125" d="M802.67,360.1a1.265,1.265,0,1,1,1.265,1.265A1.268,1.268,0,0,1,802.67,360.1Zm.074,0a1.191,1.191,0,1,0,1.191-1.191A1.194,1.194,0,0,0,802.744,360.1Z" transform="translate(534.295 2813.671)" fill="#fff" />
      <path id="Path_3126" data-name="Path 3126" d="M823.81,364.724h.08a1.184,1.184,0,1,1,2.369,0h.08a1.265,1.265,0,0,0-2.529,0Z" transform="translate(527.301 2812.142)" fill="#fff" />
      <path id="Path_3127" data-name="Path 3127" d="M829.673,355.333l-2.624.888.025.076,2.624-.888Z" transform="translate(526.229 2814.831)" fill="#fff" />
      <path id="Path_3128" data-name="Path 3128" d="M791.71,360.668h-2.77v.08h2.77Z" transform="translate(538.838 2813.065)" fill="#fff" />
      <path id="Path_3129" data-name="Path 3129" d="M776.987,344.534l-.057.057,1.959,1.959.056-.057Z" transform="translate(542.813 2818.404)" fill="#fff" />
      <path id="Path_3130" data-name="Path 3130" d="M773.143,388.232a.668.668,0,0,1-.3-.422.681.681,0,0,1,.087-.509.668.668,0,0,1,.422-.3.681.681,0,0,1,.509.087.669.669,0,0,1,.3.422.681.681,0,0,1-.087.509.668.668,0,0,1-.422.3.607.607,0,0,1-.154.02A.733.733,0,0,1,773.143,388.232Zm.294-.856a.293.293,0,0,0-.181.127.315.315,0,0,0-.04.221.293.293,0,0,0,.127.181.316.316,0,0,0,.221.04.293.293,0,0,0,.181-.127.315.315,0,0,0,.04-.221.293.293,0,0,0-.127-.181.319.319,0,0,0-.154-.047A.206.206,0,0,0,773.437,387.375Z" transform="translate(544.171 2804.358)" fill="#fff" />
      <path id="Path_3131" data-name="Path 3131" d="M820.533,351.221a.669.669,0,0,1-.3-.422.681.681,0,0,1,.087-.509.669.669,0,0,1,.422-.3.681.681,0,0,1,.509.087.669.669,0,0,1,.3.422.681.681,0,0,1-.087.509.668.668,0,0,1-.422.3.607.607,0,0,1-.154.02A.733.733,0,0,1,820.533,351.221Zm.294-.856a.294.294,0,0,0-.181.127.316.316,0,0,0-.04.221.293.293,0,0,0,.127.181.315.315,0,0,0,.221.04.293.293,0,0,0,.181-.127.315.315,0,0,0,.04-.221.294.294,0,0,0-.127-.181.318.318,0,0,0-.154-.047A.206.206,0,0,0,820.827,350.364Z" transform="translate(528.49 2816.604)" fill="#fff" />
      <path id="Path_3132" data-name="Path 3132" d="M775.573,353.5a.669.669,0,0,1-.3-.422.681.681,0,0,1,.087-.509.669.669,0,0,1,.422-.3.681.681,0,0,1,.509.087.669.669,0,0,1,.3.422.681.681,0,0,1-.087.509.666.666,0,0,1-.575.315A.714.714,0,0,1,775.573,353.5Zm.3-.856a.294.294,0,0,0-.181.127.316.316,0,0,0-.04.221.293.293,0,0,0,.127.181.288.288,0,0,0,.4-.094.316.316,0,0,0,.04-.221.294.294,0,0,0-.127-.181.318.318,0,0,0-.154-.047C775.914,352.639,775.894,352.639,775.874,352.645Z" transform="translate(543.366 2815.849)" fill="#fff" />
      <path id="Path_3133" data-name="Path 3133" d="M797.273,320.622s10.679-6.7,11.1,1.492l.154,2.121.723,10.612.007.12a1.231,1.231,0,0,1-.288.81l-6.176,4.215a.5.5,0,0,1-.6-.027l-4.583-3.9a1.225,1.225,0,0,1-.422-1.071l.167-1.559.515-4.8a7.981,7.981,0,0,1-.207-1.887C797.561,323.533,797.273,320.622,797.273,320.622Z" transform="translate(536.112 2827.17)" fill="#fa9b9b" />
      <path id="Path_3134" data-name="Path 3134" d="M794.123,319.076a3.613,3.613,0,1,0-3.613-3.613A3.614,3.614,0,0,0,794.123,319.076Z" transform="translate(538.318 2829.218)" fill="#3f4249" />
      <path id="Path_3135" data-name="Path 3135" d="M795.085,313.142s-3.794,15.918,4.369,16.989c7.066.943,7.855-10.077,7.855-10.077l-.341-7.146Z" transform="translate(537.097 2828.868)" fill="#fa9b9b" />
      <path id="Path_3136" data-name="Path 3136" d="M795.016,310.591l7.226.522a1.676,1.676,0,0,1,1.519,1.753,1.638,1.638,0,0,0,1.519,1.753.482.482,0,0,1,.422.468l-.107,1.6a.349.349,0,0,0,.341.395.373.373,0,0,0,.395-.288l.314-1.519.315-.5,1.7,1.044s2.75-4.242,1.439-8.116a4.953,4.953,0,0,0-3.981-3.118,1.143,1.143,0,0,1-.81-.522,4.21,4.21,0,0,0-6.939-.08,1.1,1.1,0,0,1-1.385.395,3.127,3.127,0,0,0-3.065.234C791.556,306.115,792.5,310.2,795.016,310.591Z" transform="translate(537.641 2832.41)" fill="#3f4249" />
      <path id="Path_3137" data-name="Path 3137" d="M813.624,327.963a1.773,1.773,0,0,1-1.044-.234l1.151,1.78Z" transform="translate(531.017 2823.964)" opacity="0.15" />
      <path id="Path_3138" data-name="Path 3138" d="M813.206,321.26a1.463,1.463,0,0,1,2.094-.81c1.78.783,1.412,4.818-1.6,5.052-1.572.134-1.258-.783-1-2.014Z" transform="translate(531.046 2826.432)" fill="#fa9b9b" />
      <path id="Path_3139" data-name="Path 3139" d="M815.88,321.975a1.155,1.155,0,0,0-.863-.234,1.064,1.064,0,0,0-.682.549,2.178,2.178,0,0,0-.288,1.1l-.027.341a.176.176,0,0,0,.181.181.18.18,0,0,0,.181-.181l.027-.341a2.245,2.245,0,0,1,.234-.917.909.909,0,0,1,.448-.395.892.892,0,0,1,.575.154.2.2,0,0,0,.261-.054C815.987,322.156,815.96,322.056,815.88,321.975Z" transform="translate(530.54 2825.951)" opacity="0.3" />
      <path id="Path_3140" data-name="Path 3140" d="M795.4,314.812l.836.08a.17.17,0,0,0,.181-.154c0-.1-.054-.181-.154-.181l-.836-.08a1.292,1.292,0,0,1-.315-.053,4.082,4.082,0,0,1-.97-.468.17.17,0,1,0-.181.288,3.84,3.84,0,0,0,1.044.5C795.137,314.758,795.265,314.785,795.4,314.812Z" transform="translate(537.204 2828.531)" fill="#fa9b9b" />
      <path id="Path_3141" data-name="Path 3141" d="M797.5,338.989l.274-2.536a5.384,5.384,0,0,0,4.818,1.164h0a5.418,5.418,0,0,0,2.85-1.78.167.167,0,1,1,.261.207,5.915,5.915,0,0,1-3.767,2.014A5.8,5.8,0,0,1,797.5,338.989Z" transform="translate(536.006 2821.302)" opacity="0.15" />
      <g id="Group_1221" data-name="Group 1221" transform="translate(1335.768 3155.313)" opacity="0.3">
        <path id="Path_3142" data-name="Path 3142" d="M801.061,333.581c0,.027.027.027,0,0,.682.054,1,.08,1,.08a.227.227,0,0,0,.234-.207.212.212,0,0,0-.207-.234l-.97-.08c-.134,0-.234.08-.234.181C800.88,333.474,800.96,333.581,801.061,333.581Z" transform="translate(-800.88 -333.139)" opacity="0.3" />
      </g>
      <g id="Group_1222" data-name="Group 1222" transform="translate(1334.529 3153.318)" opacity="0.3">
        <path id="Path_3143" data-name="Path 3143" d="M800.087,331.65a2.678,2.678,0,0,0,2.877-1.178.211.211,0,0,0-.368-.207c-.261.468-1.151,1.492-3.3.783a.207.207,0,0,0-.127.395A5.426,5.426,0,0,0,800.087,331.65Z" transform="translate(-799.03 -330.158)" opacity="0.3" />
      </g>
      <path id="Path_3144" data-name="Path 3144" d="M803.956,324.7a.656.656,0,1,0-.656-.656A.656.656,0,0,0,803.956,324.7Z" transform="translate(534.087 2825.4)" fill="#21252b" />
      <path id="Path_3145" data-name="Path 3145" d="M803.784,324.127a.134.134,0,1,0-.134-.134A.134.134,0,0,0,803.784,324.127Z" transform="translate(533.971 2825.245)" fill="#fff" />
      <path id="Path_3146" data-name="Path 3146" d="M796.216,324.741a.656.656,0,1,0-.656-.656A.656.656,0,0,0,796.216,324.741Z" transform="translate(536.647 2825.387)" fill="#21252b" />
      <path id="Path_3147" data-name="Path 3147" d="M796.044,324.159a.134.134,0,1,0-.134-.134A.134.134,0,0,0,796.044,324.159Z" transform="translate(536.532 2825.234)" fill="#fff" />
      <g id="Group_1223" data-name="Group 1223" transform="translate(1334.383 3151.645)" opacity="0.3">
        <path id="Path_3148" data-name="Path 3148" d="M799.539,329.224l.863.054a.195.195,0,1,0,.027-.388l-.863-.054a.4.4,0,1,1,.054-.79.22.22,0,0,0,.207-.181c.027-.107-.08-.181-.181-.207a.8.8,0,0,0-.836.709A.817.817,0,0,0,799.539,329.224Z" transform="translate(-798.81 -327.657)" opacity="0.3" />
      </g>
      <path id="Path_3149" data-name="Path 3149" d="M792.35,322.8v.736a2.563,2.563,0,1,0,5.125,0V322.8a.908.908,0,0,0-.181-.575h1a.893.893,0,0,0-.207.575v.736a2.563,2.563,0,0,0,5.125,0V322.8a.942.942,0,0,0-.943-.943h-8.98A.941.941,0,0,0,792.35,322.8Zm6.651-.549h3.245a.58.58,0,0,1,.575.575v.736a2.188,2.188,0,0,1-4.376-.027V322.8A.561.561,0,0,1,799,322.254Zm-6.31.575a.58.58,0,0,1,.575-.575h3.245a.58.58,0,0,1,.575.575v.736a2.188,2.188,0,0,1-4.376-.027Z" transform="translate(537.71 2825.906)" fill="#21252b" />
      <path id="Path_3150" data-name="Path 3150" d="M831.924,374.527H819.98l3.854,17.444h8.056Z" transform="translate(528.567 2808.479)" fill="#fa9b9b" />
      <path id="Path_3151" data-name="Path 3151" d="M822.187,379.818l-2.583-3.54-1.914-7.722a3.017,3.017,0,0,0-.462-1.024,2.922,2.922,0,0,0-1.191-.923l-6.383-3.071a.845.845,0,0,0-.79,1.492l5.313,3.212-4.8-1.532a.819.819,0,0,0-1.071.81.837.837,0,0,0,.482.716l5.614,2.389-4.269-1.807a.764.764,0,0,0-.689,1.358l4.6,2.79-3.031-1.82a.659.659,0,0,0-.99.415.678.678,0,0,0,.174.636l2.63,2.616,4.43,13.5h3.332C821.458,384.642,822.187,379.818,822.187,379.818Z" transform="translate(532.43 2812.142)" fill="#fa9b9b" />
      <path id="Path_3152" data-name="Path 3152" d="M830.747,395.682l-4.2-5.534-6.925,2.957,1.338,4.035H831.5A4.6,4.6,0,0,0,830.747,395.682Z" transform="translate(528.687 2803.311)" fill="#fa9b9b" />
      <path id="Path_3153" data-name="Path 3153" d="M823.208,377.27l-1.961-7.695a2.612,2.612,0,0,0-1.365-1.693l-.729-.361-.013-.642,1.68.79a2.611,2.611,0,0,1,1.439,1.78l1.74,7.561,7.441,9.535.181,2.9Z" transform="translate(528.846 2811.01)" opacity="0.15" />
      <path id="Path_3154" data-name="Path 3154" d="M796.433,363.5l-27.293.007,4.992,24.818h27.293Z" transform="translate(545.39 2812.129)" fill="#21252b" />
      <path id="Path_3155" data-name="Path 3155" d="M755.161,368.439l-6.611,10.92,10.887,1.9,4.891-10.144Z" transform="translate(552.203 2810.494)" fill="#fa9b9b" />
      <path id="Path_3156" data-name="Path 3156" d="M759.088,374.92l1.713.977,3.767-2.944a2.892,2.892,0,0,1,2.154-.054l6.771,2.094a.846.846,0,0,1,.562,1.017.836.836,0,0,1-1,.609l-6.076-1.265,4.557,2.148a.826.826,0,0,1,.234,1.325.846.846,0,0,1-.843.2l-5.734-2.074,4.363,1.579a.764.764,0,0,1-.415,1.465l-5.627-1.158,3.821.8a.662.662,0,0,1,.448.977.677.677,0,0,1-.555.348l-3.707.127-4.2,4.958a6.172,6.172,0,0,1-7.969,1.325c-.094-.06-.181-.12-.274-.181l-.147-.107C749.3,383.732,759.088,374.92,759.088,374.92Z" transform="translate(551.474 2809.078)" fill="#fa9b9b" />
      <path id="Path_3157" data-name="Path 3157" d="M758.105,376.01l-6.169,3.111a5.017,5.017,0,1,0,2.884,9.535l3.841-3.781Z" transform="translate(552.457 2807.989)" fill="#fa9b9b" />
      <path id="Path_3158" data-name="Path 3158" d="M760.406,376.152l3.693-2.683-3.627,1.693-9.354,2.128-1.559,2.69Z" transform="translate(551.868 2808.83)" opacity="0.15" />
      <path id="Path_3159" data-name="Path 3159" d="M771.38,371.753l-.542.187-.509-2.523,2.73.682Z" transform="translate(544.996 2810.17)" fill="#21252b" />
      <path id="Path_3160" data-name="Path 3160" d="M811.238,369.79l.93-2.1-1.137-.669-.241.509Z" transform="translate(531.608 2810.963)" fill="#21252b" />
      <path id="Path_3161" data-name="Path 3161" d="M813.458,362.007l2.482-5.734a.7.7,0,1,0-1.291-.542l-2.349,5.748Z" transform="translate(531.109 2814.844)" fill="#21252b" />
      <path id="Path_3162" data-name="Path 3162" d="M815.23,373.248l-3.66-1.559" transform="translate(531.351 2809.418)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" opacity="0.15" />
      <path id="Path_3163" data-name="Path 3163" d="M815.419,370.1l-3.145-1.01-.194.455Z" transform="translate(531.182 2810.279)" opacity="0.15" />
      <path id="Path_3164" data-name="Path 3164" d="M815.06,376.588l-2.73-1.639" transform="translate(531.099 2808.34)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" opacity="0.15" />
      <g id="Group_1224" data-name="Group 1224" transform="translate(1314.787 3187.956)" opacity="0.15">
        <path id="Path_3165" data-name="Path 3165" d="M769.528,382.074a.189.189,0,0,1,.221-.147l3.024.616.756.161a.646.646,0,0,1,.508.488l-4.363-.883A.2.2,0,0,1,769.528,382.074Z" transform="translate(-769.525 -381.924)" opacity="0.15" />
      </g>
      <path id="Path_3166" data-name="Path 3166" d="M772.1,379.388a.189.189,0,0,1-.114-.241.193.193,0,0,1,.248-.114l1.994.743-2.014-.729,2.328.843,2.148.8a.752.752,0,0,1,.368.535.085.085,0,0,1-.04-.007Z" transform="translate(544.452 2806.992)" opacity="0.15" />
      <path id="Path_3167" data-name="Path 3167" d="M783.1,354.729,779.31,360.6h2.074Z" transform="translate(542.024 2815.03)" opacity="0.15" />
      <path id="Path_3168" data-name="Path 3168" d="M818.167,364.308l-.161-7.119-1.827,6.163Z" transform="translate(529.825 2814.216)" opacity="0.15" />
      <path id="Path_3169" data-name="Path 3169" d="M796.677,340.966l-.167,1.559a1.2,1.2,0,0,0,.422,1.071l4.583,3.9a.511.511,0,0,0,.6.027l6.176-4.215a1.231,1.231,0,0,0,.288-.81l-.007-.12,1.693.194-.207.723a1.007,1.007,0,0,1-.388.549l-7.273,5.112a1.007,1.007,0,0,1-1.245-.074l-5.7-5.072a1,1,0,0,1-.334-.83l.174-2.181Z" transform="translate(536.795 2819.64)" opacity="0.6" />
      <path id="Path_3170" data-name="Path 3170" d="M833.31,373.811H824.5l-.335-1.452h9.14Z" transform="translate(527.182 2809.197)" opacity="0.6" />
      <path id="Path_3171" data-name="Path 3171" d="M758.43,367.724l.85-1.445,7.113,2.1.355,1.773Z" transform="translate(548.934 2811.209)" opacity="0.6" />
      <path id="Path_3172" data-name="Path 3172" d="M722.716,316.711H695.322a.544.544,0,0,1-.542-.542v-8.518a.544.544,0,0,1,.542-.542h27.394a.544.544,0,0,1,.542.542v8.518A.54.54,0,0,1,722.716,316.711Z" transform="translate(569.994 2830.787)" fill="#3e5a01" />
      <path id="Path_3173" data-name="Path 3173" d="M722.716,316.711H695.322a.544.544,0,0,1-.542-.542v-8.518a.544.544,0,0,1,.542-.542h27.394a.544.544,0,0,1,.542.542v8.518A.54.54,0,0,1,722.716,316.711Z" transform="translate(569.994 2830.787)" fill="#fff" opacity="0.5" />
      <path id="Path_3174" data-name="Path 3174" d="M721.656,316.711H694.262a.544.544,0,0,1-.542-.542v-8.518a.544.544,0,0,1,.542-.542h27.394a.544.544,0,0,1,.542.542v8.518A.532.532,0,0,1,721.656,316.711Z" transform="translate(570.345 2830.787)" fill="#fff" />
      <path id="Path_3175" data-name="Path 3175" d="M721.051,312.085H713a.436.436,0,1,1,0-.856h8.056a.436.436,0,1,1,0,.856Z" transform="translate(564.138 2829.424)" fill="#61656d" />
      <path id="Path_3176" data-name="Path 3176" d="M725,314.77H712.758a.225.225,0,1,1,0-.442H725a.249.249,0,0,1,.268.221C725.283,314.669,725.156,314.77,725,314.77Z" transform="translate(564.135 2828.398)" fill="#afb3b8" />
      <path id="Path_3177" data-name="Path 3177" d="M720.061,316.84h-7.293a.225.225,0,1,1,0-.442h7.293a.225.225,0,1,1,0,.442Z" transform="translate(564.131 2827.713)" fill="#afb3b8" />
      <path id="Path_3178" data-name="Path 3178" d="M702.521,309.77h-.575v.06a2.935,2.935,0,0,0,0,5.754v.06h.575a2.937,2.937,0,0,0,0-5.875Z" transform="translate(568.402 2829.906)" fill="#3e5a01" />
      <path id="Path_3179" data-name="Path 3179" d="M701.661,315.621a2.931,2.931,0,1,0-2.931-2.931A2.931,2.931,0,0,0,701.661,315.621Z" transform="translate(568.688 2829.91)" fill="#97be40" />
      <path id="Path_3180" data-name="Path 3180" d="M701.661,315.621a2.931,2.931,0,1,0-2.931-2.931A2.931,2.931,0,0,0,701.661,315.621Z" transform="translate(568.688 2829.91)" fill="#c6de39" opacity="0.4" />
      <path id="Path_3181" data-name="Path 3181" d="M701.78,314.792l-.99-.99a.137.137,0,0,1,0-.194l.261-.261a.137.137,0,0,1,.194,0l.636.636,1.526-1.532a.137.137,0,0,1,.194,0l.261.261a.137.137,0,0,1,0,.194l-1.887,1.887A.127.127,0,0,1,701.78,314.792Z" transform="translate(568.019 2829.033)" fill="#fff" />
      <path id="Path_3182" data-name="Path 3182" d="M722.716,339.3H695.322a.544.544,0,0,1-.542-.542v-8.518a.544.544,0,0,1,.542-.542h27.394a.544.544,0,0,1,.542.542v8.518A.54.54,0,0,1,722.716,339.3Z" transform="translate(569.994 2823.312)" fill="#3e5a01" />
      <path id="Path_3183" data-name="Path 3183" d="M722.716,339.3H695.322a.544.544,0,0,1-.542-.542v-8.518a.544.544,0,0,1,.542-.542h27.394a.544.544,0,0,1,.542.542v8.518A.54.54,0,0,1,722.716,339.3Z" transform="translate(569.994 2823.312)" fill="#fff" opacity="0.5" />
      <path id="Path_3184" data-name="Path 3184" d="M721.656,339.3H694.262a.544.544,0,0,1-.542-.542v-8.518a.544.544,0,0,1,.542-.542h27.394a.544.544,0,0,1,.542.542v8.518A.532.532,0,0,1,721.656,339.3Z" transform="translate(570.345 2823.312)" fill="#fff" />
      <path id="Path_3185" data-name="Path 3185" d="M721.051,334.674H713a.436.436,0,1,1,0-.856h8.056a.436.436,0,1,1,0,.856Z" transform="translate(564.138 2821.949)" fill="#61656d" />
      <path id="Path_3186" data-name="Path 3186" d="M725,337.36H712.758a.225.225,0,1,1,0-.442H725a.249.249,0,0,1,.268.221C725.283,337.259,725.156,337.36,725,337.36Z" transform="translate(564.135 2820.924)" fill="#afb3b8" />
      <path id="Path_3187" data-name="Path 3187" d="M720.061,339.43h-7.293a.225.225,0,1,1,0-.442h7.293a.225.225,0,1,1,0,.442Z" transform="translate(564.131 2820.239)" fill="#afb3b8" />
      <path id="Path_3188" data-name="Path 3188" d="M702.521,332.359h-.575v.06a2.935,2.935,0,0,0,0,5.754v.06h.575a2.937,2.937,0,0,0,0-5.875Z" transform="translate(568.402 2822.432)" fill="#3e5a01" />
      <path id="Path_3189" data-name="Path 3189" d="M701.661,338.211a2.931,2.931,0,1,0-2.931-2.931A2.931,2.931,0,0,0,701.661,338.211Z" transform="translate(568.688 2822.435)" fill="#97be40" />
      <path id="Path_3190" data-name="Path 3190" d="M701.661,338.211a2.931,2.931,0,1,0-2.931-2.931A2.931,2.931,0,0,0,701.661,338.211Z" transform="translate(568.688 2822.435)" fill="#97be40" opacity="0.4" />
      <path id="Path_3191" data-name="Path 3191" d="M701.78,337.382l-.99-.99a.137.137,0,0,1,0-.194l.261-.261a.137.137,0,0,1,.194,0l.636.636,1.526-1.532a.137.137,0,0,1,.194,0l.261.261a.137.137,0,0,1,0,.194l-1.887,1.887A.127.127,0,0,1,701.78,337.382Z" transform="translate(568.019 2821.558)" fill="#fff" />
      <path id="Path_3192" data-name="Path 3192" d="M722.716,361.891H695.322a.544.544,0,0,1-.542-.542v-8.518a.544.544,0,0,1,.542-.542h27.394a.544.544,0,0,1,.542.542v8.518A.54.54,0,0,1,722.716,361.891Z" transform="translate(569.994 2815.838)" fill="#3e5a01" />
      <path id="Path_3193" data-name="Path 3193" d="M722.716,361.891H695.322a.544.544,0,0,1-.542-.542v-8.518a.544.544,0,0,1,.542-.542h27.394a.544.544,0,0,1,.542.542v8.518A.54.54,0,0,1,722.716,361.891Z" transform="translate(569.994 2815.838)" fill="#fff" opacity="0.5" />
      <path id="Path_3194" data-name="Path 3194" d="M721.656,361.891H694.262a.544.544,0,0,1-.542-.542v-8.518a.544.544,0,0,1,.542-.542h27.394a.544.544,0,0,1,.542.542v8.518A.532.532,0,0,1,721.656,361.891Z" transform="translate(570.345 2815.838)" fill="#fff" />
      <path id="Path_3195" data-name="Path 3195" d="M721.051,357.264H713a.436.436,0,1,1,0-.856h8.056a.436.436,0,1,1,0,.856Z" transform="translate(564.138 2814.475)" fill="#61656d" />
      <path id="Path_3196" data-name="Path 3196" d="M725,359.95H712.758a.225.225,0,1,1,0-.442H725a.249.249,0,0,1,.268.221C725.283,359.849,725.156,359.95,725,359.95Z" transform="translate(564.135 2813.449)" fill="#afb3b8" />
      <path id="Path_3197" data-name="Path 3197" d="M720.061,362.02h-7.293a.225.225,0,1,1,0-.442h7.293a.225.225,0,1,1,0,.442Z" transform="translate(564.131 2812.764)" fill="#afb3b8" />
      <path id="Path_3198" data-name="Path 3198" d="M702.521,354.949h-.575v.06a2.935,2.935,0,0,0,0,5.754v.06h.575a2.937,2.937,0,0,0,0-5.875Z" transform="translate(568.402 2814.958)" fill="#3e5a01" />
      <path id="Path_3199" data-name="Path 3199" d="M701.661,360.8a2.931,2.931,0,1,0-2.931-2.931A2.931,2.931,0,0,0,701.661,360.8Z" transform="translate(568.688 2814.961)" fill="#97be40" />
      <path id="Path_3200" data-name="Path 3200" d="M701.661,360.8a2.931,2.931,0,1,0-2.931-2.931A2.931,2.931,0,0,0,701.661,360.8Z" transform="translate(568.688 2814.961)" fill="#7bb200" opacity="0.4" />
      <path id="Path_3201" data-name="Path 3201" d="M701.78,359.972l-.99-.99a.137.137,0,0,1,0-.194l.261-.261a.137.137,0,0,1,.194,0l.636.636,1.526-1.532a.137.137,0,0,1,.194,0l.261.261a.137.137,0,0,1,0,.194l-1.887,1.887A.137.137,0,0,1,701.78,359.972Z" transform="translate(568.019 2814.084)" fill="#fff" />
      <path id="Path_3202" data-name="Path 3202" d="M722.716,384.481H695.322a.544.544,0,0,1-.542-.542v-8.518a.544.544,0,0,1,.542-.542h27.394a.544.544,0,0,1,.542.542v8.518A.54.54,0,0,1,722.716,384.481Z" transform="translate(569.994 2808.363)" fill="#3e5a01" />
      <path id="Path_3203" data-name="Path 3203" d="M722.716,384.481H695.322a.544.544,0,0,1-.542-.542v-8.518a.544.544,0,0,1,.542-.542h27.394a.544.544,0,0,1,.542.542v8.518A.54.54,0,0,1,722.716,384.481Z" transform="translate(569.994 2808.363)" fill="#fff" opacity="0.5" />
      <path id="Path_3204" data-name="Path 3204" d="M721.656,384.481H694.262a.544.544,0,0,1-.542-.542v-8.518a.544.544,0,0,1,.542-.542h27.394a.544.544,0,0,1,.542.542v8.518A.532.532,0,0,1,721.656,384.481Z" transform="translate(570.345 2808.363)" fill="#fff" />
      <path id="Path_3205" data-name="Path 3205" d="M721.051,379.854H713a.436.436,0,1,1,0-.856h8.056a.436.436,0,1,1,0,.856Z" transform="translate(564.138 2807)" fill="#61656d" />
      <path id="Path_3206" data-name="Path 3206" d="M725,382.54H712.758a.225.225,0,1,1,0-.442H725a.249.249,0,0,1,.268.221C725.283,382.439,725.156,382.54,725,382.54Z" transform="translate(564.135 2805.974)" fill="#afb3b8" />
      <path id="Path_3207" data-name="Path 3207" d="M720.061,384.61h-7.293a.225.225,0,1,1,0-.442h7.293a.225.225,0,1,1,0,.442Z" transform="translate(564.131 2805.29)" fill="#afb3b8" />
      <path id="Path_3208" data-name="Path 3208" d="M702.521,377.529h-.575v.06a2.935,2.935,0,0,0,0,5.754v.06h.575a2.937,2.937,0,0,0,0-5.875Z" transform="translate(568.402 2807.486)" fill="#3e5a01" />
      <path id="Path_3209" data-name="Path 3209" d="M701.661,383.39a2.931,2.931,0,1,0-2.931-2.931A2.931,2.931,0,0,0,701.661,383.39Z" transform="translate(568.688 2807.486)" fill="#7bb200" />
      <path id="Path_3210" data-name="Path 3210" d="M701.661,383.39a2.931,2.931,0,1,0-2.931-2.931A2.931,2.931,0,0,0,701.661,383.39Z" transform="translate(568.688 2807.486)" fill="#fff" opacity="0.4" />
      <path id="Path_3211" data-name="Path 3211" d="M701.78,382.562l-.99-.99a.137.137,0,0,1,0-.194l.261-.261a.137.137,0,0,1,.194,0l.636.636,1.526-1.532a.137.137,0,0,1,.194,0l.261.261a.137.137,0,0,1,0,.194l-1.887,1.887A.137.137,0,0,1,701.78,382.562Z" transform="translate(568.019 2806.609)" fill="#fff" />
      <path id="Path_3212" data-name="Path 3212" d="M948.377,586.751H832.927a2.958,2.958,0,0,1-2.958-2.958V511.756a2.958,2.958,0,0,1,2.958-2.957H948.377a2.958,2.958,0,0,1,2.958,2.957v72.037A2.958,2.958,0,0,1,948.377,586.751Z" transform="translate(525.262 2764.051)" fill="#3e5a01" />
      <path id="Path_3213" data-name="Path 3213" d="M832.9,508.789H948.387a2.944,2.944,0,0,1,2.944,2.944v4.7H829.96v-4.7A2.944,2.944,0,0,1,832.9,508.789Z" transform="translate(525.266 2764.054)" />
      <path id="Path_3214" data-name="Path 3214" d="M948.377,586.751H832.927a2.958,2.958,0,0,1-2.958-2.958V511.756a2.958,2.958,0,0,1,2.958-2.957H948.377a2.958,2.958,0,0,1,2.958,2.957v72.037A2.958,2.958,0,0,1,948.377,586.751Z" transform="translate(525.262 2764.051)" fill="#fff" opacity="0.2" />
      <path id="Path_3215" data-name="Path 3215" d="M944.427,586.751H828.977a2.958,2.958,0,0,1-2.957-2.958V511.756a2.958,2.958,0,0,1,2.957-2.957H944.427a2.958,2.958,0,0,1,2.958,2.957v72.037A2.954,2.954,0,0,1,944.427,586.751Z" transform="translate(526.569 2764.051)" fill="#e9e9e9" />
      <path id="Path_3217" data-name="Path 3217" d="M828.954,508.789H944.437a2.944,2.944,0,0,1,2.944,2.944v4.7H826.01v-4.7A2.944,2.944,0,0,1,828.954,508.789Z" transform="translate(526.572 2764.054)" fill="#3f4249" />
      <path id="Path_3218" data-name="Path 3218" d="M834.352,513.88a1.251,1.251,0,1,1-1.251-1.251A1.254,1.254,0,0,1,834.352,513.88Z" transform="translate(524.641 2762.784)" />
      <path id="Path_3219" data-name="Path 3219" d="M840.672,513.88a1.251,1.251,0,1,1-1.251-1.251A1.254,1.254,0,0,1,840.672,513.88Z" transform="translate(522.549 2762.784)" />
      <path id="Path_3220" data-name="Path 3220" d="M846.982,513.88a1.251,1.251,0,1,1-1.251-1.251A1.254,1.254,0,0,1,846.982,513.88Z" transform="translate(520.461 2762.784)" />
      <path id="Path_3221" data-name="Path 3221" d="M833.521,514.7a.4.4,0,1,0-.4-.4A.4.4,0,0,0,833.521,514.7Z" transform="translate(524.22 2762.364)" fill="#fff" />
      <path id="Path_3222" data-name="Path 3222" d="M923.206,543.035c-.3.79-.616,1.6-.917,2.462A2.244,2.244,0,0,0,924,548.709a3.328,3.328,0,0,0,.355,3.433,2.665,2.665,0,0,0,3.038.83,3.061,3.061,0,0,0,2.215,1.017c2.362.241,3.259-.676,3.259-.676l2.489-.348a3.942,3.942,0,0,0,3.125,1.827,4.18,4.18,0,0,0,3.647-1c.582,1.258,3.546,1.158,3.961-.776a2.7,2.7,0,0,0-.535-2.69c1.492,1.151,4.169-1.693,1.566-4.59a29.4,29.4,0,0,1-2.041-2.315,1.8,1.8,0,0,0,2.061-2.315c-.642-1.639-1.967-1.051-3.145-3.419a14.574,14.574,0,0,0-2.884-4.309,11.76,11.76,0,0,0-8.792-3.145,7.565,7.565,0,0,0-5.848,2.436,6.306,6.306,0,0,0-1.492,3.781q-.03.432-.08.863c-.462,1-1.392,2.77-2.422,3.279A1.289,1.289,0,0,0,923.206,543.035Z" transform="translate(494.909 2756.962)" fill="#21252b" />
      <path id="Path_3223" data-name="Path 3223" d="M935.07,559.85a2.67,2.67,0,1,0-2.67-2.67A2.67,2.67,0,0,0,935.07,559.85Z" transform="translate(491.37 2748.926)" fill="#21252b" />
      <path id="Path_3224" data-name="Path 3224" d="M865.293,556.611,875.5,569.5l10.713-3.627,3.232,8.451-10.753,3.065a7.088,7.088,0,0,1-8.023-3.192L861.8,559.053Z" transform="translate(514.73 2748.231)" fill="#f39494" />
      <path id="Path_3225" data-name="Path 3225" d="M862.1,552.941s-.274-1.385-.843-3.693c-.187-.769-1.2-.482-1.3-.234a6.272,6.272,0,0,0-.141,1.88c-.033,1.559-4.751-1.726-6.1-2.7a.333.333,0,0,0-.529.281c.047.769.81,2.569,4.657,6.618.435.455.9.937,1.412,1.452C866.913,564.209,862.1,552.941,862.1,552.941Z" transform="translate(517.579 2751.038)" fill="#f39494" />
      <path id="Path_3226" data-name="Path 3226" d="M901.512,576.669l-3.232-8.451s7.287-2.469,11.268-3.814a13.665,13.665,0,0,1,5.192-.7l5.005.3,15.871.883a8.529,8.529,0,0,1,7.849,10.03l-1.452,8.15-1.044,7.247v14.587H910.853a78.722,78.722,0,0,0-.281-14.373c-.5-5.366-3.245-6.022-2.7-10.947.234-2.1,1.218-3.479,2.081-5.46l-2.4.769Z" transform="translate(502.659 2745.891)" fill="#e0ebc8" />
      <path id="Path_3227" data-name="Path 3227" d="M939.5,547.363l-.241,9.214a1.722,1.722,0,0,0,1.164,1.653l1.03.355-2.482,1.332a8.97,8.97,0,0,1-9.307-.428l-2.523-1.7,1.191-.268a1.693,1.693,0,0,0,1.3-1.365l1.793-10.866Z" transform="translate(493.11 2751.977)" fill="#f59696" />
      <path id="Path_3228" data-name="Path 3228" d="M932.728,551.222l.435.054a5.668,5.668,0,0,0,6.2-5.1l.763-7.929a1.893,1.893,0,0,0-1.706-2.068L931,535.445a2.462,2.462,0,0,0-2.683,2.215l-.709,7.367A5.714,5.714,0,0,0,932.728,551.222Z" transform="translate(492.963 2755.238)" fill="#f59696" />
      <path id="Path_3229" data-name="Path 3229" d="M936.586,546.4a.616.616,0,1,0-.616-.616A.616.616,0,0,0,936.586,546.4Z" transform="translate(490.188 2752.017)" fill="#21252b" />
      <path id="Path_3230" data-name="Path 3230" d="M936.457,545.953a.127.127,0,1,0-.127-.127A.127.127,0,0,0,936.457,545.953Z" transform="translate(490.069 2751.842)" fill="#fff" />
      <path id="Path_3231" data-name="Path 3231" d="M929.446,546.01a.616.616,0,1,0-.616-.616A.616.616,0,0,0,929.446,546.01Z" transform="translate(492.551 2752.146)" fill="#21252b" />
      <path id="Path_3232" data-name="Path 3232" d="M929.317,545.524a.127.127,0,1,0-.127-.127A.128.128,0,0,0,929.317,545.524Z" transform="translate(492.432 2751.983)" fill="#fff" />
      <g id="Group_1228" data-name="Group 1228" transform="translate(1423.429 3299.772)" opacity="0.15">
        <path id="Path_3233" data-name="Path 3233" d="M932.486,550.511l.816.12a.181.181,0,1,0,.074-.355l-.816-.12a.375.375,0,0,1-.308-.428.418.418,0,0,1,.428-.335.181.181,0,0,0,.074-.355.746.746,0,0,0-.83.622A.646.646,0,0,0,932.486,550.511Z" transform="translate(-931.89 -549.034)" opacity="0.15" />
      </g>
      <path id="Path_3234" data-name="Path 3234" d="M944.388,549.717a3.121,3.121,0,0,0,3.593-1.981c.937-2.589-2.3-3.533-3.131-.917l-.769,2.877Z" transform="translate(487.505 2751.985)" fill="#f59696" />
      <path id="Path_3235" data-name="Path 3235" d="M947.982,546.518a1.067,1.067,0,0,0-.776-.328,1.012,1.012,0,0,0-.716.422,1.992,1.992,0,0,0-.442,1l-.04.281c-.04.2.314.328.355.074l.067-.3a1.968,1.968,0,0,1,.348-.83.764.764,0,0,1,.475-.308.744.744,0,0,1,.529.234.194.194,0,0,0,.254-.013A.188.188,0,0,0,947.982,546.518Z" transform="translate(486.868 2751.679)" opacity="0.15" />
      <path id="Path_3236" data-name="Path 3236" d="M945.565,550.921a.535.535,0,1,0-.535-.535A.536.536,0,0,0,945.565,550.921Z" transform="translate(487.19 2750.468)" fill="#4d61fc" />
      <path id="Path_3237" data-name="Path 3237" d="M945.387,550.906a.586.586,0,0,0,.669-.5.617.617,0,0,0-.06-.314.615.615,0,0,1,.181.468.527.527,0,0,1-.616.455.461.461,0,0,1-.3-.147A.355.355,0,0,1,945.387,550.906Z" transform="translate(487.114 2750.389)" opacity="0.15" />
      <path id="Path_3238" data-name="Path 3238" d="M931.315,557.711a6.327,6.327,0,0,0,2.871.95l.689.04a.652.652,0,0,0,.254-.013h.047c.127-.02.254-.013.415-.027.047,0,.1-.02.154-.02a5.887,5.887,0,0,0,2.034-.609.166.166,0,0,1,.167.288,6.551,6.551,0,0,1-2.65.7,5.059,5.059,0,0,1-3.044.863,5.746,5.746,0,0,1-1.271-.221Z" transform="translate(491.84 2747.867)" opacity="0.15" />
      <path id="Path_3239" data-name="Path 3239" d="M931.952,552.649a.114.114,0,0,0-.114.147,1.762,1.762,0,0,0,3.172.174c.027-.074.007-.154-.094-.161Z" transform="translate(491.557 2749.542)" fill="#fff" />
      <path id="Path_3240" data-name="Path 3240" d="M941.452,564.607c-.241,4.376-8.538,7.514-8.538,7.514s-6.009-3.934-5.768-8.31l1.378-.321Z" transform="translate(493.11 2745.955)" fill="#f59696" />
      <path id="Path_3241" data-name="Path 3241" d="M925.46,545.14a2.474,2.474,0,1,0,4.717-.669,2.061,2.061,0,0,1,1.224.094,2.817,2.817,0,0,0-.248.783,2.474,2.474,0,1,0,2.777-2.128,2.451,2.451,0,0,0-2.362,1.051,2.287,2.287,0,0,0-1.532-.107,2.551,2.551,0,0,0-1.78-1.171A2.52,2.52,0,0,0,925.46,545.14Zm5.982.248a2.14,2.14,0,1,1,1.84,2.4A2.142,2.142,0,0,1,931.442,545.388Zm-5.647-.2a2.14,2.14,0,1,1,1.84,2.4A2.142,2.142,0,0,1,925.794,545.187Z" transform="translate(493.673 2752.743)" fill="#fff" />
      <path id="Path_3242" data-name="Path 3242" d="M939.226,571.214l-9.956-1.044a11.766,11.766,0,0,0,1.833,2.141,21.851,21.851,0,0,0,2.509,2.027,25.37,25.37,0,0,0,3.091-1.472A19.4,19.4,0,0,0,939.226,571.214Z" transform="translate(492.405 2743.745)" fill="#21252b" />
      <path id="Path_3243" data-name="Path 3243" d="M915.6,616.114l-2.416.328-5.159-.462a1.707,1.707,0,0,0-1.191.515l-3.185,2.844a.5.5,0,0,0-.047.7.511.511,0,0,0,.7.087l3.018-2.215-1.961,2.315a.5.5,0,0,0,.2.783.527.527,0,0,0,.515-.1l2.623-2.57L906.7,620.3a.453.453,0,0,0,.154.749A.464.464,0,0,0,907.3,621l2.79-2.041-1.894,1.4a.4.4,0,0,0,0,.648.392.392,0,0,0,.388.047l2.536-1.057a.946.946,0,0,1,.763.013l.97.476a5.76,5.76,0,0,0,2.536.595h1.586C919.239,621.072,915.6,616.114,915.6,616.114Z" transform="translate(500.938 2728.587)" fill="#f59696" />
      <path id="Path_3244" data-name="Path 3244" d="M910.464,621.916a.064.064,0,0,0,.047-.013l1.74-1.278c.033-.033.054-.087.013-.12a.086.086,0,0,0-.12-.013l-1.74,1.278a.086.086,0,0,0-.013.12C910.417,621.9,910.451,621.916,910.464,621.916Z" transform="translate(498.659 2727.101)" opacity="0.15" />
      <path id="Path_3245" data-name="Path 3245" d="M908.255,621.561c.013,0,.053,0,.053-.013l1.874-1.84a.085.085,0,0,0-.12-.12l-1.874,1.84a.085.085,0,0,0,0,.12C908.2,621.561,908.234,621.561,908.255,621.561Z" transform="translate(499.39 2727.401)" opacity="0.15" />
      <path id="Path_3246" data-name="Path 3246" d="M912.8,621.467c-.575.288-1.552.783-1.894.97-.475.274-.616-.087-.616-.087a.409.409,0,0,0,.542.482l2.536-1.057a.946.946,0,0,1,.763.013l-.5-.288A.9.9,0,0,0,912.8,621.467Z" transform="translate(498.688 2726.8)" opacity="0.15" />
      <path id="Path_3247" data-name="Path 3247" d="M921.59,608.206l17.123-3.914,1.526-12.211,9.348,1.365-1.827,11.442a7.34,7.34,0,0,1-6.6,6.323l-18.193,1.954Z" transform="translate(494.946 2736.495)" fill="#f59696" />
      <path id="Path_3248" data-name="Path 3248" d="M917.616,623.6H897.194a.323.323,0,0,0-.314.315v.515a.323.323,0,0,0,.314.315h20.421a.323.323,0,0,0,.315-.315v-.515A.314.314,0,0,0,917.616,623.6Z" transform="translate(503.123 2726.065)" fill="#21252b" />
      <path id="Path_3249" data-name="Path 3249" d="M917.616,623.6H897.194a.323.323,0,0,0-.314.315v.515a.323.323,0,0,0,.314.315h20.421a.323.323,0,0,0,.315-.315v-.515A.314.314,0,0,0,917.616,623.6Z" transform="translate(503.123 2726.065)" opacity="0.6" />
      <path id="Path_3250" data-name="Path 3250" d="M889.515,623.6H853.9a.323.323,0,0,0-.314.315v.515a.323.323,0,0,0,.314.315h35.61a.323.323,0,0,0,.314-.315v-.515A.338.338,0,0,0,889.515,623.6Z" transform="translate(517.446 2726.065)" fill="#21252b" />
      <g id="Group_1229" data-name="Group 1229" transform="translate(1371.713 3349.667)" opacity="0.6">
        <path id="Path_3251" data-name="Path 3251" d="M854.7,623.8h36.253a.1.1,0,0,0,0-.2H854.7a.1.1,0,0,0-.1.1A.1.1,0,0,0,854.7,623.8Z" transform="translate(-854.6 -623.602)" opacity="0.6" />
      </g>
      <path id="Path_3252" data-name="Path 3252" d="M902.105,611.064h-5.366a.866.866,0,0,1-.85-.7l-4.5-23.633a.86.86,0,0,1,.85-1.017h5.366a.866.866,0,0,1,.85.7l4.5,23.633A.871.871,0,0,1,902.105,611.064Z" transform="translate(504.943 2738.602)" fill="#21252b" />
      <path id="Path_3253" data-name="Path 3253" d="M902.105,611.064h-5.366a.866.866,0,0,1-.85-.7l-4.5-23.633a.86.86,0,0,1,.85-1.017h5.366a.866.866,0,0,1,.85.7l4.5,23.633A.871.871,0,0,1,902.105,611.064Z" transform="translate(504.943 2738.602)" opacity="0.6" />
      <path id="Path_3254" data-name="Path 3254" d="M886.575,611.064H852.129a.866.866,0,0,1-.85-.7l-4.5-23.633a.86.86,0,0,1,.85-1.017h34.426a.866.866,0,0,1,.85.7l4.5,23.633A.847.847,0,0,1,886.575,611.064Z" transform="translate(519.704 2738.602)" fill="#21252b" />
      <path id="Path_3255" data-name="Path 3255" d="M876.251,602.809c.207.475-.02.89-.5.89h-.08a3.02,3.02,0,0,0,2.155,1.037c1.017-.02,1.492-.85,1.037-1.867a3.094,3.094,0,0,0-2.65-1.78,1.117,1.117,0,0,0-1.178.87A1.49,1.49,0,0,1,876.251,602.809Z" transform="translate(510.35 2733.514)" opacity="0.6" />
      <path id="Path_3256" data-name="Path 3256" d="M941.275,580.01l-4.737,18.521-14.948,5.681,17.123-3.908Z" transform="translate(494.946 2740.489)" opacity="0.15" />
      <path id="Path_3257" data-name="Path 3257" d="M914.416,574.192c1.224-3.158,2.081-10.311,2.081-10.311a14.178,14.178,0,0,0-1.405.274c-.3,3.259-2.9,10.713-3.292,10.866Z" transform="translate(498.187 2745.825)" opacity="0.15" />
      <path id="Path_3258" data-name="Path 3258" d="M936.942,534.854s.288,4.985,2.61,7c0,0,.2-1.325,2.007-1.124a7.444,7.444,0,0,0,.435-1.807,6.718,6.718,0,0,0-7.554-7.287s-2.181-.442-5.239,2.168-1.245,5.473-1.245,5.473A18.123,18.123,0,0,0,936.942,534.854Z" transform="translate(493.017 2756.504)" fill="#21252b" />
    </g>
  </g>
</svg>
